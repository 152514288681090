import { Box, Collapse, Link, Typography } from "@mui/material";
import { useState } from "react";
import SupportEmail from "./helpers/SupportEmail";

export default function FAQ() {
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});

  const questionsAndAnswers: Record<string, string | React.ReactNode> = {
    "My print came rolled, what do I do?":
      "Unroll it and flatten it either by placing books on it, or gently rolling it in the other direction. Prints are rolled once they exceed a certain size, to prevent damage during shipping.",
    "What is your return policy?": (
      <Typography>
        If you have any returns or questions about returns, email{" "}
        <SupportEmail />. We only process returns due to print quality issues,
        and have a window of 7 days from delivery of the item(s) to contact us
        for returns. Returns must be shipped to the return address on the
        printing label, and we may redirect inquiries to Printful support where
        needed.
      </Typography>
    ),
    "Can I create an account as a customer?":
      "We haven't implemented accounts for customers yet, but plan to in the future. For now, during checkout you can indicate you want to receive emails, and we'll email you when accounts are available.",
    "How do I apply as an artist?": (
      <Typography>
        Visit our application form at{" "}
        <Link href="https://thegivinggallery.com/apply">
          https://thegivinggallery.com/apply.
        </Link>{" "}
        Our team will review your application and send further instructions via
        email if you're accepted.
      </Typography>
    ),
    "How do payouts work and what is the breakdown?":
      "Artists will be paid out 8 days after orders are received. Artists receive 50% of their artwork's price, the buyer's chosen non-profit will receive 30%, and TheGivingGallery retains 20%. Printing, shipping, and tax costs are listed separately.",
    "How do I delete my account?": (
      <Typography>
        Send us an email at <SupportEmail /> and we'll remove it for you.
      </Typography>
    )
  };

  return (
    <Box
      sx={{
        width: "900px",
        maxWidth: "95%",
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        alignSelf: "center",
        gap: 3,
        m: 3
      }}
    >
      <Typography variant="h4">FAQ</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          alignSelf: "center",
          gap: 1
        }}
      >
        {Object.keys(questionsAndAnswers).map((key) => (
          <Box key={key}>
            <Box>
              <Typography variant="h5">{key}</Typography>
            </Box>
            <Collapse sx={{ ml: 2, mt: 1 }} in={true}>
              {questionsAndAnswers[key]}
            </Collapse>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
