import { z } from "zod";
import { NameSchema } from "./artist-models";

const StripeConnect = z.object({
  id: z.string(),
  payoutsEnabled: z.boolean()
});

const USAddress = z.object({
  country: z.literal("US"),
  city: z.string(),
  // state code, e.g., CA
  state: z.string(),
  zipcode: z.string(),
  address_line_1: z.string(),
  address_line_2: z.string()
});

const AdminData = z.object({});
const ReporterData = z.object({});

export const ArtistUserData = z.object({
  storeName: z.string(),
  stripeConnect: StripeConnect.optional(),
  address: USAddress.optional(),
  nameVisibility: z.literal("private").or(z.literal("public")),
  setupCompletedOn: z.date().optional(),
  pieceLimit: z.number().optional(),
  notificationFrequency: z.literal("Daily").or(z.literal("Weekly")).optional(),
  commissionTarget: z
    .union([z.literal("self"), z.literal("nonprofit"), z.literal("TGG")])
    .optional()
});

export type ArtistUserData = z.infer<typeof ArtistUserData>;

export const Role = z
  .literal("admin")
  .or(z.literal("artist"))
  .or(z.literal("reporter"));
export type Role = z.infer<typeof Role>;

export const UserDocument = z
  .object({
    userId: z.string(),
    email: z.string().email(),
    name: NameSchema,
    roles: z.array(Role),
    createdOn: z.date()
  })
  .and(
    // artist details
    z
      .object({ isArtist: z.literal(false) })
      .or(ArtistUserData.extend({ isArtist: z.literal(true) }))
  )
  .and(
    // admin details
    z
      .object({ isAdmin: z.literal(false) })
      .or(AdminData.extend({ isAdmin: z.literal(true) }))
  )
  .and(
    // artist details
    z
      .object({ isReporter: z.literal(false) })
      .or(ReporterData.extend({ isReporter: z.literal(true) }))
  );

export type UserDocument = z.infer<typeof UserDocument>;
export type ArtistUser = UserDocument & ArtistUserData;
