import { z } from "zod";
import {
  CreateOrUpdateProduct,
  CreateOrUpdateProfile
} from "./models/store-models";

import { CheckoutData, PaymentIntentDetails } from "./models/checkout-models";
import { InviteUserData } from "./models/registration-models";

export const CloudFunctionSchemas = {
  createOrUpdateProfile: {
    data: CreateOrUpdateProfile,
    return: z.null()
  },
  createOrUpdateProduct: {
    data: CreateOrUpdateProduct,
    return: z.object({ productId: z.string() })
  },
  reportContent: {
    data: z.object({
      details: z.string(),
      resourceUrl: z.string(),
      resourceType: z.union([z.literal("product"), z.literal("artist")]),
      resourceId: z.string(),
      resourceName: z.string()
    }),
    return: z.null()
  },
  inviteUser: {
    data: InviteUserData.and(z.object({ origin: z.string().url() })),
    return: z.null()
  },
  acceptInvite: {
    data: z.object({ inviteCode: z.string() }),
    return: z.null()
  },
  completeSetup: {
    data: z.object({}),
    return: z.null()
  },
  createStripeConnectAccount: {
    data: z.object({ returnToLocation: z.string().url() }),
    return: z.object({
      redirectTo: z.string()
    })
  },
  checkStripePayoutsEnabled: {
    data: z.object({}),
    return: z.object({
      payoutsEnabled: z.boolean()
    })
  },
  submitPrelaunchEmail: {
    data: z.object({
      email: z.string().email(),
      interestedIn: z.literal("buying").or(z.literal("selling")),
      comment: z.string().max(400, "Must be at most 400 characters").optional()
    }),
    return: z.null()
  },
  createPaymentIntent: {
    data: CheckoutData.extend({
      expectedPrice: z.string(),
      origin: z.string().url()
    }),
    return: PaymentIntentDetails
  },
  markOrderAsDelivered: {
    data: z.object({
      orderId: z.string(),
      dateUnixTimestamp: z.number()
    }),
    return: z.null()
  },
  modifyOrderHold: {
    data: z.object({
      orderId: z.string(),
      action: z.literal("place on hold").or(z.literal("remove hold"))
    }),
    return: z.null()
  },
  removeProduct: {
    data: z.object({
      productId: z.string(),
      version: z.number()
    }),
    return: z.null()
  },
  artistVisibility: {
    data: z.object({
      artistId: z.string(),
      action: z.literal("set as live").or(z.literal("set as not live"))
    }),
    return: z.null()
  },
  productVisibility: {
    data: z.object({
      productId: z.string(),
      action: z.literal("set as live").or(z.literal("set as not live"))
    }),
    return: z.null()
  },
  changeStoreName: {
    data: z.object({
      targetArtistId: z.string(),
      changeFrom: z.string(),
      changeTo: z.string(),
      reason: z.string().optional()
    }),
    return: z.object({ changed: z.boolean() })
  },
  deleteArtist: {
    data: z.object({
      userIdToDelete: z.string(),
      reason: z.string().optional()
    }),
    return: z
      .object({ status: z.literal("complete") })
      .or(
        z.object({ status: z.literal("partial"), reasons: z.array(z.string()) })
      )
  }
};

export type CloudFunctionReturn<T extends keyof typeof CloudFunctionSchemas> =
  z.infer<(typeof CloudFunctionSchemas)[T]["return"]>;

export type CloudFunctionData<T extends keyof typeof CloudFunctionSchemas> =
  z.infer<(typeof CloudFunctionSchemas)[T]["data"]>;
