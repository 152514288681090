import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useTaggedProducts } from "../../helpers/queries";
import { useMemo } from "react";
import AssetsResolver from "../../AssetsResolver";
import { shuffle } from "lodash";
import { NavLink } from "react-router-dom";

interface ArtworkCategory {
  name: string;
  tags: string[];
  image: string;
  description: string;
}

export const KnownCategories: ArtworkCategory[] = [
  {
    name: "oil painting",
    tags: ["oil painting"],
    description:
      "dive into the rich, textured world of oil paintings that capture complexity and detail",
    image: "foo"
  },
  {
    name: "nature",
    tags: ["flowers", "sunset", "nature", "forest", "ocean"],
    description:
      "experience the serenity of nature through these captivating art pieces",
    image: "foo"
  },
  {
    name: "pastels",
    tags: ["pastels", "pastel", "oilpastel"],
    description:
      "bright, vibrant works created with the subtle touch of pastels",
    image: "foo"
  },
  {
    name: "watercolor",
    tags: ["watercolor"],
    description:
      "explore the gentle strokes and fluid colors of watercolor paintings",
    image: "foo"
  },
  {
    name: "abstract",
    tags: ["abstract", "semiabstract"],
    description: "get lost in the interpretive and bold styles of abstract art",
    image: "foo"
  },
  {
    name: "photography",
    tags: ["photography"],
    description:
      "experience powerful stories captured through the insightful world of photography",
    image: "foo"
  }
];
export default function Categories() {
  const categories = useMemo(() => shuffle(KnownCategories), []);

  return (
    <Grid container spacing={2} pl={2} pr={2}>
      {categories.map((c) => (
        <Grid item key={c.name} xs={6} sm={3} md={3} lg={2}>
          <Category category={c} />
        </Grid>
      ))}
    </Grid>
  );
}

function Category({ category }: { category: ArtworkCategory }) {
  const [examples, examplesLoading, examplesError] = useTaggedProducts(
    category.tags,
    1
  );

  const example = useMemo(() => examples && examples[0], [examples]);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Link
      component={NavLink}
      sx={{ textDecoration: "none" }}
      to={`/artwork/category/${category.name}`}
    >
      <Card
        sx={{
          ...(!smallScreen && {
            transition: `0.6s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
            0.6s box-shadow,
            0.6s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12)`,

            "&:hover": {
              transform: "scale(1.05)", //1.35 alternatively
              zIndex: 5,
              boxShadow:
                "0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06)"
            }
          }),
          width: "100%"
        }}
      >
        <Box sx={{ display: "grid", width: "100%" }}>
          <Skeleton
            sx={{
              transform: "none",
              borderRadius: 0,
              gridArea: "1/1",
              aspectRatio: "3/1",
              zIndex: 2
            }}
          />

          {example && (
            <CardMedia
              component="img"
              sx={{
                gridArea: "1/1",
                aspectRatio: "3/1",
                zIndex: 3
              }}
              src={AssetsResolver.imageUrl(example.image.path, {
                aspect_ratio: "3:1"
              })}
            />
          )}
        </Box>
        <CardContent
          sx={{
            p: 0,
            pb: 0,
            "&:last-child": { pb: 0 },
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Typography fontWeight={"bold"}>{category.name}</Typography>
        </CardContent>
      </Card>
    </Link>
  );
}
