import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { orderBy, query, where } from "firebase/firestore";
import { DateTime } from "luxon";
import { MRT_ColumnDef, MaterialReactTable } from "material-react-table";
import { Fragment, useMemo } from "react";
import { SubOrder } from "shared/models/order-models";
import { currency } from "shared/tools/currency-tools";
import { subOrdersCollection } from "../../databaseModels/DatabaseModels";
import { TGGUser } from "../../redux/slices/authSlice";
import MarginProduct from "../productViews/ProductImage";
import { useCollectionDataCustom } from "../../helpers/queries";

const columns: MRT_ColumnDef<SubOrder & { user: TGGUser }>[] = [
  {
    accessorKey: "orderId",
    header: "Order Id"
  },
  {
    accessorFn: (subOrder) => subOrder.subState.status,
    header: "Status",
    id: "status"
  },
  {
    accessorFn: (subOrder) => subOrder.dates.createdOn,
    header: "Placed",
    id: "placed",
    Cell({ cell, column, row, table }) {
      const created = cell.getValue() as Date;
      return DateTime.fromJSDate(created).toRelative();
    }
  },
  {
    header: "Earnings",
    id: "earnings",
    accessorFn: (subOrder) =>
      subOrder.subEvents.payout.payouts
        ?.filter(
          (p) => p.payeeType === "artist" && p.payee === subOrder.user.uid
        )
        .reduce((amount, payout) => amount + payout.amount, 0),
    Cell({ cell, column, row, table }) {
      const cents = cell.getValue() as number;

      return currency(cents, { fromCents: true }).format();
    }
  },
  {
    accessorFn: (subOrder) =>
      subOrder.selectedNonprofit.shortName || subOrder.selectedNonprofit.name,
    id: "nonprofitDisplayName",
    header: "Supported Nonprofit"
  }
];

export default function SuborderTable({ user }: { user: TGGUser }) {
  // TODO: leverage error state
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [subOrders = [], isLoading, error] = useCollectionDataCustom(
    query(
      subOrdersCollection,
      where("artist", "==", user.uid),
      orderBy("dates.createdOn", "desc")
    )
  );

  return (
    <MaterialReactTable
      localization={{
        noRecordsToDisplay:
          "Your first order will appear here as soon as it's placed"
      }}
      columns={columns}
      enableColumnResizing
      data={subOrders.map((so) => ({ ...so, user }))}
      //enableColumnFilterModes
      //enableColumnOrdering
      //enableGrouping
      enablePinning
      //enableRowActions
      enableRowSelection
      //initialState={{ showColumnFilters: true }}
      positionToolbarAlertBanner="bottom"
      renderTopToolbarCustomActions={() => (
        <Typography component="span" variant="h4">
          My Orders
        </Typography>
      )}
      renderDetailPanel={({ row }) => {
        const subOrder = row.original;

        return (
          <Box m={3}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{ "& .MuiTableCell-root": { fontWeight: "bold" } }}
                  >
                    <TableCell>Placed</TableCell>
                    <TableCell>Fully shipped</TableCell>
                    <TableCell>Fully delivered</TableCell>
                    <TableCell>Paid</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "& .MuiTableCell-root": { fontWeight: "bold" } }}
                  >
                    <TableCell>
                      {subOrder.dates?.createdOn
                        ? DateTime.fromJSDate(
                            subOrder.dates?.createdOn
                          ).toRelative()
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {subOrder.subEvents?.shipment.fullyShippedOn
                        ? DateTime.fromJSDate(
                            subOrder.subEvents?.shipment.fullyShippedOn
                          ).toRelative()
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {subOrder.subEvents?.fulfillment.fullyDeliveredOn
                        ? DateTime.fromJSDate(
                            subOrder.subEvents?.fulfillment.fullyDeliveredOn
                          ).toRelative()
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {subOrder.subEvents?.payout.fullyCompletedOn
                        ? DateTime.fromJSDate(
                            subOrder.subEvents?.payout.fullyCompletedOn
                          ).toRelative()
                        : "-"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {subOrder.subState?.status !== "paid" ? (
              <Typography>
                This order will be paid out 8 days after it is fully delivered
              </Typography>
            ) : (
              <></>
            )}
            <Typography variant="h5" mt={3}>
              Purchased Artwork
            </Typography>
            <Grid container spacing={2} display={"flex"}>
              {Object.values(subOrder?.subCart || {}).map((p, index) => (
                <Fragment key={index}>
                  {subOrder?.subCart && subOrder.snapshots.products ? (
                    <>
                      <Grid
                        item
                        xs={2}
                        key={index}
                        display="flex"
                        flexDirection="row"
                      >
                        <MarginProduct
                          product={subOrder.snapshots.products[p.product.id]!}
                          variant={
                            subOrder.snapshots.printful.variants[
                              p.printfulVariant.id
                            ]!
                          }
                          width={75}
                          outerAspectRatio={{ horizontal: 1, vertical: 1 }}
                          fit={p.fit}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        display={"flex"}
                        flexDirection={"column"}
                      >
                        <Typography variant="body1" fontWeight="bold">
                          {subOrder.snapshots.products[p.product.id]!.title}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        display={"flex"}
                        flexDirection={"column"}
                      >
                        <Typography variant="body1">
                          {
                            subOrder.snapshots.printful.variants[
                              p.printfulVariant.id
                            ]!.name
                          }
                          ,{" "}
                          {p.fit.fitStyle === "center_crop"
                            ? "cropped"
                            : "with margins"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        display={"flex"}
                        flexDirection={"column"}
                      >
                        <Typography variant="body1">
                          Quantity:{p.count || "?"}
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </Fragment>
              ))}
            </Grid>
          </Box>
        );
      }}
    />
  );
}
