/**
 * This should be rendered in the top level of the app.
 *
 * It allows for going directly between different modals without animation.
 */

import { Dialog, useMediaQuery } from "@mui/material";
import { useDeviceSelectors } from "react-device-detect";
import { setMultiModalStage, useMultiModal } from "../zustand/multi-modal";
import LogInModal from "./LogInModal";

export default function MultiModal() {
  const multiModalFlow = useMultiModal();

  function onModalHide() {
    setMultiModalStage(null);
  }

  const stage = multiModalFlow.stage;
  const lastStage = multiModalFlow.lastStage;

  const dialogOpen = multiModalFlow.stage !== null;
  const fullScreenStage = false;
  // stage?.name === "setupWizard" ||
  // (stage === null && lastStage?.stage === "setupWizard");

  const smallScreen = useMediaQuery("(max-width: 500px)");

  const renderStage = (candidateStage: "setupWizard" | "login") => {
    return (
      stage?.name === candidateStage ||
      (stage === null && lastStage?.name === candidateStage)
    );
  };

  return (
    <Dialog
      // FIXME: we need a better flow for all this. A shared dialog only works if
      // we have some generic components that conform to an interface, the current
      // system is fragile
      open={dialogOpen}
      onClose={onModalHide}
      scroll="paper"
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: "none" //"blur(3px)"
          }
        }
      }}
      fullScreen={smallScreen}
    >
      {renderStage("login") && <LogInModal onHide={onModalHide} />}
    </Dialog>
  );
}
