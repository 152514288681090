type TggPlausibleEvent =
  | {
      event: "Added to Cart";
      properties: {
        artwork_title: string;
        artist_store: string;
        artwork_id: string;
        variant: string;
        fit: string;
      };
    }
  | {
      event: "Opened NonProfit Modal";
      properties: {
        nonprofit_name: string;
        nonprofit_id: string;
      };
    }
  | {
      event: "Viewed Artist Sign Up";
    }
  | {
      event: "404";
      properties: {
        path: string;
      };
    }
  | {
      event: "Redirected";
      properties: {
        from: string;
      };
    }
  | {
      event: "Calculated Shipping and Tax";
      properties: {
        baseTotal: string;
        itemCount: string;
      };
    }
  | {
      event: "Order Placed";
      properties: {
        checkoutSessionId: string;
        total: string;
      };
    };

// FIXME: window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) } ? if needed?

// this assumes the script was loaded
declare global {
  interface Window {
    plausible?: (event: string, custom: { props: Record<string, any> }) => void;
  }
}

let preLoadEventQueue: TggPlausibleEvent[] = [];

// TODO: this is very hacky, find a better solution (like exponential backoff, firing on script load, or simply bundling the plausible script with React)
// NOTE: this assumes that once window.plausible is defined, it will never cease to be defined, which should be accurate
function tryToConsumeQueue() {
  if (window.plausible === undefined) {
    // still not loaded. Cheap function to run, so try again in 300ms
    setTimeout(tryToConsumeQueue, 300);
  } else {
    // loaded. Consume the existing queue, and don't run this function again\

    if (preLoadEventQueue.length > 0) {
      console.debug(
        `Consuming ${preLoadEventQueue.length} events from the preLoadEventQueue`
      );
    }

    let failedToSendEvent = false;

    [...preLoadEventQueue].forEach((event) => {
      const trackingResult = trackEvent(event);

      if (trackingResult === "queued") {
        failedToSendEvent = true;
      }
    });

    if (failedToSendEvent) {
      console.error(
        "At least one event from queue was expected to be consumed but was not, unexpected state"
      );
      // could retry here, but I'd rather not get into a permanent loop and I have no idea why this state would occur
    } else {
      // all events were successfully sent
      preLoadEventQueue = [];
    }
  }
}

export default function trackEvent(
  event: TggPlausibleEvent
): "sent" | "queued" {
  if (window.plausible === undefined) {
    console.debug(`Queuing event (${event.event}) for later dispatch`);
    // to be tracked later, after plausible is loaded
    preLoadEventQueue.push(event);
    return "queued";
  } else {
    if ("properties" in event) {
      window.plausible(event.event, { props: event.properties });
    } else {
      window.plausible(event.event, { props: {} });
    }

    return "sent";
  }
}

// kick off the first attempt to consume the queue
tryToConsumeQueue();
