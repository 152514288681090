import { Order, SubOrder } from "shared/models/order-models";
import { CartState } from "../zustand/cart-state";

import {
  allocate,
  sumCurrencyArray,
  currency
} from "shared/tools/currency-tools";

interface EarningsSplit {
  artistsCut: currency;
  nonProfitsCut: currency;
  tggsCut: currency;
}

export function calculateEarningsSplit(earnings: currency): EarningsSplit {
  const allocation = allocate(earnings, {
    artistsCut: 50,
    nonProfitsCut: 30,
    tggsCut: 20
  });

  return allocation;
}

// TODO: return undefined for loading cart
export function calculateCartTotalCost(cart: CartState): currency {
  const lineItems = cart.items.map((item) => {
    return currency(item.product.price).multiply(item.count);
  });

  return sumCurrencyArray(lineItems);
}

export function calculateCartTotalPrintingCost(cart: CartState): currency {
  const lineItems = cart.items.map((item) => {
    // printful variant price is a string like "5.05"
    return currency(item.printfulVariant.price).multiply(item.count);
  });

  return sumCurrencyArray(lineItems);
}

export function calculateOrderTotalEarnings(order: Order): currency {
  const lineItems = order.cart.map((item) =>
    // product snapshots are defined for all cart items
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    currency(order.snapshots.products[item.product.id]!.price).multiply(
      item.count
    )
  );

  return sumCurrencyArray(lineItems);
}

export function calculateSuborderTotalEarnings(subOrder: SubOrder): currency {
  const lineItems = subOrder.subCart.map((item) =>
    // product snapshots are defined for all cart items
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    currency(subOrder.snapshots.products[item.product.id]!.price).multiply(
      item.count
    )
  );

  return sumCurrencyArray(lineItems);
}
