import { Box } from "@mui/material";
import PrivacyPolicy from "./PrivacyPolicy";

export default function PrivacyPolicyPage() {
  return (
    <Box
      sx={{
        width: "1200px",
        maxWidth: "100%",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignSelf: "center"
      }}
    >
      <PrivacyPolicy />
    </Box>
  );
}
