import { doc, getFirestore } from "firebase/firestore";
import { Route, Routes } from "react-router-dom";
import { artistsConverter } from "../../databaseModels/DatabaseModels";
import { TGGUser, userIsLoadedArtistUser } from "../../redux/slices/authSlice";
import { useAppSelector } from "../../redux/store";
import NotFoundPage from "../NotFoundPage";
import SingleArtistPage from "../SingleArtistPage";
import PageLoading from "../helpers/PageLoading";
import CreateOrUpdateProduct from "./CreateOrUpdateProduct";
import CreateOrUpdateProfile from "./CreateOrUpdateProfile";
import { useDocumentDataCustom } from "../../helpers/queries";

/**
 * Router for artist's experience
 *
 * This allows us to fetch any needed information at the top-level,
 * preventing refresh, and logically grouping all /mystore logic
 */
export default function StoreRouter({ user }: { user: TGGUser }) {
  const [artistDoc, artistDocLoading, artistDocError] = useDocumentDataCustom(
    doc(getFirestore(), "artists", user.uid).withConverter(artistsConverter)
  );

  const authState = useAppSelector((state) => state.auth);

  if (
    authState.awaitingCorrectRoles ||
    authState.userDocumentStatus === "loading" ||
    authState.userStatus === "loading" ||
    artistDocLoading
  ) {
    return <PageLoading />;
  }
  return (
    <Routes>
      {userIsLoadedArtistUser(user) && artistDoc && (
        <>
          <Route
            path="/"
            element={
              <SingleArtistPage
                privateView={true}
                artistUser={user}
                artistDocument={artistDoc}
              />
            }
          />
          <Route
            path="/upload/art"
            element={
              <CreateOrUpdateProduct artist={artistDoc} artistUser={user} />
            }
          />
          <Route
            path="/update/art/:slug"
            element={
              <CreateOrUpdateProduct artist={artistDoc} artistUser={user} />
            }
          />
          <Route
            path="/update/profile"
            element={
              <CreateOrUpdateProfile
                artistDoc={artistDoc}
                artistUser={user}
                creating={false}
              />
            }
          />
        </>
      )}

      {userIsLoadedArtistUser(user) && !artistDoc && (
        <Route
          path="*"
          element={
            <CreateOrUpdateProfile
              artistDoc={artistDoc}
              artistUser={user}
              creating={true}
            />
          }
        />
      )}

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
