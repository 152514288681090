import { Badge } from "@mui/material";
import { SVGMotionProps, motion } from "framer-motion";

const Path = (props: SVGMotionProps<SVGElement>) => (
  <motion.path
    fill="transparent"
    strokeWidth={2} //"3"
    stroke="rgba(0, 0, 0, 0.55)"
    strokeLinecap="round"
    {...props}
  />
);

export const MenuToggle = ({
  toggle,
  badgeContent
}: {
  toggle: () => void;
  badgeContent: number;
}) => (
  <button
    aria-label="Menu Button"
    style={{
      outline: "none",
      border: "none",
      cursor: "pointer",
      background: "transparent",
      marginTop: "7px"
    }}
    onClick={toggle}
  >
    <Badge
      badgeContent={badgeContent}
      sx={{
        "& > .MuiBadge-badge": { backgroundColor: "#812215", color: "white" }
      }}
    >
      <svg width="30" height="30" viewBox="0 0 23 23">
        <Path
          variants={{
            closed: { d: "M 2 2.5 L 20 2.5" },
            open: { d: "M 3 16.5 L 17 2.5" }
          }}
          transition={{ duration: 0.5 }}
        />
        <Path
          d="M 2 9.423 L 20 9.423"
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 }
          }}
          transition={{ duration: 0.5 }}
        />
        <Path
          variants={{
            closed: { d: "M 2 16.346 L 20 16.346" },
            open: { d: "M 3 2.5 L 17 16.346" }
          }}
          transition={{ duration: 0.5 }}
        />
      </svg>
    </Badge>
  </button>
);
