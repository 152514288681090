import { DateTime } from "luxon";
import { create } from "zustand";

type DevelopmentWarningState = {
  developmentWarning: boolean;
  setDevelopmentWarning(
    enabled: boolean,
    { updateStorage }?: { updateStorage: boolean }
  ): void;
  emailSubmitted: boolean;
  setEmailSubmitted(
    submitted: boolean,
    { updateStorage }?: { updateStorage: boolean }
  ): void;
};

type LocalStorageKey = "developmentWarningDismissedAt" | "emailSubmittedAt";

function recentTimestampWithin(localStorageKey: LocalStorageKey) {
  const maybeValue = window.localStorage.getItem(localStorageKey);
  const maybeDatetime = maybeValue ? DateTime.fromISO(maybeValue) : undefined;

  const twoDaysAgo = DateTime.now().minus({ days: 2 });

  // if the timestamp is before the cutoff window, it will be ignored
  return maybeDatetime && maybeDatetime > twoDaysAgo;
}

function writeTimestampTo(localStorageKey: LocalStorageKey) {
  const timestamp = DateTime.now().toISO();

  if (timestamp) {
    localStorage.setItem(localStorageKey, timestamp);
  } else {
    console.error("Failed to write timestamp to " + localStorageKey);
  }
}

export const useDevelopmentWarning = create<DevelopmentWarningState>((set) => ({
  developmentWarning: !recentTimestampWithin("developmentWarningDismissedAt"),
  emailSubmitted: Boolean(recentTimestampWithin("emailSubmittedAt")),
  setEmailSubmitted(submitted, options) {
    if (submitted && options?.updateStorage !== false) {
      writeTimestampTo("emailSubmittedAt");
    }
    set({ emailSubmitted: submitted });
  },

  setDevelopmentWarning(enabled, options) {
    if (!enabled && options?.updateStorage !== false) {
      writeTimestampTo("developmentWarningDismissedAt");
    }
    set({ developmentWarning: enabled });
  }
}));

window.addEventListener("storage", (e: StorageEvent) => {
  // local storage may have changed
  const devWarning = !recentTimestampWithin("developmentWarningDismissedAt");
  const submitted = Boolean(recentTimestampWithin("emailSubmittedAt"));

  const state = useDevelopmentWarning.getState();

  if (state.developmentWarning !== devWarning) {
    state.setDevelopmentWarning(devWarning, { updateStorage: false });
  }

  if (state.emailSubmitted !== submitted) {
    state.setEmailSubmitted(submitted, { updateStorage: false });
  }
});
