import { Google } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormHelperText,
  Typography
} from "@mui/material";
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithPopup,
  signOut
} from "firebase/auth";
import { Form, Formik, FormikErrors } from "formik";
import { Duration } from "luxon";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InviteDocument } from "shared/models/registration-models";
import { callCloudFunction } from "../functions/callCloudFunction";
import { accountAlreadyExists } from "../helpers/firebase-helpers";
import { getInviteDocument } from "../helpers/queries";
import { TGGUser } from "../redux/slices/authSlice";
import { useAppSelector } from "../redux/store";
import { dialog } from "../zustand/imperative-dialog";
import { useJoinState } from "../zustand/join-state";
import { setMultiModalStage } from "../zustand/multi-modal";
import FormikField from "./formik/FormikField";
import DialogTitleWithClose from "./helpers/DialogTitleWithClose";
import PageLoading from "./helpers/PageLoading";
import TermsOfService from "./legal/TermsOfService";

function extractUUID(inputString: string): string | null {
  // RegExp for UUID version 4
  const uuidRegExp =
    /\b[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\b/i;
  const match = inputString.match(uuidRegExp);
  return match ? match[0] : null;
}

/**
 * All incomplete accounts end up here. An incomplete account is any account that does not
 * have any roles associated with it.
 *
 * This acts as a funnel into a full account
 *    (full = has user document + has roles)
 *
 * IF logged in:
 *  * simply waits, if has incomplete roles
 *  * otherwise, checks if there's a pending invite for the account
 *
 * IF not logged in:
 *  * asks for email
 *  * checks if there's a pending invite for that email
 *
 * THEN, if there's a pending invite:
 *
 * asks for password OR to link (login_hint'ed) google auth
 *
 *  * IF used password, then awaits email confirmation
 *  * after login, sets up the account
 */
export default function JoinPage() {
  const authState = useAppSelector((state) => state.auth);

  const [googleLoginError, setGoogleLoginError] = useState("");

  /**
   *
   * if my user state is loading -> loading
   * if I'm awaiting current roles -> Loading
   *
   * if I'm on join, and have a full account ->
   *    there is already an account associated with your login
   *    shows button for "take me home"
   *
   * if I don't have an invitation -> get an invitation
   *
   * if I do have an invitation ->
   *
   *    if I'm logged in as the right user (user for my invitation) -> ACT: accept the invitation (load while accepting)
   *    if I'm logged in as the WRONG user ->
   *
   *    if I'm not logged in at all -> show the password or google auth sign in page
   *
   */
  const [invitation, setInvitation] = useState<InviteDocument | undefined>(
    undefined
  );

  // Loading should be for transient states
  const loading =
    authState.userStatus === "loading" ||
    (authState.user && authState.userDocumentStatus === "loading") ||
    authState.awaitingCorrectRoles;

  if (loading) {
    // transient state, load while waiting
    return (
      <PageLoading
        hint="loading login details"
        timeout={Duration.fromObject({ seconds: 15 })}
      />
    );
  } else if (authState.user && authState.userDocumentStatus !== "error") {
    // full user account, redirect to home page
    return <RedirectFullAccount user={authState.user} />;
  }

  // now we have no account or a partial account, and either way we want to
  // lookup the invitation first

  if (!invitation) {
    // show the initial check for invite form
    return (
      <FindInvitation setInvitation={setInvitation} user={authState.user} />
    );
  } else {
    // we have an invite, and we need to now link it with an account

    if (
      // user exists and has the right email
      authState.user &&
      authState.user?.email?.toLowerCase().trim() ===
        invitation.email.toLowerCase().trim()
    ) {
      // we accept the invite by calling the cloud function
      return <AcceptInvite invitation={invitation} user={authState.user} />;
    } else if (
      // user exists and has the wrong email
      authState.user &&
      authState.user?.email?.toLowerCase().trim() !==
        invitation.email.toLowerCase().trim()
    ) {
      // show the user that the account they are logged into does not match the invitation, and ???
      return <WarnAccountMismatch />;
    } else {
      // no user at all
      return (
        <CreateAccount
          invitation={invitation}
          googleLoginError={googleLoginError}
          setGoogleLoginError={setGoogleLoginError}
        />
      );
    }
  }
}

function RedirectFullAccount({ user }: { user: TGGUser }) {
  const navigate = useNavigate();

  useEffect(() => {
    const roles = user?.roles;
    if (roles?.includes("admin") || roles?.includes("reporter")) {
      navigate("/admin");
    } else if (roles?.includes("artist")) {
      navigate("/mystore");
    } else {
      navigate("/");
    }
  }, []);

  return <PageLoading hint="Navigating" />;
}

function WarnAccountMismatch() {
  return (
    <Typography>
      You are logged into an account that doesn't match the invite. Please log
      out and try again.
    </Typography>
  );
}

function AcceptInvite({
  user,
  invitation
}: {
  user: TGGUser;
  invitation: InviteDocument;
}) {
  useEffect(() => {
    async function acceptInvite() {
      try {
        await callCloudFunction("acceptInvite", {
          inviteCode: invitation.inviteCode
        });
      } catch (e) {
        dialog.error("error accepting invite", e);
      }
    }

    acceptInvite();
  }, []);

  return (
    <PageLoading
      hint="Accepting invitation"
      timeout={Duration.fromObject({ seconds: 25 })}
    />
  );
}

function FindInvitation({
  setInvitation,
  user
}: {
  setInvitation: (invite: InviteDocument) => void;
  user: TGGUser | undefined;
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { inviteCode, setInviteCode } = useJoinState();

  useEffect(() => {
    const codeParam = searchParams.get("code");

    if (codeParam && !inviteCode) {
      // Consume (delete) the emailParam
      // TODO: re-enable after consumption is more stable (and code won't get lost)
      // searchParams.delete("code");
      // setSearchParams(searchParams, { replace: true });

      // Set the invite code we're working with
      // TODO: use zustand for this
      setInviteCode(codeParam);
    }
  }, [searchParams, setSearchParams]);

  const [tosDialogOpen, setTosDialogOpen] = useState(false);

  return (
    <Box
      sx={{
        width: "400px",
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ml: "auto",
        mr: "auto",
        mt: 3
      }}
    >
      <Typography variant="h4">Join TGG</Typography>
      <Typography>
        If you've been sent an invitation{user && ` (for ${user.email})`},
        please enter the invite code
      </Typography>

      <Formik
        enableReinitialize
        initialValues={{ code: inviteCode || "" }}
        validate={(values) => {
          const maybeCode = extractUUID(values.code);

          if (!maybeCode) {
            return { code: "Invalid Code" };
          } else {
            return {};
          }
        }}
        onSubmit={async (values, { setErrors }) => {
          // we check this during validation, so if we get here we can assert it
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const code = extractUUID(values.code)!;

          const inviteDoc = await getInviteDocument(code);

          if (!inviteDoc) {
            setErrors({
              code: "No invite for this code. If you've already joined, try logging in"
            });
          } else {
            // we have an outstanding invite!
            setInviteCode(code);
            setInvitation(inviteDoc);
          }
        }}
      >
        <Form>
          <Box sx={{ maxWidth: "400px", p: 3 }}>
            <FormikField name="code" size="small" label="Code" />
            <Button variant="contained" type="submit" fullWidth>
              Accept Invitation
            </Button>
            <Alert severity="info">
              <Typography>
                By accepting an invitation you are agreeing to abide by our{" "}
                <Box
                  component="span"
                  onClick={() => setTosDialogOpen(true)}
                  role="button"
                  sx={{ cursor: "pointer", color: "primary.main" }}
                >
                  Terms of Service
                </Box>
              </Typography>
            </Alert>
          </Box>
        </Form>
      </Formik>

      <Dialog open={tosDialogOpen} onClose={() => setTosDialogOpen(false)}>
        <DialogTitleWithClose onClose={() => setTosDialogOpen(false)}>
          Terms of Service
        </DialogTitleWithClose>
        <DialogContent>
          <TermsOfService />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTosDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

function CreateAccount({
  invitation,
  googleLoginError,
  setGoogleLoginError
}: {
  invitation: InviteDocument;
  googleLoginError: string;
  setGoogleLoginError: (error: string) => void;
}) {
  const [alreadyExists, setAlreadyExists] = useState<"yes" | "no" | "loading">(
    "loading"
  );

  useEffect(() => {
    async function f() {
      const alreadyExists = await accountAlreadyExists(invitation.email);
      setAlreadyExists(alreadyExists ? "yes" : "no");
    }

    f();
  }, []);

  if (alreadyExists === "loading") {
    return <PageLoading />;
  }

  // google auth is more secure, and should be used for accounts that can see sensitive data
  const requireGoogleAuth =
    invitation.roles.includes("admin") || invitation.roles.includes("reporter");

  return (
    <Box
      sx={{
        width: "1200px",
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ml: "auto",
        mr: "auto",
        mt: 3
      }}
    >
      {alreadyExists === "yes" ? (
        <>
          <Typography variant="h4">Associate Account</Typography>
          <Typography paragraph>
            An account already exists for the invited email ({invitation.email}
            ). Please log in to continue.{" "}
          </Typography>
          <Button
            variant="contained"
            onClick={() =>
              setMultiModalStage({
                name: "login",
                deepLink: "login",
                prefillEmail: invitation.email
              })
            }
          >
            Log In
          </Button>
        </>
      ) : (
        <>
          <Typography variant="h4">Create Account</Typography>
          <Typography paragraph>
            To accept your invite for {invitation.email}
            {!requireGoogleAuth && ", either"}:
          </Typography>

          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            validate={(values) => {
              const errors: FormikErrors<{
                password: "";
                confirmPassword: "";
              }> = {};

              if (!values.password) {
                errors.password = "Required";
              } else if (values.password.length < 8) {
                errors.password = "Password must be at least 8 characters";
              }

              if (values.password !== values.confirmPassword) {
                errors.confirmPassword = "Passwords do not match";
              }

              return errors;
            }}
            onSubmit={async (values, { setErrors }) => {
              // FIXME: error handling here

              try {
                await createUserWithEmailAndPassword(
                  getAuth(),
                  invitation.email,
                  values.password
                );
              } catch (e) {
                window.alert(`Error creating account: ${e}`);
              }
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 3,
                flexDirection: { xs: "column", sm: "row" }
              }}
            >
              {" "}
              {!requireGoogleAuth && (
                <>
                  <Form>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 3 }}
                    >
                      <FormikField
                        name="password"
                        size="small"
                        type="password"
                        label="Password"
                      />
                      <FormikField
                        name="confirmPassword"
                        size="small"
                        type="password"
                        label="Confirm Password"
                      />
                      <Button variant="contained" type="submit" fullWidth>
                        Create Password-based Account
                      </Button>
                    </Box>
                  </Form>

                  <Divider
                    sx={{ display: { xs: "none", sm: "flex" } }}
                    variant="middle"
                    orientation="vertical"
                    flexItem
                  >
                    OR
                  </Divider>

                  <Divider
                    sx={{ display: { xs: "flex", sm: "none" } }}
                    variant="middle"
                    orientation="horizontal"
                    flexItem
                  >
                    OR
                  </Divider>
                </>
              )}
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Button
                  sx={{ flexGrow: 1 }}
                  onClick={async () => {
                    const provider = new GoogleAuthProvider();
                    const auth = getAuth();

                    // seehttps://firebase.google.com/docs/auth/web/google-signin
                    provider.setCustomParameters({
                      login_hint: invitation.email
                    });

                    const user = await signInWithPopup(auth, provider);

                    if (
                      user.user.email?.trim().toLowerCase() !==
                      invitation.email.trim().toLowerCase()
                    ) {
                      setGoogleLoginError(
                        `Google account must be the one associated with ${invitation.email}`
                      );
                      signOut(auth);
                    }

                    return user;
                  }}
                  variant="contained"
                  endIcon={<Google />}
                >
                  Create Account with Google Login
                </Button>
                <FormHelperText error>
                  {googleLoginError ? googleLoginError : null}
                </FormHelperText>
              </Box>
            </Box>
          </Formik>
        </>
      )}
    </Box>
  );
}
