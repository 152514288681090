import { useFormikContext } from "formik";
import { useEffect } from "react";

import "./FormikScrollToError.css";

export default function FormikScrollToError(props: {
  flash?: boolean;
  modal?: boolean;
}) {
  const formik = useFormikContext();
  const submitting = formik?.isSubmitting;

  /**
   * Looks for the first instance of our own ErrorMessage component,
   * adds a flash class which has a css animation on it, scrolls to it,
   * and focuses in on the associated input element
   *
   * This is useful for drawing attention to invalid form fields when
   * the user tries to submit a form
   */
  useEffect(() => {
    const errors = document.getElementsByClassName(
      "MuiInputBase-root Mui-error"
    );

    if (errors.length >= 1) {
      const firstError = errors[0] as HTMLInputElement;
      //const element = (firstError.parentElement ?? firstError);

      if (props.flash === undefined || props.flash === true) {
        if (!firstError?.classList?.contains("flash")) {
          firstError?.classList?.add("flash");

          setTimeout(() => {
            firstError?.classList?.remove("flash");
          }, 1100);
        }
      }

      firstError?.focus();

      if (props.modal === undefined || props.modal) {
        const modalContent = document.getElementsByClassName(
          "MuiDialogContent-root"
        )[0] as HTMLDivElement;

        firstError.scrollIntoView({ behavior: "smooth" });

        // const intersectionObserver = new IntersectionObserver((entries) => {
        //     let [entry] = entries;
        //     if (entry.isIntersecting) {
        //       //setTimeout(() => alert(`${entry.target.id} is visible`), 100)
        //       modalContent.scrollBy({top: -10, behavior: "smooth"})
        //     }
        // });

        //intersectionObserver.observe(firstError);
        //to account for fieldset label
        setTimeout(
          () => modalContent.scrollBy({ top: -10, behavior: "smooth" }),
          400
        );
      } else {
        // navbar is sticky, so scrolling needs to account for it
        const navbar = document.getElementById("navbar");
        const navbarHeight = navbar?.offsetHeight || 0;

        const yOffset = -navbarHeight;
        const y =
          firstError.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [submitting, props.flash, props.modal]);
  return null;
}
