import { z } from "zod";
import { NameSchema } from "./artist-models";

export const InviteUserData = z
  .object({
    email: z
      .string()

      .regex(
        /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
        "email must be lowercase"
      )
      .email(),
    name: NameSchema,
    // TODO: regex requirements for store name
    storeName: z.string().min(2).optional(),
    roles: z.array(
      z.union([z.literal("artist"), z.literal("admin"), z.literal("reporter")])
    )
  })
  .superRefine((val, ctx) => {
    if (val.roles.includes("reporter") && val.roles.includes("admin")) {
      ctx.addIssue({
        code: "custom",
        message: "User cannot both be an admin and a reporter",
        path: ["roles"]
      });
    }
  });

// TODO: add timestamps and way of parsing them
export const InviteDocument = z.intersection(
  InviteUserData,

  z
    .object({
      invitedOn: z.date(),
      invitedBy: z.string(),
      inviteCode: z.string(),
      acceptedOn: z.date().optional(),
      revokedOn: z.date().optional(),
      accepted: z.boolean(),
      acceptedBy: z.string().optional(),
      revoked: z.boolean(),
      outstanding: z.boolean()
    })
    .and(
      z
        .object({ storeName: z.string(), storeSlug: z.string() })
        .or(z.object({ storeName: z.undefined(), storeSlug: z.undefined() }))
    )
);

export type InviteDocument = z.infer<typeof InviteDocument>;
