import { Button } from "@mui/material";
import { PrintfulApiVariant } from "shared/models/printful-models";
import { getVariantSize } from "shared/printing";

interface SingleProductSizeButtonProps {
  sizeOption: PrintfulApiVariant;
  selected: boolean;
}

export default function SingleProductSizeButton(
  props: SingleProductSizeButtonProps
) {
  const size = getVariantSize(props.sizeOption);

  return (
    <Button
      variant={props.selected ? "outlined" : "text"}
      size="small"
      sx={{
        padding: 0.3,
        m: 0,
        minWidth: "fit-content",
        minHeight: "fit-content",
        lineHeight: "normal",
        textTransform: "none"
      }}
      aria-label={size.width + "inches" + " x " + size.height + "inches"}
    >
      {size.width}"x{size.height}"
    </Button>
  );
}
