import { limit, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { productsCollection } from "../databaseModels/DatabaseModels";

// import swiper react components, modules, and needed css
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Skeleton,
  Typography
} from "@mui/material";
import Swiper from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-cube";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  Autoplay,
  EffectCoverflow,
  Navigation,
  Pagination
} from "swiper/modules";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";

import {
  Brush,
  Loyalty,
  NavigateBefore,
  NavigateNext
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import pagesVisitedSlice from "../redux/slices/pagesVisitedSlice";
import store from "../redux/store";

import NewspaperIcon from "@mui/icons-material/Newspaper";
import { ProductDocument } from "shared/models/product-models";
import AssetsResolver, { staticAsset } from "../AssetsResolver";
import { useCollectionDataCustom } from "../helpers/queries";
import Buckets from "./Buckets";
import Schmear from "./about/Schmear";

export default function LandingPage() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // no need to subscribe to this state, just get it once
  const [previouslyVisited] = useState(
    Boolean(store.getState().pagesVisited["/"])
  );

  useEffect(() => {
    dispatch(pagesVisitedSlice.actions.setVisited("/"));
  }, [dispatch]);

  // TODO: test for edge conditions of loading none or < 6 artwork, there is a bug with initialIndex (and delayed loading)
  const [artwork = [null, null, null, null, null, null]] =
    useCollectionDataCustom(
      query(productsCollection, where("live", "==", true), limit(6))
    );

  const slides: ("initial-text-slide" | ProductDocument | null)[] = [
    "initial-text-slide",
    ...artwork
  ];

  // const theme = useTheme();
  // const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedIndex, setSelectedIndex] = useState(0);

  function onCarouselChange(now: number) {
    // we use infinite loops, so we need to take the mod
    const i = now % artwork.length;
    setSelectedIndex(i);
  }

  const [loadedImages, setLoadedImages] = useState<Record<number, boolean>>({});

  const [swiper, setSwiper] = useState<Swiper | null>(null);

  const imageFadeInSecs = 1;
  const autoPlayDelayMs = 9000;

  useEffect(() => {
    if (Object.values(loadedImages).length === 3) {
      // fully loaded, start autoplay after delay

      setTimeout(() => {
        if (swiper) {
          swiper.params.autoplay = { delay: autoPlayDelayMs };
          swiper.autoplay.start();
        }
      }, imageFadeInSecs * 1000);
    }
  }, [loadedImages, swiper]);

  return (
    <Box
      sx={{
        pt: 6,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .swiper-slide-active .artwork-info-panel": {
          display: "flex"
        }
        // backgroundImage: `url(${emptyWhiteGallery})`,
        // backgroundSize: "contain"
      }}
    >
      <Box
        sx={{
          width: "100%",
          ml: 3,
          mr: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <IconButton
          centerRipple={false}
          sx={{
            // display: {
            //     xs: "none",
            //     sm: "flex"
            // },
            borderRadius: 0, //20,
            height: "209px",
            mb: "20px",
            width: "40px",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
            // "&:hover svg": {
            //     transform: "scale(1.3)",
            //     transition: "all 0.4s"
            // }
          }}
          onClick={() => swiper?.slidePrev()}
        >
          <NavigateBefore sx={{ fontSize: "80px" }} />
        </IconButton>
        <SwiperComponent
          speed={500}
          slideToClickedSlide
          onSwiper={(swiper) => setSwiper(swiper)}
          style={{
            maxWidth: "min(800px, 100%)",
            marginLeft: "unset",
            marginRight: "unset"
          }}
          effect={"coverflow"}
          grabCursor
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0, //50
            stretch: 100,
            depth: 200,
            modifier: 1,
            slideShadows: false,
            scale: 1
          }}
          pagination={false}
          modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
          className="mySwiper"
          navigation={false}
          loop
          initialSlide={0}
          onSlideChange={(swiper) => onCarouselChange(swiper.activeIndex)}
        >
          {slides.map((slide, index) => (
            <SwiperSlide
              key={index}
              style={{ width: "min(300px, 56vw)" }}
              data-swiper-autoplay={
                slide === "initial-text-slide"
                  ? "10000"
                  : autoPlayDelayMs.toString()
              }
            >
              <Box
                sx={{
                  position: "relative",
                  width: "300px",
                  aspectRatio: "1/1",
                  maxWidth: "100%",
                  height: "auto",
                  boxShadow: 3
                }}
              >
                <Skeleton
                  component="div"
                  style={{
                    width: "300px",
                    opacity: 1,
                    position: "absolute",
                    height: "auto",
                    top: 0,
                    left: 0,
                    display: "block",
                    gridArea: "1/1",
                    maxWidth: "100%",
                    minWidth: 0,
                    aspectRatio: "1/1"
                  }}
                  variant="rectangular"
                />
                {slide === "initial-text-slide" ? (
                  <Box
                    style={{
                      //backgroundColor: "rgb(232, 232, 232)",
                      //backgroundColor: "#4158D0",
                      // background: "linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898",
                      // backgroundBlendMode: "multiply,multiply",
                      // backgroundImage: "linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%)",
                      backgroundColor: "#DBDFCC",
                      backgroundImage: `url(${staticAsset(
                        "partners-intro.jpeg"
                      )})`,
                      backgroundSize: "contain",
                      boxShadow: "inset 0 0 0 1000px rgba(0,0,0,.75)",
                      // border: "5px solid rgb(112, 33, 44)",
                      // borderStyle: "double",
                      width: "100%",
                      height: "100%",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      boxSizing: "border-box", // so that padding doesn't increase width
                      padding: 10,
                      color: "white",
                      gridArea: "1/1",
                      zIndex: 1,
                      opacity: loadedImages[1] || previouslyVisited ? 1 : 0,
                      transition: `opacity ${imageFadeInSecs}s`
                    }}
                  >
                    {/* https://www.svgrepo.com/svg/398652/woman-artist */}
                    <Typography
                      style={
                        {
                          // backgroundColor: "rgb(0,0,0,0.4)",
                          // borderRadius: "10px",
                          // padding: 10
                        }
                      }
                      whiteSpace="pre"
                      textAlign="center"
                      variant="h5"
                      color="white"
                    >
                      Bridging Art {"&\n"}{" "}
                      <Typography
                        component="span"
                        fontWeight="bold"
                        variant="h5"
                      >
                        Mental Health
                      </Typography>
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center"
                        // backgroundColor: "rgb(0,0,0,0.4)",
                        // borderRadius: "10px",
                        // padding: 10
                      }}
                      mt={3}
                    >
                      <Typography textAlign="center">
                        Swipe to see featured artwork and artists!
                      </Typography>
                      {/* <img style={{height: "70px"}} src={womanArtistSvg}/> */}
                    </Box>
                  </Box>
                ) : (
                  <>
                    <img
                      onLoad={() =>
                        setLoadedImages((old) => ({ ...old, [index]: true }))
                      }
                      alt={slide?.title || "Loading"}
                      src={
                        slide
                          ? AssetsResolver.imageUrl(
                              slide.image.path,
                              "artworkthumbnail"
                            )
                          : ""
                      }
                      width="300"
                      style={{
                        opacity:
                          loadedImages[index] || previouslyVisited ? 1 : 0,
                        transition: `opacity ${imageFadeInSecs}s`,
                        //transitionTimingFunction: "cubic-bezier(.42,.16,1,.67)",
                        aspectRatio: "1/1",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "auto",
                        display: "block",
                        gridArea: "1/1",
                        zIndex: 1,
                        maxWidth: "100%"
                      }}
                    />

                    {slide && (
                      <Box
                        className="artwork-info-panel"
                        sx={{
                          // will get overridden if active slide
                          display: "none",
                          opacity:
                            loadedImages[index] || previouslyVisited ? 1 : 0,
                          transition: `opacity ${imageFadeInSecs}s`,
                          //transitionTimingFunction: "cubic-bezier(.42,.16,1,.67)",
                          position: "absolute",
                          zIndex: 2,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: "rgb(0,0,0,0.4)",
                          color: "white",
                          p: 0.1,
                          alignItems: "center"
                        }}
                      >
                        <Typography ml={1} fontStyle="italic">
                          {slide.title}
                        </Typography>
                        <Link
                          to={`/artwork/${slide.slug || slide.productId}`}
                          style={{ marginLeft: "auto", textDecoration: "none" }}
                        >
                          <Button
                            sx={{
                              color: "white",
                              "&:hover": {
                                backgroundColor: "rgb(255,255,255,0.2)"
                              }
                            }}
                          >
                            View
                          </Button>
                        </Link>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </SwiperSlide>
          ))}
        </SwiperComponent>
        <IconButton
          centerRipple={false}
          sx={{
            // display: {
            //     xs: "none",
            //     sm: "flex"
            // },
            borderRadius: 0, //20,
            height: "209px",
            mb: "20px",
            width: "40px",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
            // "&:hover svg": {
            //     transform: "scale(1.3)",
            //     transition: "all 0.4s"
            // }
          }}
          onClick={() => swiper?.slideNext()}
        >
          <NavigateNext sx={{ fontSize: "80px" }} />
        </IconButton>
      </Box>

      <Typography
        maxWidth={"min(800px, 100%)"}
        m={3}
        sx={{ textIndent: 30, fontSize: "1.2em" }}
      >
        {/* <Typography component="span" color="primary" fontStyle="italic" sx={{fontSize: "1em"}}> The Giving Gallery </Typography>
                is a free online platform for artists to sell their artwork and share their thoughts about mental health. Art sale
                proceeds are split between the artist, The Giving Gallery, and our partnered mental health charities.
                */}
        <Typography
          component="span"
          color="primary"
          fontStyle="italic"
          sx={{ fontSize: "1em" }}
        >
          {" "}
          The Giving Gallery{" "}
        </Typography>
        promotes artists and artwork, and a percentage of every sale supports
        mental health charities to help fund their suicide prevention and
        advocacy programs.
      </Typography>

      <Box
        sx={{
          display: "flex",
          pl: 2,
          pr: 2,
          columnGap: 2,
          width: "100%",
          maxWidth: "900px",
          justifyContent: "space-evenly"
        }}
      >
        <Link to="/apply">
          <Button
            size="small"
            endIcon={<Brush />}
            variant="contained"
            sx={{ fontSize: "1.1em" }}
          >
            Apply as an Artist
          </Button>
        </Link>

        <Link to="/artwork" style={{ textDecoration: "none" }}>
          <Button
            size="small"
            endIcon={<Loyalty />}
            variant="contained"
            sx={{ fontSize: "1.1em" }}
          >
            Explore Gallery
          </Button>
        </Link>
      </Box>
      <Schmear text="We believe art is a pathway to mental health awareness" />
      <Box
        sx={{
          width: "100%",
          ml: 3,
          mr: 3,
          mt: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        <Box>
          <Typography variant="h4">
            Latest News <NewspaperIcon />
          </Typography>
        </Box>

        <List
          sx={{
            listStyleType: "disc",
            fontSize: "1.2em",
            m: 2,
            maxWidth: "min(800px, 100%)",
            "& li": {
              ml: 2,
              display: "list-item"
            }
          }}
        >
          <ListItem>
            <Typography>
              3/7/2024: We’ve partnered with{" "}
              <Link to="/partners/chla">Children's Hospital Los Angeles</Link>.
              Funds raised for CHLA on The Giving Gallery will be directed to
              the Adolescent Medicine’s Behavioral Health Program.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              9/21/2023: The Giving Gallery platform is officially launched!
              Artists can <Link to="/apply">sign up</Link>, and buyers can{" "}
              <Link to="/artwork">purchase art</Link> that supports our
              nonprofit partners.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              8/1/2023: We’ve partnered with Mental Health America. Check out
              the{" "}
              <Link to="https://mhanational.org/news/giving-gallery-partner-promote-mental-health-awareness-through-art">
                press release
              </Link>{" "}
              and{" "}
              <Link to="https://mhanational.org/blog/guest-blog-art-mental-health-and-giving-together">
                blog by Diana Chao
              </Link>
              .
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              6/1/2023: We’ve partnered with{" "}
              <Link to="https://www.letterstostrangers.org/">
                Letters to Strangers
              </Link>
              .
            </Typography>
          </ListItem>
        </List>
      </Box>
      <Buckets
        buckets={[
          {
            img: staticAsset("hand-reaching-away.png"),
            title: "Sell Your Artwork",
            content: (
              <Typography>
                Join our{" "}
                <Link onClick={(e) => e.stopPropagation()} to="/artists">
                  community of artist advocates
                </Link>{" "}
                raising awareness of mental health through sharing their art.
                Check out artist stories, and{" "}
                <Link onClick={(e) => e.stopPropagation()} to="/apply">
                  apply here
                </Link>
                .
              </Typography>
            ),
            action: () => navigate("/artists")
          },
          {
            img: staticAsset("handing-art-to-another-3.png"),
            title: "Buy Artwork",
            content: (
              <Typography>
                Shop for art in our{" "}
                <Link onClick={(e) => e.stopPropagation()} to="/artwork">
                  Gallery
                </Link>
                ! Every sale benefits the artist as well as a{" "}
                <Link onClick={(e) => e.stopPropagation()} to="/nonprofits">
                  partnered mental health nonprofit
                </Link>{" "}
                of the buyer's choice.
              </Typography>
            ),
            action: () => navigate("/artwork")
          },
          {
            img: staticAsset("reaching-hand-in-painting.png"),
            title: "Partner with Us",
            content: (
              <Typography>
                Shop for art in our{" "}
                <Link onClick={(e) => e.stopPropagation()} to="/artwork">
                  Gallery
                </Link>
                ! Every sale benefits the artist as well as a{" "}
                <Link onClick={(e) => e.stopPropagation()} to="/nonprofits">
                  partnered mental health nonprofit
                </Link>{" "}
                of the buyer's choice.
              </Typography>
            ),
            action: () => navigate("/nonprofits")
          }
        ]}
      />

      <Box
        sx={{
          mt: 5,
          width: "1000px",
          maxWidth: "100%",
          display: "flex",
          justifyContent: "space-around",

          "& iframe": {
            flex: 1,
            aspectRatio: "560/315",
            width: "100%",
            height: "auto",
            maxWidth: "400px"
          },
          "& iframe.first": {
            ml: 3,
            mr: {
              xs: 3,
              sm: 1.5
            }
          },
          "& iframe.second": {
            display: {
              xs: "none",
              sm: "unset"
            },

            ml: {
              xs: 3,
              sm: 1.5
            },
            mr: 3
          }
        }}
      >
        <iframe
          className="first"
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/UHjyN5fhN0M"
          title="YouTube video player"
          frameBorder="0"
          allow="encrypted-media; picture-in-picture; fullscreen"
          allowFullScreen
        ></iframe>

        <iframe
          className="second"
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/nAUFzWNS7Zs"
          title="YouTube video player"
          frameBorder="0"
          allow="encrypted-media; picture-in-picture; fullscreen"
          allowFullScreen
        ></iframe>
      </Box>
    </Box>
  );
}
