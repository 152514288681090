import { z } from "zod";
import { FitStyle, OrderCosts, USAddress } from "./order-models";
import { PrintfulShippingDetails } from "./printful-models";
import { TaxBreakdown } from "./stripe-models";

export const CheckoutData = z.object({
  customer: z.object({
    name: z.object({
      first: z
        .string()
        .min(1, "must be at least 1 character")
        .max(50, "must be less than 50 characters"),
      last: z
        .string()
        .min(1, "must be at least 1 character")
        .max(50, "must be less than 50 characters")
        .optional()
    }),
    shippingAddress: USAddress,
    email: z.string().email(),
    phone: z.string().nullable()
  }),
  cart: z.array(
    z.object({
      product: z.object({
        id: z.string(),
        basePrice: z.string(),
        version: z.number()
      }),
      count: z.number(),
      printfulVariant: z.object({
        id: z.number(),
        printfulProductId: z.number(),
        price: z.string()
      }),
      fit: FitStyle
    })
  ),
  selectedNonprofit: z.object({
    id: z.string()
  }),
  gift: z
    .object({
      // part of the printful API for orders
      gifterName: z.string().max(60).optional().nullable(),
      message: z.string().max(200).optional().nullable()
    })
    .nullable()
});

export type CheckoutData = z.infer<typeof CheckoutData>;

export const PaymentIntentDetails = z.object({
  clientSecret: z.string(),
  taxBreakdown: z.array(TaxBreakdown),
  checkoutSessionId: z.string(),
  standardShipping: PrintfulShippingDetails,
  costs: OrderCosts
});

export type PaymentIntentDetails = z.infer<typeof PaymentIntentDetails>;
