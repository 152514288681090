import { ArtistDocument } from "./artist-models";
import { NonProfitDocument } from "./nonprofit-models";
import {
  BackupOperationDocument,
  NotificationEvent
} from "./operations-models";
import { CheckoutSession, Order, SubOrder } from "./order-models";
import { ProductDocument } from "./product-models";
import { InviteDocument } from "./registration-models";
import { UserDocument } from "./user-models";

import { z } from "zod";

import {
  PrintfulCategoriesDocument,
  PrintfulProductDocument,
  PrintfulVariantDocument
} from "./printful-models";
import { StoreSlugDoc } from "./store-models";
import { DeletionDocument } from "./deletion-models";

export const AllCollections = {
  products: {
    schema: ProductDocument,
    idField: "productId",
    name: "products"
  },
  artists: {
    schema: ArtistDocument,
    idField: "artistId",
    name: "artists"
  },
  invites: {
    schema: InviteDocument,
    idField: "inviteCode",
    name: "invites"
  },
  users: {
    schema: UserDocument,
    idField: "userId",
    name: "users"
  },
  nonprofits: {
    schema: NonProfitDocument,
    idField: "id",
    name: "nonProfits"
  },
  checkoutSessions: {
    schema: CheckoutSession,
    idField: "checkoutSessionId",
    name: "checkoutSessions"
  },
  orders: {
    schema: Order,
    idField: "orderId",
    name: "orders"
  },
  subOrders: {
    schema: SubOrder,
    idField: "suborderId",
    name: "subOrders"
  },
  firestoreBackups: {
    schema: BackupOperationDocument,
    idField: "id",
    name: "firestoreBackups"
  },
  printfulCategories: {
    schema: PrintfulCategoriesDocument,
    idField: "id",
    name: "printfulCategories"
  },
  printfulProducts: {
    schema: PrintfulProductDocument,
    idField: "id",
    name: "printfulProducts"
  },
  printfulVariants: {
    schema: PrintfulVariantDocument,
    idField: "id",
    name: "printfulVariants"
  },
  config: {
    schema: z.object({
      plausible: z.record(z.string())
    }),
    idField: "id",
    name: "config",
    document: "all"
  },
  nonprofitPrivate: {
    schema: z.object({
      owed: z.number(),
      totalRaised: z.number(),
      id: z.string()
    }),
    name: "nonprofitPrivate",
    idField: "id"
  },
  notifications: {
    schema: NotificationEvent,
    name: "notifications",
    idField: "notificationId"
  },
  storeSlugs: {
    schema: StoreSlugDoc,
    name: "storeSlugs",
    idField: "slug"
  },
  emails: {
    // TODO: enforce schema
    schema: z.any(),
    idField: "email",
    name: "emails"
  },
  deletions: {
    name: "deletions",
    schema: DeletionDocument,
    idField: "id"
  }
};
