import { Loyalty, OpenInNew } from "@mui/icons-material";
import { Box, Button, GridProps, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import AssetsResolver from "../../AssetsResolver";
import Config from "../../config";
import { useNonprofitArtwork, useNonprofitSlug } from "../../helpers/queries";
import Schmear from "../about/Schmear";
import SupportEmail from "../helpers/SupportEmail";
import FlexibleProductList from "../productViews/FlexibleProductList";

interface DefaultNonprofitPageProps {
  slug: string;
}

export default function DefaultNonprofitPage(props: DefaultNonprofitPageProps) {
  const [nonprofit, nonprofitLoading] = useNonprofitSlug(props.slug);

  const nonprofitArt = useNonprofitArtwork(props.slug, {
    limit: 4
  });

  const productListColumns: GridProps = {
    xs: 6,
    sm: 4,
    md: 3,
    lg: 2
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%"
      }}
    >
      <Box
        sx={{
          width: Config.ux.navBarWidth,
          p: 3,
          pb: 0,
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        {!nonprofit && !nonprofitLoading && (
          <Typography>
            Error loading nonprofit. Please contact <SupportEmail />
          </Typography>
        )}

        {nonprofit && (
          <>
            <Box
              sx={{
                display: "flex",
                gap: 3,
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "space-around",
                p: 3
              }}
            >
              <Box sx={{ flexBasis: "500px" }}>
                <Typography variant="h4" sx={{ textAlign: "center" }}>
                  {nonprofit.name}
                </Typography>

                <Typography
                  sx={{ mt: 3, maxWidth: "600px", whiteSpace: "pre-wrap" }}
                >
                  {nonprofit.description}
                </Typography>

                {nonprofit.earmarked && (
                  <Typography
                    sx={{ mt: 2, maxWidth: "600px", whiteSpace: "pre-wrap" }}
                  >
                    <em>{nonprofit.earmarked}</em>
                  </Typography>
                )}
              </Box>
              <Box
                component="img"
                sx={{
                  width: "400px",
                  maxWidth: "100%"
                }}
                src={
                  typeof nonprofit.image === "string"
                    ? nonprofit.image
                    : AssetsResolver.imageUrl(nonprofit.image.path, "nonprofit")
                }
              />
            </Box>

            {nonprofit.artThatSupported &&
              nonprofit.artThatSupported.length >= 1 &&
              nonprofitArt && (
                <>
                  <Typography variant="h4" sx={{ mt: 3 }}>
                    Latest Art That Supported {nonprofit.shortName}
                  </Typography>

                  <FlexibleProductList
                    products={nonprofitArt}
                    customCardAction={undefined}
                    {...productListColumns}
                    containerProps={{ justifyContent: "center", mb: 3 }}
                  />
                </>
              )}

            <Box
              sx={{
                display: "flex",
                gap: 3,
                justifyContent: "space-evenly",
                width: "100%",
                mt: 1
              }}
            >
              <Link to="/artwork">
                <Button
                  variant="contained"
                  sx={{ height: "100%" }}
                  endIcon={
                    <Loyalty sx={{ display: { xs: "none", sm: "inherit" } }} />
                  }
                >
                  Buy artwork to support
                </Button>
              </Link>

              <Link to={nonprofit.website} target="_blank" rel="noopener">
                <Button
                  sx={{ height: "100%" }}
                  variant="contained"
                  endIcon={
                    <OpenInNew
                      sx={{ display: { xs: "none", sm: "inherit" } }}
                    />
                  }
                >
                  Visit {nonprofit.shortName || nonprofit.name}'s Website
                </Button>
              </Link>
            </Box>
          </>
        )}
      </Box>

      {nonprofit && (
        <Schmear
          text={`Share ${nonprofit.name}'s page`}
          action={{ type: "social" }}
        />
      )}
    </Box>
  );
}
