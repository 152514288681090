import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PagesVisited = Record<string, boolean>;

const initialState: PagesVisited = {};

const pagesVisitedSlice = createSlice({
  name: "pagesVisited",
  initialState: initialState,
  reducers: {
    setVisited: (state: PagesVisited, action: PayloadAction<string>) => {
      state[action.payload] = true;
    }
  }
});

export default pagesVisitedSlice;
