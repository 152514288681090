import { Box, Button, Typography } from "@mui/material";
import { MRT_ColumnDef, MaterialReactTable } from "material-react-table";
import { useMemo, useState } from "react";

import { DeletionDocument } from "shared/models/deletion-models";
import { useDeletedUsers } from "../../helpers/queries";
import { callCloudFunction } from "../../functions/callCloudFunction";
import { dialog } from "../../zustand/imperative-dialog";
import LoadingButton from "../helpers/LoadingButton";

export function DeletionsTable({ readOnly }: { readOnly: boolean }) {
  const [deletionRecords, recordsLoading] = useDeletedUsers();

  const userTableColumns: MRT_ColumnDef<DeletionDocument>[] = [
    {
      accessorKey: "id",
      header: "id"
    },
    { accessorKey: "type", header: "type" },
    { accessorKey: "status", header: "status" },
    { accessorKey: "reason", header: "reason" }
  ];

  // index => true for all selected rows. Index is stringified though
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});

  const selected = useMemo(() => {
    const selectedDocs = Object.entries(rowSelection)
      .filter(([_id, selected]) => selected)
      .map(([id, _selected]) => id)
      .map((id) => deletionRecords?.find((r) => r.id === id))
      .filter((r) => r !== undefined) as DeletionDocument[];

    return selectedDocs;
  }, [rowSelection]);

  const singleSelected = useMemo(() => {
    const [head, ..._tail] = selected;
    return head;
  }, [selected]);

  const [finishingDeletion, setFinishingDeletion] = useState(false);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography component="span" variant="h4" textAlign="center">
        Deleted Users
      </Typography>

      <MaterialReactTable
        initialState={{ pagination: { pageSize: 3, pageIndex: 0 } }}
        state={{ isLoading: recordsLoading, rowSelection }}
        muiPaginationProps={{
          rowsPerPageOptions: [3, 5, 10, 20]
        }}
        enableSelectAll={false}
        columns={userTableColumns}
        enableColumnResizing
        data={deletionRecords || []}
        //enableColumnFilterModes
        //enableColumnOrdering
        //enableGrouping
        enablePinning
        //enableRowActions
        enableRowSelection
        //initialState={{ showColumnFilters: true }}
        positionToolbarAlertBanner="bottom"
        renderTopToolbarCustomActions={() => (
          <Box display="flex" sx={{ gap: 3 }}>
            <LoadingButton
              variant="contained"
              size="small"
              loading={finishingDeletion}
              disabled={!singleSelected || singleSelected.status === "complete"}
              onClick={async () => {
                try {
                  setFinishingDeletion(true);

                  const id = singleSelected?.id;

                  if (!id) {
                    throw new Error("can't get row id");
                  }

                  const result = await callCloudFunction("deleteArtist", {
                    userIdToDelete: singleSelected?.id
                  });

                  dialog.alert({
                    title: "Deletion Result",
                    content: JSON.stringify(result)
                  });
                } catch (e) {
                  dialog.error("failed to complete deletion", e);
                } finally {
                  setFinishingDeletion(false);
                }
              }}
            >
              finish deletion
            </LoadingButton>
          </Box>
        )}
        muiTablePaperProps={{
          elevation: 0
        }}
        onRowSelectionChange={setRowSelection}
        getRowId={(record) => record.id}
        // muiSelectCheckboxProps={({ row }) => ({
        //   disabled: row.original.email === undefined
        // })}
      />
    </Box>
  );
}
