import { Button, Link, Snackbar } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import snackbarSlice from "../redux/slices/snackbarSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";

export default function SnackbarHandler() {
  const dispatch = useAppDispatch();
  const snackbarItems = useAppSelector((state) => state.snackbar.items);

  const maybeItem = snackbarItems.length > 0 ? snackbarItems[0] : undefined;

  const closeSnack = () => dispatch(snackbarSlice.actions.removeCurrent());

  const closeSnackbar = () => {
    closeSnack();
  };

  return (
    <>
      {maybeItem && maybeItem.type === "added_to_cart" && (
        <Snackbar
          key={maybeItem.key}
          open
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={closeSnack}
          message="Added item to cart"
          action={
            <>
              <Link component={RouterLink} to="/checkout">
                <Button
                  variant="contained"
                  size="small"
                  onClick={closeSnackbar}
                >
                  checkout
                </Button>
              </Link>
              <Button
                sx={{
                  ml: 2,
                  backgroundColor: "grey.700",
                  "&:hover, &:focus": {
                    backgroundColor: "grey.500"
                  }
                }}
                variant="contained"
                size="small"
                onClick={closeSnack}
              >
                Keep Looking
              </Button>

              {/* <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.5 }}
                            onClick={closeSnack}
                        >
                            <Close />
                        </IconButton> */}
            </>
          }
        />
      )}
    </>
  );
}
