import { getAuth } from "firebase/auth";
import {
  DocumentData,
  FirestoreError,
  Query,
  and,
  collection,
  doc,
  documentId,
  getDoc,
  getFirestore,
  limit,
  or,
  orderBy,
  query,
  where,
  QuerySnapshot,
  DocumentReference,
  DocumentSnapshot,
  startAfter
} from "firebase/firestore";

import { useEffect, useMemo, useState } from "react";
import {
  useCollectionData as useCollectionDataRaw,
  useDocumentData as useDocumentDataRaw
} from "react-firebase-hooks/firestore";
import { ArtistDocument } from "shared/models/artist-models";
import { AllCollections } from "shared/models/collections";
import { NonProfitDocument } from "shared/models/nonprofit-models";
import { PrintfulApiProduct } from "shared/models/printful-models";
import { ProductDocument } from "shared/models/product-models";
import {
  artistsCollection,
  artistsConverter,
  deletionsCollection,
  nonProfitsCollection,
  nonProfitsConverter,
  productsCollection,
  productsConverter
} from "../databaseModels/DatabaseModels";
import { converters } from "./firebase-helpers";
import { useAppSelector } from "../redux/store";
import slugify from "shared/tools/slugify";

export function useCollectionDataCustom<T = DocumentData>(
  query?: Query<T> | null | undefined
): [
  T[] | undefined,
  boolean,
  FirestoreError | undefined,
  QuerySnapshot<T> | undefined
] {
  const [data, loading, error, snapshot] = useCollectionDataRaw(query);

  // TODO: add errors on loading for too long

  useEffect(() => {
    // TODO: consider showing user?
    if (error) {
      console.error("failed to load collection data", error, { query: query });
    }
  }, [error]);

  return [data, loading, error, snapshot];
}

export function useDocumentDataCustom<T = DocumentData>(
  docRef?: DocumentReference<T> | null | undefined
): [
  T | undefined,
  boolean,
  FirestoreError | undefined,
  DocumentSnapshot<T> | undefined
] {
  const [data, loading, error, snapshot] = useDocumentDataRaw(docRef);

  useEffect(() => {
    if (error) {
      console.error("failed to load document data", error, { docRef: docRef });
    }
  }, [error]);

  return [data, loading, error, snapshot];
}

export function useDailyProducts(limitTo: number) {
  const q = query(
    productsCollection,
    where("live", "==", true),
    orderBy("random"),
    limit(limitTo)
  );

  return useCollectionDataCustom(q);
}

export function useTaggedProducts(
  tag: string | string[] | null,
  limitedTo?: number
) {
  const userRoles = useAppSelector((state) => state.auth.user?.roles);

  const operator = Array.isArray(tag) ? "array-contains-any" : "array-contains";

  const q =
    userRoles?.includes("admin") || userRoles?.includes("reporter")
      ? query(
          productsCollection,
          where("tags", operator, tag),
          orderBy("random", "desc"),
          limit(limitedTo || 100)
        )
      : query(
          productsCollection,
          where("live", "==", true),
          where("tags", operator, tag),
          orderBy("random", "desc"),
          limit(limitedTo || 100)
        );

  return useCollectionDataCustom(tag === null ? null : q);
}

export function useRecentProducts(limitTo: number) {
  const userRoles = useAppSelector((state) => state.auth.user?.roles);

  const q =
    userRoles?.includes("admin") || userRoles?.includes("reporter")
      ? query(productsCollection, orderBy("recencyRank"), limit(limitTo))
      : query(
          productsCollection,
          where("live", "==", true),
          orderBy("recencyRank"),
          limit(limitTo)
        );

  return useCollectionDataCustom(q);
}

export async function getInviteDocument(inviteCode: string) {
  const result = await getDoc(
    doc(getFirestore(), AllCollections.invites.name, inviteCode).withConverter(
      converters.invitesConverter
    )
  );

  if (result.exists()) {
    return result.data();
  } else {
    return null;
  }
}

export function usePrintfulWallArt() {
  return useDocumentDataCustom(
    doc(
      getFirestore(),
      AllCollections.printfulCategories.name,
      "wallArt"
    ).withConverter(converters.printfulCategoriesConverter)
  );
}

export function useProduct(
  slug: string | null
): [
  ProductDocument | undefined,
  boolean | undefined,
  FirestoreError | undefined
] {
  if (slug === null) {
    return [undefined, undefined, undefined];
  }

  const uid = useAppSelector((state) => state.auth.user?.uid);

  const userRoles = useAppSelector((state) => state.auth.user?.roles);

  const viewRegardlessOfLive =
    userRoles?.includes("admin") || userRoles?.includes("reporter");

  if (viewRegardlessOfLive) {
    const [slugProduct, slugLoading, slugError] = useCollectionDataCustom(
      query(
        collection(getFirestore(), "products").withConverter(productsConverter),
        where("slugs", "array-contains", slug),
        limit(1)
      )
    );

    return [slugProduct?.[0], slugLoading, slugError];
  } else if (uid) {
    /**
     * this branch is needed, as we can't ever query with anything besides live
     * if we have new user, due to firestore rule semantics
     */
    const [slugProduct, slugLoading, slugError] = useCollectionDataCustom(
      query(
        collection(getFirestore(), "products").withConverter(productsConverter),
        and(
          or(where("live", "==", true), where("artist", "==", uid)),
          where("slugs", "array-contains", slug)
        ),
        limit(1)
      )
    );

    return [slugProduct?.[0], slugLoading, slugError];
  } else {
    const [slugProduct, slugLoading, slugError] = useCollectionDataCustom(
      query(
        collection(getFirestore(), "products").withConverter(productsConverter),
        where("live", "==", true),
        where("slugs", "array-contains", slug),
        limit(1)
      )
    );

    return [slugProduct?.[0], slugLoading, slugError];
  }
}

export function usePrintfulProductDetails(
  printfulProduct?: PrintfulApiProduct
) {
  return useDocumentDataCustom(
    printfulProduct
      ? doc(
          getFirestore(),
          AllCollections.printfulProducts.name,
          printfulProduct.id.toString()
        ).withConverter(converters.printfulProductsConverter)
      : null
  );
}

export function useDeletedUsers() {
  return useCollectionDataCustom(
    query(deletionsCollection, where("type", "==", "user"))
  );
}

export function useArtists() {
  const userRoles = useAppSelector((state) => state.auth.user?.roles);

  const viewRegardlessOfLive =
    userRoles?.includes("admin") || userRoles?.includes("reporter");

  // TODO: show non-setup artists to themselves during setup
  return useCollectionDataCustom(
    viewRegardlessOfLive
      ? query(artistsCollection)
      : query(artistsCollection, where("live", "==", true))
  );
}

export function useSingleArtist(
  storeName?: string
): [ArtistDocument | undefined, boolean, FirestoreError | undefined] {
  const uid = useAppSelector((state) => state.auth.user?.uid);

  const userRoles = useAppSelector((state) => state.auth.user?.roles);

  const viewRegardlessOfLive =
    userRoles?.includes("admin") || userRoles?.includes("reporter");

  const slug = storeName ? slugify(storeName) : undefined;

  if (viewRegardlessOfLive) {
    const [doc, loading, error] = useCollectionDataCustom(
      slug === undefined
        ? null
        : query(
            artistsCollection,
            // needs an index
            and(where("storeSlugs", "array-contains", slug)),
            limit(1)
          )
    );

    return [doc?.[0], loading, error];
  } else if (uid) {
    const [doc, loading, error] = useCollectionDataCustom(
      slug === undefined
        ? null
        : query(
            artistsCollection,
            and(
              where("storeSlugs", "array-contains", slug),
              or(
                where("live", "==", true),
                and(where(documentId(), "==", uid), where("live", "==", false))
              )
            ),
            limit(1)
          )
    );

    return [doc?.[0], loading, error];
  } else {
    const [doc, loading, error] = useCollectionDataCustom(
      storeName === undefined
        ? null
        : query(
            artistsCollection,
            // needs an index
            and(
              where("storeSlugs", "array-contains", slug),
              where("live", "==", true)
            ),
            limit(1)
          )
    );

    return [doc?.[0], loading, error];
  }
}

export function useArtistCollection(
  //may want to rename function
  artist: ArtistDocument | null,
  productArtist?: string
): [ProductDocument[] | undefined, boolean, FirestoreError | undefined] {
  const [artistCollection, artistCollectionLoading, artistCollectionError] =
    useCollectionDataCustom(
      artist && productArtist
        ? query(
            productsCollection,
            where("live", "==", true),
            where("artist", "==", productArtist),
            limit(4)
          )
        : null
    );
  return [artistCollection, artistCollectionLoading, artistCollectionError];
}

// TODO: stub. look for a tag or something to see recent support for specific nonprofit
export function useNonprofitArtwork(
  nonprofitOrSlug: NonProfitDocument | string,
  options?: {
    limit?: number;
  }
): ProductDocument[] | undefined {
  const [nonprofitBySlug] = useNonprofitSlug(
    typeof nonprofitOrSlug === "string" ? nonprofitOrSlug : null
  );

  const nonprofit =
    typeof nonprofitOrSlug !== "string" ? nonprofitOrSlug : nonprofitBySlug;

  const limitedTo = options?.limit || 3;

  const [unorderedProducts, setUnorderedProducts] = useState<ProductDocument[]>(
    []
  );

  const productIds = useMemo(
    () => (nonprofit?.artThatSupported || []).slice(0, limitedTo),
    [nonprofit]
  );

  useEffect(() => {
    if (nonprofit) {
      for (const id of productIds) {
        const promisedDoc = getDoc(
          doc(getFirestore(), AllCollections.products.name, id).withConverter(
            productsConverter
          )
        );

        promisedDoc
          .catch((e) =>
            console.log(
              `Failed to get productId=${id} for nonprofit page. Code=${
                e.code || "unknown"
              }`
            )
          )
          .then((doc) => {
            if (doc) {
              const data = doc.data();
              if (data) {
                setUnorderedProducts((state) => [...state, data]);
              }
            }
          });
      }
    }
  }, [nonprofit]);

  const orderedProducts = productIds
    .map((id) => {
      const fullProduct = (unorderedProducts || []).find(
        (product) => product.productId === id
      );

      return fullProduct || null;
    })
    .filter((productOrNull) => productOrNull !== null) as ProductDocument[];

  return orderedProducts;
}

export function useArtist(product: ProductDocument | null) {
  return useDocumentDataCustom(
    product
      ? doc(getFirestore(), "artists", product.artist).withConverter(
          artistsConverter
        )
      : null
  );
}

export function useNonprofits() {
  return useCollectionDataCustom(
    query(nonProfitsCollection, orderBy("priority"))
  );
}

export function useNonprofitSlug(
  slug: string | null
): [NonProfitDocument | undefined, boolean, FirestoreError | undefined] {
  const [slugProduct, slugLoading, slugError] = useCollectionDataCustom(
    slug
      ? query(
          collection(
            getFirestore(),
            AllCollections.nonprofits.name
          ).withConverter(nonProfitsConverter),
          where("slug", "==", slug),
          limit(1)
        )
      : null
  );

  return [slugProduct?.[0], slugLoading, slugError];
}
