import { Button } from "@mui/material";
import { useState } from "react";
import { createStripeConnectLoginLink } from "../../functions/cloudFunctions";

export default function ViewStripeDashboardButton() {
  const [generatingLink, setGeneratingLink] = useState(false);

  return (
    <Button
      onClick={async () => {
        setGeneratingLink(true);
        const link = await createStripeConnectLoginLink();
        setGeneratingLink(false);
        {
          /* TODO: consider changing to opening in the same window */
        }
        window.open(link, "_blank");
      }}
      disabled={generatingLink}
      sx={{
        flexBasis: {
          xs: "15em",
          sm: "auto"
        }
      }}
    >
      {generatingLink
        ? "generating dashboard link..."
        : "Go to my Stripe (payments) dashboard"}{" "}
    </Button>
  );
}
