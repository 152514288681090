import { TextField, TextFieldProps } from "@mui/material";
import { useField, useFormikContext } from "formik";

type FormikFieldProps = TextFieldProps & {
  name: string;
  label?: string;
  shrink?: boolean;
  allowUndefined?: boolean;
  ignoreBlurIf?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => boolean;
};

export default function FormikField({
  name,
  label,
  shrink,
  allowUndefined,
  ...props
}: FormikFieldProps) {
  const [field, meta] = useField(name);
  const formikContext = useFormikContext<Record<string, string>>();
  // const [value, setValue] = useState<any>(
  //   allowUndefined && field.value === undefined ? "" : field.value
  // );
  // useEffect(() => {
  //   if (field.value === undefined && allowUndefined) {
  //     // text field is detached from formik state
  //   } else {
  //     setValue(field.value);
  //   }
  // }, [field]);
  return (
    <TextField
      fullWidth
      {...props}
      label={label}
      name={name}
      value={field.value === undefined ? "" : field.value}
      onChange={(e) => {
        // if (e.target.value.trim() === "" && allowUndefined) {
        //   formikContext.setFieldValue(name, undefined);
        //   setValue(e.target.value);
        // } else {
        field.onChange(e);
        //}
      }}
      onBlur={(e) => {
        if (props.ignoreBlurIf && props.ignoreBlurIf(e)) {
          return;
        } else {
          field.value?.trim() === "" && allowUndefined
            ? (async () => {
                await formikContext.setFieldValue(name, "");
                await formikContext.setFieldValue(name, undefined);
                field.onBlur(e);
              })()
            : field.onBlur(e);
        }
      }}
      error={meta.touched && meta.error !== undefined}
      helperText={meta.touched && meta.error !== undefined && meta.error}
      // sx={{
      //     "& .MuiOutlinedInput-root": {
      //         color: "red"
      //     }
      // }}
      variant="outlined"
      InputLabelProps={{ shrink: shrink === undefined ? true : shrink }}
      // inputProps={{
      //     sx: {
      //         "&::placeholder": {
      //         color: "green"
      //         }
      //     }
      // }}
    />
  );
}
