import { Box, Grid, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import AssetsResolver from "../AssetsResolver";
import Config from "../config";
import { useArtists } from "../helpers/queries";
import OurPartners from "./about/OurPartners";
import Schmear from "./about/Schmear";
import { VisibilityOff } from "@mui/icons-material";
import { useMemo } from "react";
import { shuffle } from "lodash";

const elementWidth = Config.ux.navBarWidth;

export default function ArtistsPage() {
  // TODO: leverage loading and error states here
  const [unsortedArtists = [], loading, _error] = useArtists();

  const randomizedArtists = useMemo(
    () => shuffle(unsortedArtists),
    [unsortedArtists]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "100%"
      }}
    >
      {randomizedArtists && !loading && (
        <>
          <Box
            sx={{
              width: elementWidth,
              maxWidth: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 3
            }}
          >
            <Typography paragraph style={{ textIndent: 30 }}>
              Our mission is simple: elevate artists and raise awareness and
              funds for mental health.{" "}
              <em>
                Artists earn 50% net profit from each sale, 30% goes to the
                nonprofit you choose, and 20% supports TGG.
              </em>{" "}
              Some artists may choose to waive their commission to additionally
              support the nonprofit or TGG.
            </Typography>

            <Typography mt={2} variant="h4" fontWeight="bold">
              Our Artists
            </Typography>

            {/* <Collapse in={unsortedArtists.length > 0}> */}
            <Grid
              container
              spacing={2}
              sx={{
                mt: 5,
                maxWidth: "100%"
              }}
            >
              {randomizedArtists.map((artist) => (
                <Grid
                  item
                  key={artist.artistId}
                  xs={6}
                  sm={4}
                  md={3}
                  lg={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <Link
                    component={RouterLink}
                    to={`/artists/${artist.storeSlug}`}
                    sx={{ textDecoration: "none" }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%"
                      }}
                    >
                      {artist && (
                        <>
                          <Box
                            position="relative"
                            data-testid="img hover wrapper"
                            sx={{
                              //backgroundColor: "#00000090",
                              backgroundColor: "rgba(0,0,0,0.5)",
                              width: "100%",
                              aspectRatio: "1/1",
                              margin: 0,
                              padding: 0,
                              "& .frontside, &:hover .backside": {
                                opacity: 1
                              },
                              "& .backside, &:hover .frontside": {
                                opacity: 0
                              }
                            }}
                          >
                            {!artist.live && (
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  background: "rgba(0,0,0,0.7)",
                                  p: 1,
                                  pb: 0,
                                  br: 10
                                }}
                              >
                                <VisibilityOff color="error" />
                              </Box>
                            )}

                            <Box
                              component="img"
                              alt={`profile picture for ${
                                artist.name
                                  ? artist.name.first + " " + artist.name.last
                                  : artist.storeName
                              }`}
                              className="frontside"
                              src={AssetsResolver.imageUrl(
                                artist.profilePicture?.path || "",
                                "profilepicture"
                              )}
                              sx={{
                                width: "100%",
                                //height: "100%",
                                aspectRatio: "1/1",
                                transition: "opacity 1s",
                                display: "block"
                              }}
                            />
                            <Box
                              component="img"
                              alt={`backside for ${
                                artist.name
                                  ? artist.name.first + " " + artist.name.last
                                  : artist.storeName
                              }`}
                              className="backside"
                              src={AssetsResolver.imageUrl(
                                artist.coverImage?.path ||
                                  Config.defaultCoverImagePath,
                                "blurredmediumsquare"
                              )}
                              sx={{
                                width: "100%",
                                //height: "100%",
                                aspectRatio: "1/1",
                                transition: "opacity 1s",
                                display: "block",
                                position: "absolute",
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0
                              }}
                            />
                            <Box
                              className="backside"
                              sx={{
                                width: "100%",
                                maxHeight: "100%",
                                position: "absolute",
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                margin: "auto",
                                transition: "opacity 1s",
                                overflow: "hidden",
                                padding: 2,
                                backgroundColor: "rgba(0,0,0,0.5)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                              }}
                            >
                              <Typography variant="h4" color="white">
                                View Store
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              height: "30%"
                            }}
                          >
                            <Typography variant="h5" color="black">
                              {artist.name
                                ? artist.name.first + " " + artist.name.last
                                : artist.storeName}
                            </Typography>
                            <Typography
                              sx={{
                                color: "black",
                                display: "-webkit-box",
                                WebkitLineClamp: 4,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                wordWrap: "anywhere"
                              }}
                            >
                              {artist.quote.includes('"') ||
                              artist.quote.includes("“")
                                ? artist.quote
                                : `“${artist.quote}”`}
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Link>
                </Grid>
              ))}
            </Grid>
            {/* </Collapse> */}
          </Box>

          <Schmear
            action={{
              text: "Apply Now",
              type: "link",
              to: "/apply"
            }}
            text="We are looking for artists to join our 2024 Spring Cohort!"
          />

          <Box sx={{ width: elementWidth, maxWidth: "100%" }}>
            <OurPartners includePreamble />
          </Box>
        </>
      )}
    </Box>
  );
}
