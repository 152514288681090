import { z } from "zod";
import { ImageDetails } from "./image-models";

export const NameSchema = z.object({
  first: z
    .string()
    .min(1, "must be at least 1 character")
    .max(50, "must be less than 50 characters"),
  last: z
    .string()
    .min(1, "must be at least 1 character")
    .max(50, "must be less than 50 characters")
});

export const CoreArtistData = z.object({
  bio: z.string().min(100, "bio must contain at least 100 characters").max(600),
  quote: z
    .string()
    .min(20, "quote must be at least 20 characters")
    .max(150, "quote must be less than 150 characters"),
  instagram: z
    .union([
      z
        .string()
        .regex(
          new RegExp(/^instagram\.com\/[a-zA-Z0-9._]+\/?$/),
          "must be a valid instagram link or handle"
        ),
      z
        .string()
        .regex(
          new RegExp(/^https:\/\/instagram\.com\/[a-zA-Z0-9._]+\/?$/),
          "must be a valid instagram link or handle"
        ),
      z
        .string()
        .regex(
          new RegExp(/^@?[a-zA-Z0-9._]+\/?$/),
          "must be a valid instagram link or handle"
        )
    ])
    .optional(),
  tiktok: z
    .union([
      z
        .string()
        .regex(
          new RegExp(/^tiktok\.com\/@[a-zA-Z0-9._]+\/?$/),
          "must be a valid tiktok link or handle"
        ),
      z
        .string()
        .regex(
          new RegExp(/^https:\/\/tiktok\.com\/@[a-zA-Z0-9._]+\/?$/),
          "must be a valid tiktok link or handle"
        ),
      z
        .string()
        .regex(
          new RegExp(/^@?[a-zA-Z0-9._]+\/?$/),
          "must be a valid tiktok link or handle"
        )
    ])
    .optional(),
  facebook: z
    .union([
      z
        .string()
        .regex(
          new RegExp(/^tiktok\.com\/[a-zA-Z0-9.]+\/?$/),
          "must be a valid facebook link or handle"
        ),
      z
        .string()
        .regex(
          new RegExp(/^https:\/\/tiktok\.com\/[a-zA-Z0-9.]+\/?$/),
          "must be a valid facebook link or handle"
        ),
      z
        .string()
        .regex(
          new RegExp(/^@?[a-zA-Z0-9.]+\/?$/),
          "must be a valid facebook link or handle"
        )
    ])
    .optional(),
  personalWebsite: z
    .string()
    .regex(
      new RegExp(
        /^(http(s?):\/\/.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/
      ),
      "must be a valid url"
    )
    .optional()
});

export const ArtistDocument = CoreArtistData.extend({
  storeName: z.string(),
  coverImage: ImageDetails.optional(),
  profilePicture: ImageDetails,
  live: z.boolean(),
  liveOn: z.date().optional(),
  name: NameSchema.optional(),
  artistId: z.string(),
  storeSlug: z.string(),
  storeSlugs: z.array(z.string())
});

export type ArtistDocument = z.infer<typeof ArtistDocument>;
