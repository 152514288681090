import { HelpOutline } from "@mui/icons-material";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import { PaymentIntentDetails } from "shared/models/checkout-models";
import { TaxBreakdown } from "shared/models/stripe-models";
import { currency } from "shared/tools/currency-tools";

interface CostBreakdownProps {
  paymentIntent: PaymentIntentDetails;
}

export default function CostBreakdown({ paymentIntent }: CostBreakdownProps) {
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ "& .MuiTableCell-root": { fontWeight: "bold" } }}>
              <TableCell>Base</TableCell>
              <TableCell>Printing + Shipping</TableCell>
              <TableCell>Tax</TableCell>
              <TableCell
                align="right"
                sx={{ borderLeft: "1px solid", borderColor: "grey.400" }}
              >
                Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {currency(paymentIntent.costs.base, {
                  fromCents: true
                }).format()}
              </TableCell>
              <TableCell>
                <Tooltip
                  title={
                    <>
                      <Typography>
                        Print cost:{" "}
                        {currency(paymentIntent.costs.printing.subtotal, {
                          fromCents: true
                        }).format()}
                      </Typography>
                      <Typography>
                        Shipping:{" "}
                        {currency(paymentIntent.costs.printing.shipping, {
                          fromCents: true
                        }).format()}
                      </Typography>

                      {paymentIntent.costs.printing.additional_fee +
                        paymentIntent.costs.printing.digitization +
                        paymentIntent.costs.printing.fulfillment_fee >
                        0 && (
                        <Typography>
                          Fees:{" "}
                          {currency(
                            paymentIntent.costs.printing.additional_fee +
                              paymentIntent.costs.printing.digitization +
                              paymentIntent.costs.printing.fulfillment_fee,
                            { fromCents: true }
                          ).format()}
                        </Typography>
                      )}

                      <Typography>
                        Printful taxes:{" "}
                        {currency(
                          paymentIntent.costs.printing.tax +
                            paymentIntent.costs.printing.vat,
                          { fromCents: true }
                        ).format()}
                      </Typography>
                    </>
                  }
                >
                  <Box sx={{ display: "flex", cursor: "help" }}>
                    <Typography>
                      {currency(paymentIntent.costs.printing.total, {
                        fromCents: true
                      }).format()}
                    </Typography>

                    <HelpOutline fontSize="small" sx={{ ml: 0.5 }} />
                  </Box>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={
                    <>
                      {paymentIntent.taxBreakdown.map((b, index) => (
                        <TaxRateInfo taxBreakdown={b} key={index} />
                      ))}
                    </>
                  }
                >
                  <Typography>
                    {currency(paymentIntent?.costs.tax, {
                      fromCents: true
                    }).format()}
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell
                align="right"
                sx={{ borderLeft: "1px solid", borderColor: "grey.400" }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {currency(paymentIntent.costs.total, {
                    fromCents: true
                  }).format()}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function TaxRateInfo({ taxBreakdown }: { taxBreakdown: TaxBreakdown }) {
  return <Typography paragraph>{JSON.stringify(taxBreakdown)}</Typography>;
}
