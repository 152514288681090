import { Box, Button, ButtonProps, LinearProgress } from "@mui/material";

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
  loadingMessage?: string;
}

export default function LoadingButton({
  loading,
  loadingMessage,
  children,
  disabled,
  ...props
}: LoadingButtonProps) {
  return (
    <Box
      sx={{ position: "relative", width: props.fullWidth ? "100%" : "unset" }}
    >
      <Button disabled={disabled || loading} {...props}>
        {(loading && loadingMessage) || children}
      </Button>
      {loading && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            borderTopRightRadius: 4,
            borderTopLeftRadius: 4
          }}
        />
      )}
    </Box>
  );
}
