import { Box, Paper } from "@mui/material";
import AssetsResolver from "../../AssetsResolver";

import { PrintfulApiVariant } from "shared/models/printful-models";
import { ProductDocument } from "shared/models/product-models";
import { getVariantSize, printOrientation } from "shared/printing";
import "./ProductCard.css";
import { FitStyle } from "shared/models/order-models";

function fixAspectRatioFormat(
  product: ProductDocument,
  variant: PrintfulApiVariant
) {
  const { width, height } = getVariantSize(variant);

  if (
    printOrientation({
      width: product.image.full.width,
      height: product.image.full.height
    }) === "horizontal"
  ) {
    return {
      horizontal: height,
      vertical: width
    };
  } else {
    return {
      horizontal: width,
      vertical: height
    };
  }
}

interface MarginProductProps {
  product: ProductDocument;
  variant?: PrintfulApiVariant;
  width: number | "100%";
  outerAspectRatio: { horizontal: number; vertical: number };
  fit: FitStyle;
}
export default function MarginProduct(props: MarginProductProps) {
  if (!props.variant) {
    // show skeleton
    return (
      <Box
        sx={{
          // backgroundColor: "aliceblue",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width:
            typeof props.width === "string" ? props.width : `${props.width}px`,
          aspectRatio: `${props.outerAspectRatio.horizontal}/${props.outerAspectRatio.vertical}`
        }}
      ></Box>
    );
  }

  const fixedAspectRatio = fixAspectRatioFormat(props.product, props.variant);
  const { width, height } = getVariantSize(props.variant);
  return (
    <Box
      sx={{
        // backgroundColor: "aliceblue",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width:
          typeof props.width === "string" ? props.width : `${props.width}px`,
        aspectRatio: `${props.outerAspectRatio.horizontal}/${props.outerAspectRatio.vertical}`
      }}
    >
      <Paper
        elevation={5}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          aspectRatio:
            printOrientation({
              width: props.product.image.full.width,
              height: props.product.image.full.height
            }) === "horizontal"
              ? `${height}/${width}`
              : `${width}/${height}`,
          width: `min(100%,${(
            (100 /
              (props.outerAspectRatio.horizontal /
                props.outerAspectRatio.vertical)) *
            (fixedAspectRatio.horizontal / fixedAspectRatio.vertical)
          ).toString()}%)`,
          //if aspect ratio of inner is less wide than outer,(checked with min)
          //height equals outer width (100%) over outer aspect ratio
          //that height times inner aspect ratio equals width
          height: "auto",
          maxHeight: "100%"
        }}
      >
        <Box
          component="img"
          src={AssetsResolver.imageUrl(props.product.image.path, {})}
          alt={props.product.altText || ""}
          sx={{
            height: "100%",
            width: "100%",
            objectFit:
              props.fit.fitStyle === "center_crop" ? "cover" : "contain"
          }}
        />
      </Paper>
    </Box>
  );
}
