// it's important to do this early
import "./firebase-initializer";

import { useEffect } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import "./App.css";
import Navigation from "./components/Navigation";

import {
  Box,
  createTheme,
  CssBaseline,
  responsiveFontSizes,
  ThemeProvider
} from "@mui/material";
import { useLocation } from "react-router-dom";
import AdminPage from "./components/admin/AdminPage";
import Footer from "./components/Footer";
import LandingPage from "./components/LandingPage";
import GuidelinePage from "./components/legal/GuidelinePage";
import MultiModal from "./components/MutliModal";
import NotFoundPage from "./components/NotFoundPage";
import SingleArtistPage from "./components/SingleArtistPage";
import SnackbarHandler from "./components/SnackbarHandler";
import MainGallery from "./components/store/MainGallery";
import SingleProductPage from "./components/store/SingleProductPage";
import SuccessfulCheckoutPage from "./components/SuccessfulCheckoutPage";
import campaignSlice from "./redux/slices/campaignSlice";
import { useAppDispatch, useAppSelector } from "./redux/store";

// for nav bars
import "@fontsource/eb-garamond"; // Defaults to weight 400.
import KeepAlive, { AliveScope } from "react-activation";
import About from "./about/About";
import Apply from "./Apply";
import ArtistsPage from "./components/ArtistsPage";
import Cart from "./components/checkout/Cart";
import CartManager from "./components/checkout/CartManager";
import NewCheckout from "./components/checkout/Checkout";
import FAQ from "./components/FAQ";
import ImperativeDialog from "./components/helpers/ImperativeDialog";
import JoinPage from "./components/JoinPage";
import TermsOfServicePage from "./components/legal/TermsOfServicePage";
import StoreRouter from "./components/my-store/MyStoreRouter";
import NonprofitRouter from "./components/nonprofit-pages/NonprofitRouter";
import NonProfitsPage from "./components/NonProfitsPage";
import CategoryPage from "./components/store/CategoryPage";
import Config from "./config";
import trackEvent from "./helpers/plausible";
import PrivacyPolicyPage from "./components/legal/PrivacyPolicyPage";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function ParamsParser() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const params = [...searchParams.entries()];

    const utmParams = params.filter(([key, value]) =>
      key.toLowerCase().startsWith("utm_")
    );

    const newParams = params.filter((p) => !utmParams.includes(p));

    // FIXME: race condition, these get stripped before plausible sends it becon
    // until it's fixed, disabling this
    if (params.length !== newParams.length) {
      //console.log(`Detected and stripped out utm params: ${utmParams}`);
      //setSearchParams(newParams);
    }

    const redirected_from = searchParams.get("redirected_from");

    if (redirected_from) {
      // consume the param and fire an event to plausible

      trackEvent({
        event: "Redirected",
        properties: {
          from: redirected_from
        }
      });

      // TODO: don't mutate the state like this
      searchParams.delete("redirected_from");

      // replacing is important to prevent a permanent loop when trying to go back
      setSearchParams(searchParams, { replace: true });
    }

    // TODO: use a similar pattern to above here, or remove this entirely in favor of UTM
    if (searchParams.has("supports")) {
      dispatch(
        campaignSlice.actions.setActive({
          supports: searchParams.get("supports")!,
          capturedAtEpochMs: Date.now()
        })
      );

      // replacing is important to prevent a permanent loop when trying to go back
      setSearchParams({}, { replace: true });
    }
  }, [dispatch, searchParams, setSearchParams]);

  return null;
}

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor } });

// dark red, color of logo
export const tggPrimary = createColor("rgb(112, 33, 44)");
export const tggSecondary = {
  ...createColor("#ece0d4"),
  contrastText: "#374744"
};
//export const tggTertiary = createColor("#374744");

// https://stackoverflow.com/questions/46486565/mui-customize-button-color
const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: tggPrimary,
      secondary: tggSecondary
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
            em {
              color: ${tggPrimary.main};
              font-weight: bold;
              font-style: unset;
            }

            h1, h2, h3, h4, h5, h6 {
              color: ${tggPrimary.main};
            }
          `
      }
    }
  })
);

function LoadPlausibleScript() {
  useEffect(() => {
    const baseUrl = Config.plausible.baseUrl;
    // we need to use a specific script that allows localhost if we're running on localhost
    const jsPath = Config.plausible.usingLocalhost ? "m.l.js" : "m.js";
    const eventPath = "e";

    const script = document.createElement("script");

    // which plausible "site" the stats are funneled to
    script.dataset.domain = Config.plausible.dataDomain;

    script.dataset.api = `${baseUrl}/${eventPath}`;
    script.src = `${baseUrl}/${jsPath}`;

    script.onload = () => {
      console.debug(
        `loaded plausible script (data domain = ${script.dataset.domain}).`
      );
    };

    document.body.appendChild(script);
  }, []);

  return null;
}

function App() {
  const user = useAppSelector((state) => state.auth.user);
  const userStatus = useAppSelector((state) => state.auth.userStatus);

  const navigate = useNavigate();
  const location = useLocation();

  // redirect to /join if incomplete user
  // TODO: redirect new logins to appropriate location
  useEffect(() => {
    if (user?.roles.length === 0 && window.location.pathname !== "/join") {
      // User doesn't have roles yet
      // They're either waiting for correct roles,
      // or don't have a user document yet
      // either way, redirect them to the /join flow
      console.log("Incomplete account, redirecting to /join");
      navigate("/join");
    }
  }, [location, user]);

  return (
    <ThemeProvider theme={theme}>
      <AliveScope>
        <LoadPlausibleScript />
        <CssBaseline />
        <ScrollToTop />
        <Navigation />
        <CartManager />
        <ParamsParser />
        <Box
          minHeight="calc(100vh - 64px)"
          maxWidth="100%"
          display="flex"
          flexDirection="column"
          position="relative"
        >
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/join" element={<JoinPage />} />
            <Route path="/nonprofits" element={<NonProfitsPage />} />
            <Route
              path="/artwork"
              element={
                <KeepAlive cacheKey="MainGallery">
                  <MainGallery />
                </KeepAlive>
              }
            />
            <Route
              path="/artwork/category/:categoryName"
              element={<CategoryPage />}
            />
            <Route path="/artwork/:productId" element={<SingleProductPage />} />
            <Route
              path="/artists"
              element={
                <KeepAlive cacheKey="ArtistsPage">
                  <ArtistsPage />
                </KeepAlive>
              }
            />
            <Route path="/partners/:slug" element={<NonprofitRouter />} />
            <Route path="/apply" element={<Apply />} />
            <Route
              path="/artists/:storeName"
              element={
                <SingleArtistPage
                  privateView={false}
                  artistUser={undefined}
                  artistDocument={undefined}
                />
              }
            />
            {user && user.roles.includes("artist") && (
              <Route path="/mystore/*" element={<StoreRouter user={user} />} />
            )}
            {!Config.ux.checkoutDisabled && (
              <>
                <Route path="/cart" element={<Cart />} />
                {/* <Route path="/checkout" element={<Checkout />} /> */}
                <Route path="/checkout" element={<NewCheckout />} />

                <Route
                  path="/checkout/success"
                  element={<SuccessfulCheckoutPage />}
                />
              </>
            )}
            {user &&
              (user.roles.includes("admin") ||
                user.roles.includes("reporter")) && (
                <Route path="/admin" element={<AdminPage />} />
              )}
            {userStatus !== "loading" && (
              <Route path="*" element={<NotFoundPage />} />
            )}
            <Route path="/legal/guidelines" element={<GuidelinePage />} />
            <Route
              path="/legal/terms-of-service"
              element={<TermsOfServicePage />}
            />
            <Route
              path="/legal/privacy-policy"
              element={<PrivacyPolicyPage />}
            />
            <Route path="/faq" element={<FAQ />} />
          </Routes>
        </Box>
        <Footer />
        <ImperativeDialog />
        <MultiModal />
        <SnackbarHandler />
      </AliveScope>
    </ThemeProvider>
  );
}

export default App;
