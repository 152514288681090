import {
  Alert,
  Box,
  BoxProps,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { NonProfitDocument } from "shared/models/nonprofit-models";
import AssetsResolver from "../../AssetsResolver";
import trackEvent from "../../helpers/plausible";
import { useNonprofits } from "../../helpers/queries";
import { useAppSelector } from "../../redux/store";
import DialogTitleWithClose from "../helpers/DialogTitleWithClose";

interface OurPartnersProps {
  includePreamble?: boolean;
  includeHeader?: boolean;
  minHeight?: string;
}

export default function OurPartners({
  includePreamble = false,
  includeHeader = true,
  minHeight = "150px"
}: OurPartnersProps) {
  const [nonProfits = [], loading, error] = useNonprofits();

  const [selectedNonProfit, setSelectedNonProfit] = useState<
    NonProfitDocument | undefined
  >(undefined);
  const [nonProfitDialogOpen, setNonProfitDialogOpen] = useState(false);

  useEffect(() => {
    if (nonProfitDialogOpen) {
      trackEvent({
        event: "Opened NonProfit Modal",
        properties: {
          nonprofit_id: selectedNonProfit!.id,
          nonprofit_name: selectedNonProfit!.name
        }
      });
    }
  }, [nonProfitDialogOpen]);

  const activeCampaign = useAppSelector((state) => state.campaign.active);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 1
      }}
    >
      {includeHeader && (
        <Typography mt={2} variant="h3">
          Our Nonprofit Partners
        </Typography>
      )}

      {includePreamble && (
        <Typography sx={{ pt: 3, width: "80%", textAlign: "center" }}>
          We partner with artists and organizations who believe in the power of
          creativity to raise awareness about mental health. Artists can sign up
          to build their The Giving Gallery store.
        </Typography>
      )}

      <Box
        sx={{
          mt: 5,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          columnGap: 2.5,
          rowGap: 2.5,
          maxWidth: "100%",
          minHeight
        }}
      >
        {nonProfits.map((nonProfit) => (
          <Link
            className="nonprofit-image"
            component={RouterLink}
            to={`/partners/${nonProfit.slug}`}
            key={nonProfit.id}
            sx={{
              cursor: "pointer",
              "&:hover": {
                transform: "scale(1.05)",
                zIndex: 5,
                boxShadow:
                  "0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06)"
              },
              transition:
                ".6s transform cubic-bezier(.155, 1.105, .295, 1.12), .6s box-shadow, .6s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12)",
              maxWidth: "100%"
            }}
          >
            <Box>
              <img
                alt={nonProfit.name}
                src={
                  typeof nonProfit.image === "string"
                    ? nonProfit.image
                    : AssetsResolver.imageUrl(nonProfit.image.path, "nonprofit")
                }
                style={{
                  height: minHeight,
                  maxWidth: "100%"
                }}
              />
              {activeCampaign && activeCampaign.supports === nonProfit.id && (
                <Alert
                  severity="info"
                  icon={false}
                  style={{
                    maxWidth: "100%",
                    borderRadius: 0
                  }}
                >
                  You came from a {nonProfit.shortName || nonProfit.name} link.
                  At checkout, supporting this will be your default selection,
                  although you're free to select differently!
                </Alert>
              )}
            </Box>
          </Link>
        ))}
      </Box>

      <Dialog
        open={nonProfitDialogOpen}
        onClose={() => setNonProfitDialogOpen(false)}
      >
        <DialogTitleWithClose onClose={() => setNonProfitDialogOpen(false)}>
          {selectedNonProfit?.name || "Unknown"}
        </DialogTitleWithClose>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <img
            alt={selectedNonProfit?.name}
            style={{
              maxHeight: "300px",
              maxWidth: "100%",
              height: "auto",
              alignSelf: "center"
            }}
            src={
              selectedNonProfit
                ? typeof selectedNonProfit.image === "string"
                  ? selectedNonProfit.image
                  : AssetsResolver.imageUrl(
                      selectedNonProfit.image.path,
                      "nonprofit"
                    )
                : undefined
            }
          />
          <Typography mt={3} whiteSpace="pre-wrap">
            {selectedNonProfit?.description}
          </Typography>
          <Box mt={2} />
          <Typography>
            So far, we've raised <span style={{ fontWeight: "bold" }}>X$</span>{" "}
            for {selectedNonProfit?.name}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNonProfitDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
