import { Box, Chip, Typography } from "@mui/material";

// TODO: consolidate these policy pages with a cohesive pattern, ideally some sort of CMS
export default function PrivacyPolicy() {
  const effectiveDate = `July 20th, 2024`; //used for versioning, update when policy is updated
  const header = `Welcome to The Giving Gallery (TGG)! This is a first attempt at a privacy policy which will expand as we grow.`;
  const TOS = [
    {
      title: `1. Overview`,
      content: `At TGG, we believe that privacy matters. We are committed to adhering to all relevant regulations, such as the California Consumer Privacy Act (CCPA) and the General Data Protection Regulation (GDPR), as well as contributing to the conversation around privacy and online rights.`
    },
    {
      title: "2. Data Security",
      content:
        "We take reasonable steps to ensure your high-resolution prints are not publicly available. We use Google Cloud Platform (GCP) for account and data management."
    },
    {
      title: "3. User Rights",
      content:
        "You have the right to access, correct, and delete your personal data. If you have any concerns or requests regarding your data, or would like to delete your account, please contact us at support@thegivinggallery.com"
    }
  ];
  const footer = `Please share with us what is important to you about privacy as we develop this policy. Your voice matters to us.`;

  return (
    <Box
      sx={{
        width: "900px",
        maxWidth: "95%",
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        alignSelf: "center",
        gap: 3
      }}
    >
      <Typography variant="h4">Privacy Policy</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          alignSelf: "center",
          gap: 1
        }}
      >
        <Box>
          <Chip label={`Effective Date: ${effectiveDate}`} />
        </Box>
        <Typography variant="body1">{header}</Typography>
      </Box>
      {TOS.map((item, index) => {
        const emailPattern = /\S+@\S+\.\S+/;
        const words = item.content.split(" ");

        const elements = [];
        let buffer = "";

        words.forEach((word, idx) => {
          if (emailPattern.test(word)) {
            if (buffer) {
              elements.push(buffer);
              buffer = "";
            }
            elements.push(
              " ",
              <a href={`mailto:${word}`} key={idx}>
                {word}
              </a>,
              " "
            );
          } else {
            buffer += (buffer ? " " : "") + word;
          }
        });

        if (buffer) {
          elements.push(buffer);
        }

        return (
          <Box key={index}>
            <Typography variant="h5" sx={{ whiteSpace: "pre-line", mb: 1 }}>
              {item.title}
            </Typography>
            <Typography variant="body1" sx={{ whiteSpace: "pre-line", ml: 2 }}>
              {elements}
            </Typography>
          </Box>
        );
      })}

      <Typography variant="body1">{footer}</Typography>
    </Box>
  );
}
