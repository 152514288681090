import { AttachMoney, Brush, Check, Hail, Person } from "@mui/icons-material";
import {
  CircularProgress,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  useTheme
} from "@mui/material";

interface SetupWizardStepperProps {
  steps: SetupStep[];
  completedSteps: Set<SetupStep>;
  currentStep?: SetupStep;
  actions: (step: SetupStep) => void;
  loading: SetupStep[];
}

export type SetupStep = "profile" | "art" | "stripe";

//the following 2 consts can probably be combined, or I can make a series of if statements like the icons use
const nameToStepInfo = {
  profile: "Fill out Profile",
  art: "Upload Art",
  stripe: "Payout Setup"
};

// either map step order directly, or into an array of objects
// look at order and decide on the label and icon
function SetupWizardStepIcon(stepName: SetupStep, loading: boolean) {
  function CustomStepIcon({ active, completed }: StepIconProps) {
    const theme = useTheme();
    const sx = {
      color:
        active || completed
          ? theme.palette.primary.main
          : theme.palette.grey["500"]
    };
    const stepIcons = {
      welcome: <Hail sx={sx} />,
      profile: <Person sx={sx} />,
      art: <Brush sx={sx} />,
      stripe: <AttachMoney sx={sx} />
    };

    if (loading) {
      return <CircularProgress sx={sx} size="1.4rem" />;
    }

    return (
      //<Avatar color={active || completed ? "red" : "gray"}>
      active || !completed ? stepIcons[stepName] : <Check sx={sx} />
      //</Avatar>
    );
  }
  return CustomStepIcon;
}

// look at props to decide state
export default function SetupWizardStepper(props: SetupWizardStepperProps) {
  const steps = props.steps;

  const activeStepIndex = steps.findIndex((step) => {
    props.currentStep === step;
  });

  const isActiveStep = activeStepIndex == -1 ? false : true;

  function isStepCompleted(stepName: SetupStep) {
    return props.completedSteps.has(stepName);
  }

  function isNextStep(step: SetupStep) {
    const uncompleted = steps.filter(
      (possibleStep) => !props.completedSteps.has(possibleStep)
    );
    return step === uncompleted[0];
  }

  const theme = useTheme();

  return (
    <Stepper activeStep={activeStepIndex} alternativeLabel={true}>
      {steps.map((stepName) => {
        return (
          <Step key={stepName} completed={isStepCompleted(stepName)}>
            <StepLabel
              onClick={() => {
                isNextStep(stepName) && props.actions(stepName);
              }}
              sx={{
                "& .MuiStepLabel-label, .MuiStepLabel-label.Mui-completed": {
                  color:
                    isActiveStep || isStepCompleted(stepName)
                      ? theme.palette.primary.main
                      : theme.palette.grey["500"]
                },
                "&.Mui-disabled": {
                  cursor:
                    isStepCompleted(stepName) || !isNextStep(stepName)
                      ? "default"
                      : "pointer"
                },
                "&:hover .MuiSvgIcon-root": {
                  transform:
                    isStepCompleted(stepName) || !isNextStep(stepName)
                      ? "none"
                      : "scale(1.2)"
                },
                "& .MuiSvgIcon-root": {
                  transition: "all 0.2s"
                }
              }}
              StepIconComponent={SetupWizardStepIcon(
                stepName,
                props.loading.includes(stepName)
              )}
            >
              {nameToStepInfo[stepName]}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}
