import { create } from "zustand";

type InfoDialog = {
  content: string;
  title: string;
};

type SimpleDialog =
  | (InfoDialog & { type: "info" })
  | {
      type: "error";
      message: string;
      error?: unknown;
    };

type DialogState = {
  current: SimpleDialog | null;
  backlog: SimpleDialog[];
  addSimpleDialog: (dialog: SimpleDialog) => void;
  closeCurrent: () => void;
};

export const useDialogState = create<DialogState>((set) => ({
  current: null,
  backlog: [],
  addSimpleDialog(dialog) {
    set((state) => {
      return {
        ...state,
        current: state.current ? state.current : dialog,
        backlog: state.current ? [...state.backlog, dialog] : state.backlog
      };
    });
  },
  closeCurrent() {
    set(() => ({ current: null }));
  }
}));

export const dialog = {
  alert(dialog: InfoDialog) {
    useDialogState.getState().addSimpleDialog({ type: "info", ...dialog });
  },
  error(message: string, e?: unknown) {
    console.error(message, e);

    useDialogState
      .getState()
      .addSimpleDialog({ type: "error", message: message, error: e });
  }
};
