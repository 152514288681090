import { z } from "zod";

export const NonProfitDocument = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  shortDescription: z.string().optional(),
  shortName: z.string().optional(),
  image: z.string().or(z.object({ path: z.string() })),
  slug: z.string(),
  artThatSupported: z.array(z.string()).optional(),
  website: z.string().url(),
  priority: z.number(),
  earmarked: z.string().url()
});

export type NonProfitDocument = z.infer<typeof NonProfitDocument>;
