import { z } from "zod";

export const ImageDetails = z.object({
  path: z.string(),
  full: z.object({
    width: z.number(),
    height: z.number()
  })
});

export const InternalImageDetails = z.object({
  // internal means it's stored in cloud storage, but not in BunnyCDN
  type: z.literal("internal"),
  path: z.string(),
  full: z.object({
    width: z.number(),
    height: z.number()
  }),
  tag: z.literal("rotated_90_deg_right")
})

export type ImageDetails = z.infer<typeof ImageDetails>;
export type InternalImageDetails = z.infer<typeof InternalImageDetails>;
