import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography
} from "@mui/material";
import { Form, Formik } from "formik";
import FormikField from "../formik/FormikField";
import DialogTitleWithClose from "../helpers/DialogTitleWithClose";

import slugify from "shared/tools/slugify";

import { ArtistUser } from "shared/models/user-models";
import { z } from "zod";
import { withZodSchema } from "../../helpers/ZodFormikAdapter";
import { callCloudFunction } from "../../functions/callCloudFunction";
import { dialog } from "../../zustand/imperative-dialog";

type ChangeStoreNameDialogProps = {
  onClose: () => void;
  targetUser?: ArtistUser;
};

export default function ChangeStoreNameDialog({
  targetUser,
  onClose
}: ChangeStoreNameDialogProps) {
  const open = targetUser !== undefined;

  async function submitForm(values: FormData) {
    if (!targetUser) {
      const e = new Error("couldn't submit");

      dialog.error("Can't determine what user to target for change", e);
      return;
    }

    try {
      const changeResult = await callCloudFunction("changeStoreName", {
        changeFrom: targetUser.storeName,
        changeTo: values.newStoreName,
        targetArtistId: targetUser.userId
      });

      onClose();

      dialog.alert({
        title: "Success",
        content: `successfully changed store name from ${targetUser.storeName} -> ${values.newStoreName}`
      });
    } catch (e) {
      dialog.error("failed to change store name", e);
    }
  }

  const FormData = z.object({ newStoreName: z.string().min(2) });

  type FormData = z.infer<typeof FormData>;

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitleWithClose onClose={onClose}>
        Change Store Name
      </DialogTitleWithClose>

      <Formik<FormData>
        initialValues={{
          newStoreName: ""
        }}
        validate={withZodSchema(FormData)}
        onSubmit={submitForm}
      >
        {({ isSubmitting, values }) => (
          <>
            <DialogContent>
              {targetUser && (
                <Form id="change-store-name-form">
                  <Typography>
                    Existing Store Name: <b>{targetUser.storeName}</b>
                  </Typography>

                  <FormikField
                    sx={{ mt: 3, mb: 3 }}
                    label="New Store Name"
                    name="newStoreName"
                  />

                  <Grid container>
                    <Grid item xs={6}>
                      Existing Store Slug:
                    </Grid>

                    <Grid item xs={6}>
                      <b>{slugify(targetUser.storeName)}</b>
                    </Grid>

                    <Grid item xs={6}>
                      New Store Slug:
                    </Grid>

                    <Grid item xs={6}>
                      <b>{slugify(values.newStoreName)}</b>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                form="change-store-name-form"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Changing..." : "Change Store Name"}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
}
