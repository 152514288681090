import { Box, Typography } from "@mui/material";
import { staticAsset } from "../AssetsResolver";
import Config from "../config";
import Buckets from "./Buckets";
import OurPartners from "./about/OurPartners";
import Schmear from "./about/Schmear";

export default function NonProfitsPage() {
  // TODO: leverage loading and error states
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "100%"
      }}
    >
      <Schmear text="Art that Supports Mental Health Charities" />

      <Box
        sx={{
          width: Config.ux.navBarWidth,
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 3
        }}
      >
        <Typography>
          The Giving Gallery believes that artists play a critical role in
          raising awareness about mental health. A large percentage of every
          artwork sale supports both the artist and a partnered mental health
          charity of the buyer's choice. Through partnering with mental health
          charities, we hope to open doors for artists and buyers to learn more
          about mental health and create art with a purpose.
        </Typography>

        <OurPartners />

        <Typography variant="h3" sx={{ mt: 3 }}>
          How it Works
        </Typography>

        <Buckets
          buckets={[
            {
              img: staticAsset("hand-reaching-away.png"),
              title: "Partner",
              content: `Your nonprofit joins The Giving
                Gallery to receive charitable
                contributions from art sales. A
                percentage from each artwork
                sale supports a nonprofit of the
                buyer's choice.`
            },
            {
              img: staticAsset("handing-art-to-another-3.png"),
              title: "Promote",
              content: `We create a dedicated page about
                your nonprofit on The Giving
                Gallery, which can be used to share
                with your network. The Giving
                Gallery also promotes through our
                own networks and nonprofits.`
            },
            {
              img: staticAsset("reaching-hand-in-painting.png"),
              title: "Sale Proceeds",
              content: `We payout nonprofits 30% of art sale proceeds quarterly.`
            }
          ]}
        />
      </Box>
    </Box>
  );
}
