// it's important to run this before we import parts of the app
import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import Config from "./config";

const firebaseConfig = Config.firebase.config;

// Initialize Firebase
const app = initializeApp(firebaseConfig);

if (Config.firebase.emulators.ports.firestore) {
  const port = Config.firebase.emulators.ports.firestore;
  connectFirestoreEmulator(getFirestore(), "localhost", port);
}

if (Config.firebase.emulators.ports.storage) {
  const port = Config.firebase.emulators.ports.storage;
  connectStorageEmulator(getStorage(), "localhost", port);
}

if (Config.firebase.emulators.ports.functions) {
  const port = Config.firebase.emulators.ports.functions;
  connectFunctionsEmulator(getFunctions(), "localhost", port);
}

// TODO: auth emulator

export default app;
