import { z } from "zod";
import { CoreArtistData, NameSchema } from "./artist-models";
import { CoreProductInfo } from "./product-models";

export const CreateProductData = CoreProductInfo.extend({
  mainImageUploadId: z.string().uuid()
});

export const CreateOrUpdateProduct = z.union([
  CreateProductData.partial().extend({ updatingProductId: z.string() }),
  CreateProductData
]);

export type CreateOrUpdateProduct = z.infer<typeof CreateOrUpdateProduct>;

export const CreateProfileData = CoreArtistData.extend({
  profilePictureUploadId: z.string().uuid(),
  coverImageUploadId: z.string().uuid().optional(),
  nameVisibility: z.union([z.literal("private"), z.literal("public")]),
  name: NameSchema,
  notificationFrequency: z.literal("Daily").or(z.literal("Weekly")),
  commissionTarget: z.union([
    z.literal("self"),
    z.literal("nonprofit"),
    z.literal("TGG")
  ])
});

export const StoreSlugDoc = z
  .object({
    inviteCode: z.string(),
    fromName: z.string(),
    slugVersion: z.literal("v2"),
    slug: z.string().min(2),
    current: z.boolean()
  })
  .and(
    z
      .object({ outstandingInvite: z.literal(true), userId: z.undefined() })
      .or(z.object({ outstandingInvite: z.literal(false), userId: z.string() }))
  );

export type StoreSlugDoc = z.infer<typeof StoreSlugDoc>;

export const CreateOrUpdateProfile = z.union([
  makeOptionalPropsNullable(CreateProfileData)
    .partial()
    .extend({ updating: z.literal(true) }),
  CreateProfileData.extend({ updating: z.literal(false) })
]);

function makeOptionalPropsNullable<Schema extends z.AnyZodObject>(
  schema: Schema
) {
  const entries = Object.entries(schema.shape) as [
    keyof Schema["shape"],
    z.ZodTypeAny
  ][];
  //FIXME: remove unwrap
  const newProps = entries.reduce(
    (acc, [key, value]) => {
      acc[key] =
        value instanceof z.ZodOptional
          ? value.unwrap().nullable().optional()
          : value;
      return acc;
    },
    {} as {
      [key in keyof Schema["shape"]]: Schema["shape"][key] extends z.ZodOptional<
        infer T
      >
        ? z.ZodOptional<z.ZodNullable<T>>
        : Schema["shape"][key];
    }
  );
  return z.object(newProps);
}

export type CreateOrUpdateProfile = z.infer<typeof CreateOrUpdateProfile>;
