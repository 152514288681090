import { Flag } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import { Form, Formik, FormikErrors } from "formik";
import { useState } from "react";
import { callCloudFunction } from "../../functions/callCloudFunction";
import FormikField from "../formik/FormikField";
import DialogTitleWithClose from "./DialogTitleWithClose";
import { ProductDocument } from "shared/models/product-models";
import { ArtistDocument } from "shared/models/artist-models";

type ReportContentProps =
  | {
      resourceType: "artist";
      artist: ArtistDocument;
    }
  | {
      resourceType: "product";
      product: ProductDocument;
    };

const titleFor = {
  artist: "Report Artist's Profile",
  product: "Report Artwork"
};

export default function ReportContent(props: ReportContentProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [reportPlaced, setReportPlaced] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Tooltip title={titleFor[props.resourceType]}>
          <IconButton onClick={() => setDialogOpen(true)}>
            <Flag color={reportPlaced ? "error" : "action"} />
          </IconButton>
        </Tooltip>
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="xs"
      >
        <DialogTitleWithClose onClose={() => setDialogOpen(false)}>
          {titleFor[props.resourceType]}
        </DialogTitleWithClose>
        <DialogContent>
          {reportPlaced ? (
            <>
              <Typography paragraph>
                Thank you for helping us keep a respectful community. We will
                investigate your report to see if this violates our Terms and
                Conditions, and if so remove it or have the artist change it.
              </Typography>
              <Typography>
                If you have any additional issues, feel free to email us at{" "}
                <a href="mailto:support@thegivinggallery.com">
                  support@thegivinggallery.com
                </a>
              </Typography>
            </>
          ) : (
            <>
              <Typography>
                Keeping a respectful community is important to us. If something
                about this content is problematic, please place a report and we
                will investigate.
              </Typography>
              <Formik
                initialValues={{ details: "" }}
                validate={(values) => {
                  const errors: FormikErrors<{ details: string }> = {};

                  if (!values.details) {
                    errors.details = "Required";
                  }

                  if (values.details.length > 300) {
                    errors.details =
                      "Please limit this report to 300 characters";
                  }

                  return errors;
                }}
                onSubmit={async (values) => {
                  setSubmitting(true);

                  try {
                    await callCloudFunction("reportContent", {
                      details: values.details,
                      resourceId:
                        props.resourceType === "artist"
                          ? props.artist.artistId
                          : props.product.productId,
                      resourceName:
                        props.resourceType === "artist"
                          ? props.artist.storeName
                          : props.product.title,
                      resourceType: props.resourceType,
                      resourceUrl: window.location.href
                    });
                  } catch (e) {
                    // TODO: get error message from callCloudFunction
                    alert(
                      "Error submitting report, please contact support@thegivinggallery.com directly."
                    );
                  }

                  setSubmitting(false);
                  setReportPlaced(true);
                }}
              >
                <Form id="report-form">
                  <FormikField
                    sx={{ mt: 3 }}
                    name="details"
                    multiline
                    minRows={3}
                    placeholder="Please provide details"
                    label="Report"
                  />
                </Form>
              </Formik>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {reportPlaced ? (
            <Button onClick={() => setDialogOpen(false)}>Close</Button>
          ) : (
            <Button form="report-form" type="submit" disabled={submitting}>
              {submitting ? "sending..." : "Place Report"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
