import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { staticAsset } from "../AssetsResolver";
import Amanda from "../assets/Amanda.jpeg";
import Arouti from "../assets/Arouti-Agoupi.jpeg";
import Ariel from "../assets/ariel.jpg";
import Eli from "../assets/david-israelian-Sept-5-2023.jpeg";
import Julia from "../assets/julia.jpg";
import Nathan from "../assets/nathan-1.jpeg";
import Joanne from "../assets/joanne.jpeg";
import Scott from "../assets/Scott.jpeg";
import TeamPhoto from "../assets/team-photo.jpg";
import Buckets from "../components/Buckets";
import OurPartners from "../components/about/OurPartners";
import Schmear from "../components/about/Schmear";
import Config from "../config";
import TeamSection from "./TeamSection";

export default function About() {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "100%" }}>
      <Schmear text="Bridging art and mental health" />
      <Box
        style={{
          width: Config.ux.navBarWidth,
          maxWidth: "100%",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignSelf: "center"
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 3,
            mt: 3,
            p: 3,
            flexWrap: "wrap-reverse",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Box
            component="img"
            src={TeamPhoto}
            sx={{ width: "400px", aspectRatio: "2/1.4", objectFit: "cover" }}
          />

          <Box
            sx={{ flexBasis: "500px", flexGrow: 1, textAlign: "center", p: 3 }}
          >
            <Typography paragraph>
              The Giving Gallery is an e-commerce platform for artists to sell
              their art in partnership with mental health organizations. A large
              percentage of every artwork sale supports both the artist and a
              partnered mental health charity of the buyer's choice. The Giving
              Gallery believes that artists play a critical role in raising
              awareness about mental health. By elevating artists and mental
              health causes together, there is opportunity to creatively raise
              funds, education, and awareness about mental health.
            </Typography>

            <Typography paragraph>
              We are a team of advocates, artists, and technologists who are
              passionate about mental health and nonprofit fundraising.
            </Typography>
          </Box>
        </Box>

        {/* "It started with a box of crayons" */}
        <Box
          sx={{
            display: "flex",
            columnGap: 3,
            rowGap: 3,
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            width: "100%"
          }}
        >
          <Box sx={{ width: "100%", flex: 1, flexBasis: "500px", p: 3 }}>
            <Typography variant="h3" mb={2} ml="15%">
              It Started with
            </Typography>
            <Typography variant="h3" mb={2} textAlign="end" mr="15%">
              a Box of Crayons
            </Typography>
            <Typography>
              Amanda was a patient at a psychiatric hospital during her freshman
              year of college. A nurse gave her a{" "}
              <Box
                component="span"
                sx={{
                  color: "transparent",
                  // removed yellow for poor contrast
                  backgroundImage:
                    "linear-gradient(to left, violet, indigo, blue, green, orange, red)",
                  backgroundClip: "text"
                }}
              >
                box of crayons
              </Box>
              , which opened the door for conversation and healing. After Amanda
              was discharged, she began auctioning her art to raise funds for
              youth suicide prevention programs. This led to building The Giving
              Gallery, so other artists could have a place to sell their art and
              connect with mental health organizations.
            </Typography>
          </Box>
          <img
            style={{ width: "min(500px, 100%)", flexShrink: 0 }}
            src={staticAsset("amanda-next-to-artwork.jpg")}
          />
        </Box>
      </Box>

      <Schmear
        text="Share The Giving Gallery on social media"
        action={{
          type: "social"
        }}
      />

      <Box
        style={{
          width: Config.ux.navBarWidth,
          maxWidth: "100%",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignSelf: "center"
        }}
      >
        <OurPartners includePreamble />

        <Box sx={{ backgroundColor: "#f2fafd", width: "100%", mt: 3 }}>
          <Buckets
            buckets={[
              {
                img: staticAsset("hand-reaching-away.png"),
                title: "Artists",
                content:
                  "We partner with artists that want to support raising awareness and funding for mental health charities.",
                action: () => navigate("/artists")
              },
              {
                img: staticAsset("handing-art-to-another-3.png"),
                title: "Nonprofits",
                content:
                  "We partner with nonprofits that want to support artists and diversify their fundraising through art sales.",
                action: () => navigate("/nonprofits")
              },
              {
                img: staticAsset("reaching-hand-in-painting.png"),
                title: "Buyers",
                content:
                  "Browse The Giving Gallery and pick which nonprofit receives the charitable contribution upon checkout.",
                action: () => navigate("/artwork")
              }
            ]}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: 11,
            rowGap: 1,
            m: 0.5,
            mt: 3
          }}
        >
          <TeamSection
            title="Our Team and Advisors"
            people={[
              { image: Amanda, name: "Amanda Lipp", role: "founder" },
              { image: Arouti, name: "Arouti Agoupi", role: "co-founder" },
              { image: Eli, name: 'David "Eli" Israelian', role: "co-founder" },
              
              { name: "Ariel Ezekiel-Sayegh", image: Ariel, role: "developer" },
              {
                name: "Joanne Crawford-Duner",
                image: Joanne,
                role: "advisor"
              },
              { name: "Scott Syphax", image: Scott, role: "advisor" },
              { name: "Julia Brock", image: Julia, role: "advisor" }
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
}
