import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect } from "react";
import { AllCollections } from "shared/models/collections";
import { ProductDocument } from "shared/models/product-models";
import { z } from "zod";
import { create } from "zustand";
import { productsConverter } from "../databaseModels/DatabaseModels";
import { zodConverter } from "../databaseModels/firestoreHelpers";

export type DocumentState<T> =
  | {
      state: "loading";
    }
  | { state: "error"; error: unknown }
  | { state: "loaded"; document: T };

type DocumentsState = {
  collections: {
    [Collection in keyof typeof AllCollections]: Record<
      string,
      DocumentState<z.infer<(typeof AllCollections)[Collection]["schema"]>>
    >;
  };

  products: Record<string, DocumentState<ProductDocument>>;

  fetchDocument: <
    Collection extends keyof typeof AllCollections,
    Data extends z.infer<(typeof AllCollections)[Collection]["schema"]>
  >(
    documentId: string,
    collection: Collection
  ) => Promise<Data>;

  setDocument: <
    Collection extends keyof typeof AllCollections,
    Data extends z.infer<(typeof AllCollections)[Collection]["schema"]>
  >(
    id: string,
    document: Data,
    collection: Collection
  ) => void;

  productSlugs: Record<string, ProductDocument>;
  setProductDocument: (document: ProductDocument) => void;
  fetchProduct: ({ id }: { id: string }) => Promise<void>;
};

export const useDocuments = create<DocumentsState>((set) => ({
  products: {},
  productSlugs: {},
  collections: {
    artists: {},
    checkoutSessions: {},
    firestoreBackups: {},
    invites: {},
    nonprofits: {},
    orders: {},
    printfulCategories: {},
    printfulProducts: {},
    products: {},
    subOrders: {},
    users: {},
    printfulVariants: {},
    config: {},
    nonprofitPrivate: {},
    notifications: {},
    storeSlugs: {},
    deletions: {},
    emails: {}
  },
  fetchDocument<
    Collection extends keyof typeof AllCollections,
    Data extends z.infer<(typeof AllCollections)[Collection]["schema"]>
  >(documentId: string, collection: Collection) {
    let resolvedOrRejectedOnce = false;

    return new Promise<Data>((resolve, reject) => {
      const currentState =
        useDocuments.getState().collections[collection][documentId];

      if (currentState?.state === undefined) {
        set((state) => ({
          collections: {
            ...state.collections,
            [collection]: {
              ...state.collections[collection],
              [documentId]: { state: "loading" }
            }
          }
        }));

        const document = doc(
          getFirestore(),
          AllCollections[collection].name,
          documentId
        ).withConverter(zodConverter(collection));

        onSnapshot(
          document,
          (snapshot) => {
            const data = snapshot.data();

            if (data !== undefined) {
              useDocuments.getState().setDocument(documentId, data, collection);

              if (!resolvedOrRejectedOnce) {
                resolvedOrRejectedOnce = true;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                resolve(data as any); // need hammer for this
              }
            }
          },
          (error) => {
            console.error(
              `Failed to load document (collection=${collection}, id=${documentId})`
            );

            if (!resolvedOrRejectedOnce) {
              resolvedOrRejectedOnce = true;
              reject(error);
            }

            set((state) => ({
              collections: {
                ...state.collections,
                [collection]: {
                  ...state.collections[collection],
                  [documentId]: { state: "error", error: error }
                }
              }
            }));
          }
        );
      } else if (currentState.state === "loaded") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        resolve(currentState.document as any);
      } else if (currentState.state === "error") {
        reject(currentState.error);
      }
    });
  },
  setDocument(id, document, collection) {
    set((state) => ({
      collections: {
        ...state.collections,
        [collection]: {
          ...state.collections[collection],
          [id]: { state: "loaded", document }
        }
      }
    }));
  },
  setProductDocument(document) {
    set((priorState) => ({
      products: {
        ...priorState.products,
        [document.productId]: { state: "loaded", document: document }
      }
    }));
  },
  async fetchProduct({ id }) {
    //dispatch(productsSlice.actions.setPopularProductsLoading());

    const currentState = useDocuments.getState().products[id];

    if (currentState?.state === undefined) {
      set((oldState) => ({
        products: { ...oldState.products, [id]: { state: "loading" } }
      }));

      const document = doc(
        getFirestore(),
        AllCollections.products.name,
        id
      ).withConverter(productsConverter);

      onSnapshot(
        document,
        (snapshot) => {
          const data = snapshot.data();

          if (data !== undefined) {
            useDocuments.getState().setProductDocument(data);
          }
        },
        (error) => {
          console.error(`Failed to load product (id=${id})`, error);
          set((oldState) => ({
            products: {
              ...oldState.products,
              [id]: { state: "error", error: error }
            }
          }));
        }
      );
    }
  }
}));

export function useFetchProducts(productIds: string[]) {
  const fetchProduct = useDocuments((state) => state.fetchProduct);

  useEffect(() => {
    productIds.forEach((id) => {
      fetchProduct({ id: id });
    });
  }, [productIds]);

  return null;
}

export function usePersistentProduct(id: string) {
  const { fetchProduct, products } = useDocuments();

  useEffect(() => {
    fetchProduct({ id: id });
  }, [id]);

  return products[id];
}

export function useDocumentData<
  Collection extends keyof typeof AllCollections
>({ documentId, collection }: { documentId: string; collection: Collection }) {
  const { fetchDocument, collections } = useDocuments();

  useEffect(() => {
    fetchDocument(documentId, collection);
  }, [documentId, collection]);

  return collections[collection][documentId];
}
