import {
  AppBar,
  Box,
  Divider,
  Fab,
  SwipeableDrawer,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useScrollTrigger,
  Zoom
} from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
import { motion } from "framer-motion";
import { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import CartIcon from "./CartIcon";
import { MenuToggle } from "./helpers/MenuToggle";

import { KeyboardArrowUp } from "@mui/icons-material";
import { useAppSelector } from "../redux/store";
import "./Navigation.css";

import { staticAsset } from "../AssetsResolver";
import Config from "../config";
import { cartTotalCount, useCart } from "../zustand/cart-state";
import { setMultiModalStage } from "../zustand/multi-modal";

function ScrollToTopButton() {
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100
  });

  return (
    <Zoom in={trigger}>
      <Fab
        variant="circular"
        color="primary"
        onClick={() => document.body.scrollIntoView({ behavior: "smooth" })}
        sx={{
          position: "fixed",
          p: 4,
          bottom: 20,
          right: 20,
          display: "flex",
          flexDirection: "column"
        }}
        aria-label="back to top"
      >
        <KeyboardArrowUp />
        <Typography>top</Typography>
      </Fab>
      {/* <Box
          onClick={handleClick}
          role="presentation"
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
        >
          {children}
        </Box> */}
    </Zoom>
  );
}

export default function Navigation(): JSX.Element {
  const user = useAppSelector((state) => state.auth.user);

  const navigate = useNavigate();

  const shoppingCart = useCart();

  // todo: make sure the navbar controls are accessible (aria controls and such)

  // navbar medium: 768px
  // navbar large: 992px
  const fullWidth = useMediaQuery("(min-width: 768px)");

  const [open, setOpen] = useState(false);
  const [transitioning, setTransitioning] = useState(false);

  //const { pathname: currentPath } = useLocation();

  const transitionDuration = 500;

  const setZIndex = open || transitioning;

  function toggleMenu() {
    setOpen(!open);
    setTransitioning(true);
    setTimeout(() => setTransitioning(false), transitionDuration);
  }

  // TODO: see if this is still needed?
  function bodyFix(count?: number, fixCount?: number) {
    const maxLoop = 500;
    count = count || 1;
    fixCount = fixCount || 0;

    if (fixCount! >= 1) {
      return;
    }

    if (count! >= maxLoop) {
      console.log("max loops reached when attempting to fix navbar issue");
      return;
    }

    if (!Object.keys(document.body.style)) {
      // keep trying until we see a style

      setTimeout(() => {
        bodyFix(count! + 1);
      }, 0);
    } else {
      // has style we want to remove
      //document.body.setAttribute("style", "padding-right: 17px; overflow: hidden;");
      setTimeout(() => {
        bodyFix(count! + 1, fixCount! + 1);
      }, 0);
    }
  }

  function handleLinkClick() {
    if (!fullWidth) {
      // menu needs closing
      setOpen(false);
      setTransitioning(true);
      setTimeout(() => setTransitioning(false), transitionDuration);
    }
  }

  const sharedItems = (
    <>
      <NavLink onClick={handleLinkClick} to="/about">
        About
      </NavLink>

      <NavLink onClick={handleLinkClick} to="/artwork">
        Gallery
      </NavLink>

      <NavLink onClick={handleLinkClick} to="/artists">
        Artists
      </NavLink>

      <NavLink onClick={handleLinkClick} to="/nonprofits">
        Nonprofits
      </NavLink>

      {user &&
        (user.roles.includes("admin") || user.roles.includes("reporter")) && (
          <NavLink onClick={handleLinkClick} to="/admin">
            Admin
          </NavLink>
        )}

      {!user && (
        <a
          onClick={() => {
            setMultiModalStage({ name: "login", deepLink: "login" });
            handleLinkClick();
            bodyFix();
          }}
          role="button"
        >
          Log In
        </a>
      )}

      {user && (
        <>
          {user.userDocument?.isArtist && (
            <NavLink
              // className={
              //   // specifically toggle active if looking at your own profile
              //   currentPath ===
              //   `/artists/${user.userDocument.storeName}`
              //     ? "active"
              //     : ""
              // }
              onClick={handleLinkClick}
              to="/mystore"
            >
              My Store
            </NavLink>
          )}

          <Tooltip title={`Log out of ${user.email}`}>
            <a
              onClick={() => {
                signOut(getAuth());
                navigate("/"); // take them back to the homepage after logout
                handleLinkClick();
              }}
            >
              Log Out
            </a>
          </Tooltip>
        </>
      )}
    </>
  );

  const fullItems = (
    <>
      {sharedItems}

      {!Config.ux.checkoutDisabled && <CartIcon />}
    </>
  );

  const drawerItems = (
    <>
      {sharedItems}
      {!Config.ux.checkoutDisabled && (
        <>
          <Divider sx={{ width: "100%" }} />
          <NavLink to="/cart" onClick={handleLinkClick}>
            Checkout ({cartTotalCount(shoppingCart)} item
            {cartTotalCount(shoppingCart) === 1 ? "" : "s"})
          </NavLink>
        </>
      )}
    </>
  );

  /**
   * It's possible that the background of our site will change without our knowledge,
   * based on e.g., the darkreader extension. However, those extensions wouldn't
   * change the background of our particle system for fireworks, resulting in a
   * broken visual site.
   *
   * If this causes issues in the future, we can remove the particles, as they're not
   * that important. But it's a cool feature, and this should work pretty well.
   *
   * NOTE: This only works if the body background is the main background. If we have
   * our own dark mode or custom MUI Paper backgrounds, then this would likely
   * not work.
   */
  return (
    <>
      <AppBar
        elevation={0}
        position="sticky"
        id="main-appbar"
        sx={{
          // set z-index context to 20 min, mainly so the shopping cart shows above gallery,
          zIndex: setZIndex ? 1001 : 20,
          display: "flex",
          flexDirection: "row",
          color: "unset"
        }}
      >
        <Toolbar
          className={`tgg-toolbar`}
          sx={{ maxWidth: 1200, flexGrow: 1, pl: 0 }}
        >
          <Link
            to="/"
            className="tgg-logo-link"
            style={{ padding: 0 }}
            onClick={handleLinkClick}
          >
            <Box id="main-appbar-brand" sx={{ display: "flex", pl: 1 }}>
              <img
                src={staticAsset("tgg-logo.png")}
                alt="Giving Gallery Logo"
              />
            </Box>
          </Link>

          <Box ml="auto" />
          {fullWidth && fullItems}
          {!fullWidth && (
            <motion.div initial={false} animate={open ? "open" : "closed"}>
              <MenuToggle
                toggle={toggleMenu}
                badgeContent={
                  Config.ux.checkoutDisabled ? 0 : cartTotalCount(shoppingCart)
                }
              />
            </motion.div>
          )}
        </Toolbar>
      </AppBar>
      {/* needed if appbar position = "fixed" <Toolbar/> */}
      <Divider />

      {!fullWidth && (
        <SwipeableDrawer
          sx={{
            zIndex: 1000
          }}
          disableScrollLock={true}
          id="main-drawer"
          disableSwipeToOpen
          anchor="right"
          variant="temporary"
          open={open}
          transitionDuration={{
            enter: transitionDuration,
            exit: transitionDuration
          }}
          PaperProps={{ elevation: 9 }}
          onOpen={() => null}
          onClose={() => toggleMenu()}
        >
          <>
            <Box sx={{ height: "87px", backgroundColor: "white" }} />
            <Toolbar className="tgg-toolbar drawer">{drawerItems}</Toolbar>
          </>
        </SwipeableDrawer>
      )}

      <ScrollToTopButton />
    </>
  );
}
