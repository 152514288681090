import {
  Autocomplete,
  Box,
  Chip,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { Formik } from "formik";
import { TGGArtistUser } from "../../redux/slices/authSlice";
import ManageStoreScaffold from "./MyStoreScaffold";

import { useEffect, useRef, useState } from "react";

import { getStorage, ref as storageRef, uploadString } from "firebase/storage";

import { FormikProps } from "formik";

import "react-image-crop/dist/ReactCrop.css";
import FormikEffect from "../helpers/FormikEffect";

import { Brush, DeleteForever } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  ListItem
} from "@mui/material";
import { doc, getFirestore } from "firebase/firestore";
import { isEqual } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidV4 } from "uuid";
import AssetsResolver from "../../AssetsResolver";
import { callCloudFunction } from "../../functions/callCloudFunction";
import { withZodSchema } from "../../helpers/ZodFormikAdapter";
import FormikField from "../formik/FormikField";
import FormikImageField, {
  ImageValueSchema,
  superRefineImageValue
} from "../formik/FormikImageField";
import FormikScrollToError from "../helpers/FormikScrollToError";
import LoadingButton from "../helpers/LoadingButton";

import { CloudFunctionData } from "shared/cloud-functions";
import { ArtistDocument } from "shared/models/artist-models";
import { CreateProductData } from "shared/models/store-models";
import { tggPrimary } from "../../App";
import { useDocumentDataCustom, useProduct } from "../../helpers/queries";
import DialogTitleWithClose from "../helpers/DialogTitleWithClose";
import Guidelines from "../legal/Guidelines";

import scrollIntoView from "scroll-into-view-if-needed";
import { z } from "zod";
import { dialog } from "../../zustand/imperative-dialog";

interface HasArtist {
  artistUser: TGGArtistUser;
}

type CreateOrUpdateProductProps = HasArtist & { artist: ArtistDocument };

/**
 * We enrich the underlying schema with our image refining logic
 */
const CreateProductSchema = CreateProductData.omit({
  mainImageUploadId: true
}).extend({
  image: ImageValueSchema.optional().superRefine(
    superRefineImageValue({
      enforceDefined: true,
      type: "product"
    })
  )
});

type CreateProductSchema = z.infer<typeof CreateProductSchema>;

export default function CreateOrUpdateProduct({
  artist,
  artistUser
}: CreateOrUpdateProductProps) {
  const navigate = useNavigate();

  const formRef = useRef<FormikProps<CreateProductSchema>>(null);

  const { slug } = useParams();

  const edit = slug !== undefined;

  const [product, productLoading] = useProduct(slug || null);

  const [uploadState, setUploadState] = useState<
    "Uploading" | "Processing" | undefined
  >(undefined);

  async function upload(values: CreateProductSchema) {
    try {
      const uploadId = uuidV4();

      if (values.image?.type === "new") {
        setUploadState("Uploading");

        const storage = getStorage();

        // these images are public and should be cached indefinitely
        const newMetadata = {
          cacheControl: "public,max-age=31536000"
        };

        const uploadRef = storageRef(
          storage,
          `/uploads/${artistUser.uid}/${uploadId}`
        );

        if (values.image?.type === "new") {
          await uploadString(
            uploadRef,
            values.image.dataUrl,
            "data_url",
            newMetadata
          );
        }
      }

      setUploadState("Processing");

      // now we call a cloud function to upload a piece of art, referencing the file that is chilling
      // in the uploads folder

      // first we create our realtime database record for this product, and then use that
      // unique id for the storage id as well (to link them)

      if (product) {
        // update

        const data: CloudFunctionData<"createOrUpdateProduct"> = {
          updatingProductId: product.productId
        };

        if (product.description !== values.description) {
          data.description = values.description;
        }

        if (product.price !== values.price) {
          data.price = values.price;
        }

        if (product.title !== values.title) {
          data.title = values.title;
        }

        if (product.altText !== values.altText) {
          data.altText = values.altText;
        }

        if (values.image?.type === "new" && uploadId) {
          data.mainImageUploadId = uploadId;
        }

        if (!isEqual(product.tags, values.tags)) {
          data.tags = values.tags;
        }

        const result = await callCloudFunction("createOrUpdateProduct", data);

        console.log("result of upload: ", result);
      } else {
        // initial upload
        const result = await callCloudFunction("createOrUpdateProduct", {
          description: values.description,
          altText: values.altText,
          price: values.price,
          title: values.title,
          tags: values.tags,
          mainImageUploadId: uploadId
        });

        console.log("result of upload: ", result);
      }

      navigate("/mystore");
    } catch (e) {
      dialog.error("Couldn't upload artwork", e);
    } finally {
      setUploadState(undefined);
    }
  }

  useEffect(() => {
    const handlesCollection = document.getElementsByClassName(
      "ReactCrop__drag-handle"
    );
    const handles = Array.from(handlesCollection);
    handles.forEach((handle) => ((handle as HTMLElement).tabIndex = -1));
  });

  // const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  // const cameraInput = useRef<HTMLInputElement | null>(null);

  const [tagsDoc] = useDocumentDataCustom(
    doc(getFirestore(), "tags", "allTags")
  );

  let knownTags: string[] = [];

  if (tagsDoc) {
    knownTags = Object.keys(tagsDoc);
  }

  //const knownTags = ["impressionism", "abstract", "photograph"];

  const [guidelineDialogOpen, setGuidelineDialogOpen] = useState(false);

  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [currentlyRemoving, setCurrentlyRemoving] = useState(false);

  const topRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (topRef.current) {
      scrollIntoView(topRef.current, {
        behavior: "smooth",
        scrollMode: "if-needed"
      });
    }
  }, [topRef]);

  return (
    <ManageStoreScaffold artist={artist} artistUser={artistUser}>
      <Box
        ref={topRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          pt: 3,
          alignItems: "center",
          maxWidth: "100%"
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between"
          }}
        >
          <Typography variant="h4" sx={{ m: "auto" }}>
            {edit ? "Edit Artwork" : "Upload Artwork"}
          </Typography>
          {edit && product && (
            <Tooltip title="Remove this artwork" arrow>
              <IconButton
                onClick={async () => {
                  setRemoveDialogOpen(true);
                }}
              >
                <DeleteForever color="error" fontSize="large" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        {/* Fixme: this logic is pretty complex. Clean it up, and maybe use loading component with timeout? */}

        {!(edit && !product) ? (
          <Formik<CreateProductSchema>
            validate={withZodSchema(CreateProductSchema)}
            initialValues={{
              altText: product?.altText || "",
              tags: product?.tags || [],
              title: product?.title || "",
              description: product?.description || "",
              price: product?.price || "",
              image: product
                ? {
                    type: "existing",
                    url: AssetsResolver.imageUrl(
                      product.image.path,
                      "artworkthumbnail"
                    )
                  }
                : undefined
            }}
            onSubmit={async (values) => {
              await upload(values);
            }}
            innerRef={formRef}
          >
            {({
              handleSubmit,
              values,
              touched,
              setFieldValue,
              isSubmitting,
              errors,
              handleBlur
            }) => (
              <Box
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: "#fff"
                  },
                  m: 3,
                  maxWidth: "100%"
                }}
              >
                <FormikScrollToError />
                <Box
                  component="form"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 2,
                    maxWidth: "100%"
                  }}
                >
                  <fieldset disabled={isSubmitting}>
                    {/* TODO: prevent navigation on changes w/o confirmation */}
                    <FormikEffect
                      onDirtyChange={(dirty) =>
                        console.log("form has changes: ", dirty)
                      }
                    />

                    <Box
                      sx={{
                        display: "flex",
                        maxWidth: "100%",
                        flexWrap: "wrap",
                        gap: 3
                      }}
                    >
                      <Box
                        m={0}
                        sx={{
                          flexBasis: "400px",
                          display: "flex",
                          flexDirection: "column",
                          gap: 3,
                          flexGrow: 1
                        }}
                      >
                        {/* Need mt={4} here so the label doesn't clip */}

                        <FormikField
                          name="title"
                          label="Title"
                          placeholder="A short title for this artwork"
                        />

                        <FormikField
                          multiline
                          rows={3}
                          placeholder="Tell the viewer about this artwork (inspirations, process, etc). Tell a story."
                          name="description"
                          label="Description"
                        />

                        <FormikField
                          placeholder="Visual description for visually impaired users"
                          allowUndefined
                          name="altText"
                          label="Alt Text"
                        />

                        <FormikField
                          placeholder="Enter price (not including tax)"
                          name="price"
                          label="Price"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            )
                          }}
                        />

                        <Autocomplete
                          clearOnBlur
                          onBlur={handleBlur}
                          onChange={(event, newValue) => {
                            setFieldValue(
                              "tags",
                              newValue.map((v) => v.toLowerCase())
                            );
                          }}
                          multiple
                          options={knownTags}
                          filterOptions={(options, state) => {
                            const normalizedInput =
                              state.inputValue.toLowerCase();

                            const optionsToShow = options.filter((o) =>
                              o.includes(normalizedInput)
                            );

                            if (
                              normalizedInput &&
                              !knownTags.includes(normalizedInput)
                            ) {
                              optionsToShow.push(normalizedInput);
                            }

                            return optionsToShow;
                          }}
                          renderOption={(props, option, state) => {
                            const newOption = ![
                              ...knownTags,
                              ...values.tags
                            ].includes(option);

                            return (
                              <ListItem {...props}>
                                {newOption ? `create "${option}"` : option}
                              </ListItem>
                            );
                          }}
                          freeSolo
                          value={values.tags}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index: number) => (
                              <Chip
                                sx={{ borderRadius: 1 }}
                                variant="filled"
                                label={option}
                                {...getTagProps({ index })}
                                key={option}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tags"
                              placeholder="short tags"
                              InputLabelProps={{ shrink: true }}
                              error={touched.tags && errors.tags !== undefined}
                              helperText={
                                touched.tags && typeof errors.tags === "string"
                                  ? errors.tags
                                  : undefined
                              }
                            />
                          )}
                        />
                      </Box>

                      <Box sx={{ flexBasis: "500px", flexGrow: 1 }}>
                        <>
                          <FormikImageField
                            label="Artwork"
                            name="image"
                            placeholderText={
                              <Typography sx={{ color: "text.secondary" }}>
                                Click here or drag and drop and image to upload.
                                <em> 2000 x 2500 pixel minimum</em> but higher
                                resolutions will allow more print options.
                              </Typography>
                            }
                          />

                          <Typography variant="h6">Content</Typography>
                          <Typography paragraph>
                            Check out the{" "}
                            <Box
                              component="span"
                              onClick={() => {
                                setGuidelineDialogOpen(true);
                              }}
                              sx={{
                                cursor: "pointer",
                                display: "inline",
                                color: tggPrimary.main
                              }}
                            >
                              community guidelines
                            </Box>
                            . Summary:{" "}
                            <em>no nudity, and no violence or self-harm. </em>
                            Please help us create a respectful community.
                          </Typography>

                          {values.image === undefined && (
                            <>
                              <Typography variant="h6" mt={3}>
                                Format
                              </Typography>

                              <Typography paragraph>
                                It should be a cropped image of just the
                                artwork, <em>no frame or background.</em>
                              </Typography>

                              <Typography variant="h6">Aspect Ratio</Typography>
                              <Typography paragraph>
                                We will crop the artwork for various print
                                dimensions, however, the original must be less
                                than 2/1 (i.e., not too narrow)
                              </Typography>
                            </>
                          )}
                        </>
                      </Box>
                    </Box>
                  </fieldset>

                  <LoadingButton
                    fullWidth
                    sx={{ alignSelf: "center", width: "100%" }}
                    size="large"
                    variant="contained"
                    endIcon={<Brush />}
                    onClick={() => handleSubmit()}
                    loading={isSubmitting}
                    loadingMessage={uploadState || "Uploading"}
                  >
                    {edit ? "Update" : "Upload"}
                  </LoadingButton>
                </Box>
              </Box>
            )}
          </Formik>
        ) : (
          !productLoading && (
            <Box sx={{ m: 3 }}>
              <Typography> Can't find product "{slug}"</Typography>
            </Box>
          )
        )}
        <Dialog
          open={guidelineDialogOpen}
          onClose={() => setGuidelineDialogOpen(false)}
        >
          <DialogTitleWithClose onClose={() => setGuidelineDialogOpen(false)}>
            {""}
          </DialogTitleWithClose>
          <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
            <Guidelines />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setGuidelineDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Dialog
        open={removeDialogOpen}
        onClose={() => setRemoveDialogOpen(false)}
        maxWidth="xs"
      >
        <DialogTitleWithClose onClose={() => setRemoveDialogOpen(false)}>
          Remove Artwork
        </DialogTitleWithClose>

        <DialogContent>
          <Typography paragraph>
            Remove this artwork, "{product?.title}"?
          </Typography>

          <Typography>
            It may remain in our system for records and backups, but will be
            removed from the site and inaccessible.
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={currentlyRemoving}
            disabled={!product}
            color="error"
            onClick={async () => {
              if (!product) {
                dialog.error("can't fetch product to delete");
                return;
              }

              setCurrentlyRemoving(true);

              try {
                await callCloudFunction("removeProduct", {
                  productId: product.productId,
                  version: product.version
                });

                setRemoveDialogOpen(false);
                navigate("/mystore");
              } catch (e) {
                dialog.error("couldn't remove product", e);
              } finally {
                setCurrentlyRemoving(false);
              }
            }}
          >
            Remove
          </LoadingButton>

          <Button onClick={() => setRemoveDialogOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </ManageStoreScaffold>
  );
}
