import { configureStore } from "@reduxjs/toolkit";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import authSlice from "./slices/authSlice";
import campaignSlice from "./slices/campaignSlice";
import pagesVisitedSlice from "./slices/pagesVisitedSlice";
import snackbarSlice from "./slices/snackbarSlice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    snackbar: snackbarSlice.reducer,
    campaign: campaignSlice.reducer,
    pagesVisited: pagesVisitedSlice.reducer
  },

  // we disable serializable check, because we're using
  // Date objects within Products
  // this is bad practice, but most likely harmless
  // see: https://github.com/reduxjs/redux-toolkit/issues/456
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * This was initially in authSlice, but belongs
 * here since it depends on the store being defined
 * and the store depends on the auth slice
 */
onAuthStateChanged(getAuth(), (user) => {
  store.dispatch(authSlice.actions.setFirebaseUser(user));
});

export default store;
