import {
  EmailOutlined,
  FacebookRounded,
  Instagram,
  YouTube
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { tggPrimary } from "../App";
import { staticAsset } from "../AssetsResolver";
import SupportEmail from "./helpers/SupportEmail";

function TggMediaLinks() {
  const sharedIconSx = {
    fontSize: "40px",
    color: tggPrimary.main,
    transition: "all .3s",
    "&:hover": {
      transform: "scale(1.1)",
      color: "rgb(55, 71, 68)",
      opacity: "0.9"
    }
  };

  return (
    <Box sx={{ display: "flex", columnGap: 3 }}>
      <a
        href="https://www.facebook.com/thegivinggallery"
        title="The Giving Gallery's Facebook"
        target="_blank"
        rel="noopener"
      >
        <FacebookRounded sx={sharedIconSx} />
      </a>

      <a
        href="https://www.instagram.com/thegivinggallery/"
        title="The Giving Gallery's Instagram"
        target="_blank"
        rel="noopener"
      >
        <Instagram sx={sharedIconSx} />
      </a>

      <a
        href="https://www.youtube.com/channel/UCMP2gw3D5adXNbEgRu9ZXdA"
        title="The Giving Gallery's YouTube"
        target="_blank"
        rel="noopener"
      >
        <YouTube sx={sharedIconSx} />
      </a>
    </Box>
  );
}

export default function Footer() {
  return (
    <Box
      style={{
        backgroundImage: `url(${staticAsset("footer-background.png")})`,
        backgroundPosition: "0px 44px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: "32px 0px 162px 0px",
        marginTop: "auto"
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        style={{ height: "350px", gap: 1 }}
        sx={{ p: 2 }}
      >
        <img
          style={{ alignSelf: "center", maxWidth: "95vw" }}
          src={staticAsset("tgg-logo.png")}
        />
        <TggMediaLinks />

        <Typography color="primary" variant="body2" sx={{ mt: 2 }}>
          © 2020-{new Date().getFullYear()}, The Giving Gallery. All rights
          reserved.{" "}
        </Typography>

        <Box
          sx={{
            display: "flex",
            rowGap: 0.5,
            columnGap: 2.5,
            mt: 0.5,
            flexWrap: "wrap",
            justifyContent: "center"
          }}
        >
          <Link to="/legal/guidelines">
            <Typography variant="body2" color="primary">
              Community Guidelines
            </Typography>
          </Link>
          <Link to="/faq">
            <Typography variant="body2" color="primary">
              FAQ
            </Typography>
          </Link>
          <Link to="/legal/terms-of-service">
            <Typography variant="body2" color="primary">
              Terms of Service
            </Typography>
          </Link>
          <Link to="/legal/privacy-policy">
            <Typography variant="body2" color="primary">
              Privacy Policy
            </Typography>
          </Link>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: "primary.main",
            mt: 0.5
          }}
        >
          <EmailOutlined fontSize="small" />
          <Typography variant="body2" color="primary">
            <SupportEmail />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
