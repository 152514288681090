import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

type SnackbarItemType = "added_to_cart";

interface SnackbarItem {
  type: SnackbarItemType;
  key: string;
}

interface SnackbarState {
  items: SnackbarItem[];
}

const initalState: SnackbarState = {
  items: []
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: initalState,
  reducers: {
    removeCurrent: (state: SnackbarState) => {
      state.items = state.items.slice(1);
    },

    addSnackbar: (
      state: SnackbarState,
      action: PayloadAction<SnackbarItemType>
    ) => {
      switch (action.payload) {
        case "added_to_cart": {
          const maybeCurrent = state.items.findIndex(
            (item) => item.type === "added_to_cart"
          );

          const newItem: SnackbarItem = {
            type: "added_to_cart",
            key: uuidv4()
          };

          if (maybeCurrent === -1) {
            // no current snack
            state.items.push(newItem);
          } else {
            // update existing
            state.items[maybeCurrent] = newItem;
          }

          return;
        }

        default: {
          const exhaustiveCheck: never = action.payload;
          throw new Error(exhaustiveCheck);
        }
      }
    }
    // setPopularProducts: (state: ProductsState, action: PayloadAction<ProductDocument[]>) => {
    //     state.popularProducts = action.payload;
    // }
  }
});

export default snackbarSlice;
