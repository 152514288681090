import slug from "slug";

/**
 * generic function for turning a URL into a slug. Uses the "slug" library
 * under the hood.
 *
 * General process for this is two-fold:
 * 1) Enforce the raw character set/constraints of the input string before this
 * 2) Slugify it
 * 3) Enforce uniqueness and any additional constraints (character length) after it
 */
export default function slugify(raw: string): string {
  return slug(raw);
}
