import { Lock, Redeem, ShoppingCartCheckout } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormLabel,
  Typography
} from "@mui/material";
import {
  AddressElement,
  ExpressCheckoutElement,
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { Form, Formik, FormikErrors } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AllCollections } from "shared/models/collections";
import { currency, sumCurrencyArray } from "shared/tools/currency-tools";
import { z } from "zod";
import { tggCheckoutSessionConverter } from "../../databaseModels/DatabaseModels";
import { callCloudFunction } from "../../functions/callCloudFunction";
import trackEvent from "../../helpers/plausible";
import { useCart } from "../../zustand/cart-state";
import { dialog } from "../../zustand/imperative-dialog";
import { usePaymentsState } from "../../zustand/payments-state";
import FormikField from "../formik/FormikField";
import LoadingButton from "../helpers/LoadingButton";
import CostBreakdown from "./CostBreakdown";
import { FormikObserver } from "../formik/FormikObserver";
interface PaymentProps {
  selectedNonprofit: {
    id: string;
  };
}

/**
 * Backend cares about emails being valid from Zod's perspective
 */
function validEmail(email: string) {
  return z.string().email().safeParse(email).success;
}

interface FormikValues {
  isGift: boolean;
  gifterName: string | undefined;
  giftMessage: string | undefined;
}

export default function Payments({ selectedNonprofit }: PaymentProps) {
  const items = useCart((state) => state.items);

  const stripe = useStripe();
  const elements = useElements();

  const paymentIntent = usePaymentsState((state) => state.paymentIntent);
  const [loadingPaymentIntent, setLoadingPaymentIntent] = useState(false);
  const [paymentIntentError, setPaymentIntentError] = useState<
    string | undefined
  >();

  const [attemptingPayment, setAttemptingPayment] = useState(false);
  const [paymentError, setPaymentError] = useState<string | undefined>();

  // stored address in global state
  const address = usePaymentsState((state) => state.address);

  // actively editing the address
  const [editingAddress, setEditingAddress] = useState(true);

  const navigate = useNavigate();

  const email = usePaymentsState((state) => state.email);

  const initialNonprofit = useMemo(() => selectedNonprofit.id, []);

  const [marketingEmailsAllowed, setMarketingEmailsAllowed] = useState(true);

  useEffect(() => {
    if (selectedNonprofit.id !== initialNonprofit) {
      // force new payment intent generation
      // TODO: allow changing existing checkout session.
      usePaymentsState.getState().setPaymentIntent(undefined);
      // todo: better state on this
      setEditingAddress(true);
      console.debug("nonprofit changed");
    }
  }, [selectedNonprofit.id]);

  /**
   * this is the core submission for calculating tax/shipping,
   * i.e., creating payment intents
   *
   * it invokes stripe element submission
   */
  async function handleSubmission(formikValues: FormikValues) {
    if (elements && stripe) {
      setLoadingPaymentIntent(true);

      const addressElement = elements.getElement("address");
      const value = await addressElement?.getValue();

      const { error: submitError } = await elements.submit();

      const paymentElement = elements.getElement("payment");
      paymentElement?.clear();

      if (
        submitError?.code === "incomplete_email" ||
        submitError?.code === "email_invalid"
      ) {
        setLoadingPaymentIntent(false);
        return;
      }

      if (!email) {
        setPaymentError("Email is required");
        setLoadingPaymentIntent(false);
        return;
      } else if (!validEmail(email)) {
        setPaymentError("Invalid email");
        setLoadingPaymentIntent(false);
        return;
      } else {
        setPaymentError(undefined);
      }

      const linkElement = elements.getElement("linkAuthentication");
      await linkElement?.blur();

      if (value?.complete) {
        // Trigger form validation and wallet collection

        const address = value.value;

        let firstName: string;
        let lastName: string | undefined;

        if (address.firstName && address.lastName) {
          firstName = address.firstName;
          lastName = address.lastName;
        } else {
          // we need to try and split the name
          const name = address.name;
          const nameParts = name.split(" ");

          // first part always exists after a split
          firstName = nameParts[0]!;
          // the rest of the name can be considered last name, if it exists
          lastName = nameParts[1]?.trim() || undefined;
        }

        try {
          trackEvent({
            event: "Calculated Shipping and Tax",
            properties: {
              baseTotal: `${sumCurrencyArray(
                items.map((item) => {
                  return currency(item.product.price).multiply(item.count);
                })
              )}`,
              itemCount: `${items.reduce(
                (accumulator, item) => accumulator + item.count,
                0
              )}`
            }
          });
          const paymentIntent = await callCloudFunction("createPaymentIntent", {
            gift: !formikValues.isGift
              ? null
              : {
                  message:
                    formikValues.giftMessage ||
                    "We hope you enjoy your gift from The Giving Gallery!",
                  gifterName: formikValues.gifterName
                },
            cart: items.map((item) => ({
              count: item.count,
              printfulVariant: {
                id: item.printfulVariant.id,
                price: item.printfulVariant.price,
                printfulProductId: item.printfulVariant.product_id
              },
              product: {
                basePrice: item.product.price,
                id: item.product.productId,
                version: item.product.version
              },
              fit: item.fit
            })),
            customer: {
              name: {
                first: firstName,
                last: lastName
              },
              email: email,
              phone: address.phone || null,
              shippingAddress: {
                country: "US",
                line1: address.address.line1,
                line2: address.address.line2,

                city: address.address.city,
                state: address.address.state,
                zipcode: address.address.postal_code
              }
            },
            expectedPrice: "50",
            origin: window.origin,
            selectedNonprofit: {
              id: selectedNonprofit.id
            }
          });

          setEditingAddress(false);
          usePaymentsState.getState().setAddress(address);
          usePaymentsState.getState().setPaymentIntent(paymentIntent);
        } catch (e) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setPaymentIntentError((e as any)?.message);
        }
      }
      setLoadingPaymentIntent(false);
    } else {
      setPaymentIntentError(
        "Can't get payment context, please contact support"
      );
    }
  }

  // needs memoized or change will get invoked too often
  const onFormikChange = useCallback(() => {
    // form has changed. Clear any client secret
    usePaymentsState.getState().setPaymentIntent(undefined);
  }, []);

  return (
    <Box sx={{ maxWidth: "100%" }}>
      <Formik
        initialValues={
          {
            isGift: false,
            gifterName: undefined,
            giftMessage: undefined
          } as FormikValues
        }
        validate={(values) => {
          const errors = {} as FormikErrors<FormikValues>;

          if (!values.isGift) {
            return errors;
          }

          if (
            values.gifterName !== undefined &&
            values.gifterName.length > 60
          ) {
            errors.gifterName = `name is too long (${values.gifterName.length}/60)`;
          }

          if (
            values.giftMessage !== undefined &&
            values.giftMessage.length > 200
          ) {
            errors.giftMessage = `message is too long (${values.giftMessage.length}/200)`;
          }

          return errors;
        }}
        onSubmit={handleSubmission}
      >
        {(form) => (
          <>
            <FormikObserver onChange={onFormikChange} />

            {/* Form contains gift fields as well as link & address stripe elements */}
            <Form>
              {/* Gift options */}
              <Box>
                <FormControlLabel
                  sx={{ mt: 1, mb: 1 }}
                  control={
                    <Checkbox
                      checked={form.values.isGift}
                      onChange={form.handleChange}
                      name="isGift"
                    />
                  }
                  label={
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Typography>This is a gift for someone </Typography>
                      <Redeem />
                    </Box>
                  }
                />

                <Collapse in={form.values.isGift}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      mt: 1,
                      mb: 1
                    }}
                  >
                    <FormikField
                      name="gifterName"
                      label="Your Name"
                      allowUndefined
                      placeholder="Alice"
                    />
                    <FormikField
                      allowUndefined
                      name="giftMessage"
                      label="Message"
                      placeholder="I hope this brightens your walls and life!"
                    />
                  </Box>
                </Collapse>
              </Box>

              <FormLabel sx={{ mt: 2, mb: 2, fontWeight: "bold" }}>
                Next your email address
              </FormLabel>
              <Box sx={{ mb: 2 }} />

              <LinkAuthenticationElement
                id="stripe-link-element"
                options={{ defaultValues: email ? { email } : undefined }}
                onChange={(change) => {
                  usePaymentsState.getState().setEmail(change.value.email);
                  // TODO: allow email changes to update an existing checkout session
                  if (paymentIntent) {
                    usePaymentsState.getState().setPaymentIntent(undefined);
                    setEditingAddress(true);
                  }
                }}
              />
            <Box sx={{ pt: 2, mb: 2 }}>
              <FormLabel sx={{fontWeight: "bold"}}>
                Now {form.values.isGift ? "their (recipient) " : "your "}
                shipping information <em>(must be US for now)</em>
              </FormLabel>
              </Box>
              {/* already captured address field, displayed with the option to change */}
              {address && (
                <Collapse in={!editingAddress}>
                  <Box
                    onClick={() => setEditingAddress(true)}
                    sx={{
                      maxWidth: "100%",
                      position: "relative",
                      cursor: "pointer",
                      border: 2,
                      borderRadius: 1,
                      p: 2,
                      borderColor: "primary.main",
                      color: "text.secondary"
                    }}
                  >
                    <Button
                      sx={{ position: "absolute", top: 3, right: 3 }}
                      onClick={() => setEditingAddress(true)}
                    >
                      change
                    </Button>
                    <Typography sx={{ color: "text.primary" }}>
                      {address.name}
                    </Typography>
                    <Typography variant="body2">
                      {address.address.line1}
                    </Typography>
                    {address.address.line2 && (
                      <Typography variant="body2">
                        {address.address.line2}
                      </Typography>
                    )}
                    <Typography variant="body2">
                      {address.address.city}, {address.address.state},{" "}
                      {address.address.postal_code}
                    </Typography>
                    {address.phone && (
                      <Typography variant="body2">{address.phone}</Typography>
                    )}
                  </Box>
                </Collapse>
              )}

              {/* Add/edit address */}
              <Collapse
                in={!address || editingAddress}
                sx={{ maxWidth: "100%" }}
              >
                <AddressElement
                  id="stripe-address-element"
                  onChange={(change) => {
                    change.isNewAddress &&
                      usePaymentsState.getState().setPaymentIntent(undefined);
                  }}
                  options={{
                    mode: "shipping",
                    defaultValues: {
                      ...address,
                      name: undefined
                    },
                    // autocomplete: {
                    //   mode: "google_maps_api",
                    //   apiKey: ""
                    // },
                    allowedCountries: ["US"],
                    display: { name: "split" },
                    fields: { phone: "always" }
                    // validation: {
                    //   phone: { required: "always" }
                    // }
                  }}
                />

                <Box mt={3} />

                {paymentIntent?.clientSecret && (
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => setEditingAddress(false)}
                  >
                    Keep address
                  </Button>
                )}
              </Collapse>

              {/*
                If we don't have a client secret for a payment intent, due to whatever reason, we need to generate it

                This will submit the formik form
              */}
              {!paymentIntent?.clientSecret && (
                <>
                  <LoadingButton
                    sx={{ mt: 1 }}
                    type="submit"
                    disabled={paymentIntent?.clientSecret !== undefined}
                    //variant="contained"
                    loading={loadingPaymentIntent}
                    endIcon={<ShoppingCartCheckout />}
                    variant="contained"
                    fullWidth
                  >
                    Calculate shipping/tax
                  </LoadingButton>
                  {paymentIntentError && (
                    <Alert severity="error">{paymentIntentError}</Alert>
                  )}
                </>
              )}
            </Form>

            <Box mt={3} />

            {/* Section for payment, requiring a client secret */}
            <Collapse
              in={paymentIntent?.clientSecret !== undefined}
              sx={{ maxWidth: "100%" }}
            >
              <Typography variant="h5">
                {paymentIntent && paymentIntent.standardShipping.name}
              </Typography>
              {paymentIntent && <CostBreakdown paymentIntent={paymentIntent} />}
              <Alert
                sx={{ mb: 2, mt: 2, maxWidth: "100%" }}
                icon={<Lock />}
                action={
                  <Button
                    color="success"
                    onClick={() =>
                      dialog.alert({
                        title: "Secure Payment",
                        content:
                          "Stripe is the industry standard for secure payment processing. We embed a payment form on our page, but due to browser security features we're not able to see credit card details even if we wanted to. Stripe will process your card on its servers."
                      })
                    }
                  >
                    Learn More
                  </Button>
                }
              >
                Pay securely with Stripe
              </Alert>

              <ExpressCheckoutElement onConfirm={() => null} />

              <PaymentElement
                id="stripe-payments-element"
                options={{
                  defaultValues: {
                    billingDetails: {
                      address: {
                        country: "US",
                      },

                      name: "Foo Bar"
                    }
                  }
                }}
              />

              {paymentIntent && (
                <Box mt={1}>
                  <Box sx={{ mb: 2 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={marketingEmailsAllowed}
                          onChange={async (event) => {
                            setMarketingEmailsAllowed(event.target.checked);

                            // now we update the checkout session
                            await updateDoc(
                              doc(
                                getFirestore(),
                                AllCollections.checkoutSessions.name,
                                paymentIntent.checkoutSessionId
                              ).withConverter(tggCheckoutSessionConverter),
                              {
                                marketingEmailsAllowed: event.target.checked
                              }
                            );
                          }}
                        />
                      }
                      label="Send me occasional emails about The Giving Gallery"
                    />
                  </Box>

                  <LoadingButton
                    onClick={async () => {
                      if (elements && stripe && paymentIntent?.clientSecret) {
                        const { error: submitError } = await elements.submit();
                        if (submitError) {
                          console.error("submit error", submitError);
                          //handleError(submitError);
                          return;
                        }

                        setAttemptingPayment(true);

                        const result = await stripe.confirmPayment({
                          elements,
                          clientSecret: paymentIntent.clientSecret,
                          redirect: "if_required",
                          confirmParams: {
                            // FIXME update this
                            return_url: "http://localhost:3000"
                          }
                        });

                        result.error;

                        if (result.error) {
                          console.error("payment failed", result.error);
                          setPaymentError(result.error.message);
                        } else {
                          setPaymentError(undefined);
                          navigate(
                            `/checkout/success?sid=${paymentIntent.checkoutSessionId}`
                          );
                        }

                        setAttemptingPayment(false);

                        trackEvent({
                          event: "Order Placed",
                          properties: {
                            checkoutSessionId: `${paymentIntent.checkoutSessionId}`,
                            total: `${paymentIntent.costs.total}`
                          }
                        });
                      }
                    }}
                    type="submit"
                    //variant="contained"
                    loading={attemptingPayment}
                    endIcon={<ShoppingCartCheckout />}
                    variant="contained"
                    fullWidth
                  >
                    Place Order (
                    {currency(paymentIntent.costs.total, {
                      fromCents: true
                    }).format()}
                    )
                  </LoadingButton>
                </Box>
              )}
              {paymentError && <Alert severity="error">{paymentError}</Alert>}
            </Collapse>
          </>
        )}
      </Formik>
    </Box>
  );
}
