import { create } from "zustand";

type MultiModalStage = {
  name: "login";
  deepLink?: "login" | "signUp";
  redirectAfter?: boolean;
  prefillEmail?: string;
} | null;

interface MultiModalState {
  stage: MultiModalStage;
  lastStage: MultiModalStage;
  setStage: (stage: MultiModalStage) => void;
}

export const useMultiModal = create<MultiModalState>((set) => ({
  stage: null,
  lastStage: null,
  setStage: (stage: MultiModalStage) =>
    set((state) => ({ stage: stage, lastStage: state.stage }))
}));

export function setMultiModalStage(stage: MultiModalStage) {
  useMultiModal.getState().setStage(stage);
}
