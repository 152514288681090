import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DateTime } from "luxon";

interface ActiveCampaign {
  supports: string;
  capturedAtEpochMs: number;
}

interface CampaignState {
  active?: ActiveCampaign;
}

const localStorageKey = "activeCampaign";
const maybeStashed = localStorage.getItem(localStorageKey);
let maybeParsed = maybeStashed
  ? (JSON.parse(maybeStashed) as ActiveCampaign)
  : undefined;

if (maybeParsed) {
  const campaignCapturedAt = DateTime.fromMillis(maybeParsed.capturedAtEpochMs);
  const timeDiff = campaignCapturedAt.diffNow(["hour", "minutes"]);

  // "more than 12 hours ago"
  if (timeDiff.hours < -12) {
    console.warn(
      `Active Campaign (supporting ${
        maybeParsed.supports
      }) was first seen ${campaignCapturedAt.toRelativeCalendar()}, discarding`
    );
    localStorage.removeItem(localStorageKey);
    maybeParsed = undefined;
  }
}

const initialState: CampaignState = maybeParsed ? { active: maybeParsed } : {};

const campaignSlice = createSlice({
  name: "campaign",
  initialState: initialState,
  reducers: {
    setActive: (
      state: CampaignState,
      action: PayloadAction<ActiveCampaign | undefined>
    ) => {
      state.active = action.payload;
      localStorage.setItem(localStorageKey, JSON.stringify(state.active));
    }
  }
});

export default campaignSlice;
