import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  GridProps,
  Skeleton,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import FlexibleProductList from "../productViews/FlexibleProductList";

import SchmearBottom from "../../assets/schmear-bottom-gray-improved.png";

import { LocalOffer, Replay } from "@mui/icons-material";
import { doc, getFirestore } from "firebase/firestore";
import {
  useDailyProducts,
  useDocumentDataCustom,
  useRecentProducts,
  useTaggedProducts
} from "../../helpers/queries";
import Schmear from "../about/Schmear";
import "./MainGallery.css";
import Categories from "./Categories";

export default function MainStoreView() {
  // TODO: proper recommendation system
  const [dailyPicks] = useDailyProducts(24);

  const [tagsDoc] = useDocumentDataCustom(
    doc(getFirestore(), "tags", "allTags")
  );

  type Tag = {
    name: string;
    count: number;
  };

  const knownTags: Tag[] | undefined = useMemo(() => {
    if (!tagsDoc) {
      return undefined;
    } else {
      return Object.entries(tagsDoc)
        .map(([name, count]) => ({ name, count }))
        .filter((t) => t.count >= 1);
    }
  }, [tagsDoc]);

  const [filters, setFilters] = useState<string[]>([]);

  const [filteredArtwork] = useTaggedProducts(
    filters.length > 0 ? filters : null
  );

  function randomChoice<T>(arr: T[]): T | null {
    const index: number = Math.floor(Math.random() * arr.length);
    return arr[index] || null;
  }

  const [selectedTag, setSelectedTag] = useState<Tag | undefined>(undefined);

  const frequentTags = useMemo(
    () => knownTags?.filter((t) => t.count >= 4),
    [knownTags]
  );

  useEffect(() => {
    if (frequentTags && !selectedTag) {
      setSelectedTag(randomChoice(frequentTags) || undefined);
    }
  }, [frequentTags]);

  const [tagSelectedProducts] = useTaggedProducts(selectedTag?.name || null, 4);

  const [recent, recentLoading] = useRecentProducts(10);

  const sectionWidth = "min(100%, 1300px)";

  const productListColumns: GridProps = {
    xs: 6,
    sm: 4,
    md: 3,
    lg: 2
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        sx={{
          //backgroundColor: "rgba(229, 246, 253, 0.5)",
          p: 2,
          display: "flex",
          gap: 3,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Typography variant="h5" color="text.primary" maxWidth="400px">
          30% goes to a mental health nonprofit you select
        </Typography>
        <Autocomplete
          autoHighlight
          sx={{ alignSelf: "center", width: "300px", maxWidth: "90%" }}
          multiple
          value={filters}
          onChange={(e, values) => setFilters([...values])}
          options={knownTags?.map((t) => t.name) || []}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="search" />
          )}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                sx={{ borderRadius: 1 }}
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
                key={index}
              />
            ))
          }
          renderOption={(props, option, { inputValue }) => {
            const matches = option
              .toLowerCase()
              .includes(inputValue.toLowerCase());
            const parts = matches
              ? [
                  {
                    text: option.slice(
                      0,
                      option.toLowerCase().indexOf(inputValue.toLowerCase())
                    ),
                    highlight: false
                  },
                  {
                    text: option.slice(
                      option.toLowerCase().indexOf(inputValue.toLowerCase()),
                      option.toLowerCase().indexOf(inputValue.toLowerCase()) +
                        inputValue.length
                    ),
                    highlight: true
                  },
                  {
                    text: option.slice(
                      option.toLowerCase().indexOf(inputValue.toLowerCase()) +
                        inputValue.length
                    ),
                    highlight: false
                  }
                ]
              : [{ text: option, highlight: false }];

            return (
              <li {...props}>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? "bold" : "normal" }}
                  >
                    {part.text}
                  </span>
                ))}
              </li>
            );
          }}
        />
      </Box>
      <Divider />

      <Box
        display="flex"
        justifyContent="center"
        // HACK: This should be done at a lower level, doing it in this context
        // to avoid conflict with other developer's feature
        // TODO: move this a level lower, or refactor artwork list in general
        sx={{
          "& .artwork-list": {
            m: 1,
            mt: 3
          }
        }}
      >
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            width: "100%",
            alignItems: "center"
          }}
        >
          {filters.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: sectionWidth
              }}
            >
              <Typography variant="h4" color="text.primary" ml={2} mt={2}>
                results
              </Typography>

              <FlexibleProductList
                products={filteredArtwork || []}
                options={{
                  expected: 1,
                  loading: !filteredArtwork || filteredArtwork.length <= 0
                }}
                {...productListColumns}
              />
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: sectionWidth,
              alignItems: "center"
            }}
          >
            <Typography
              variant="h4"
              color="text.primary"
              alignSelf="start"
              ml={2}
              mt={2}
              mb={1}
            >
              daily picks
            </Typography>

            <FlexibleProductList
              products={dailyPicks?.slice(0, 6) || []}
              options={{
                expected: 6,
                loading: !dailyPicks || dailyPicks.length < 6
              }}
              {...productListColumns}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: sectionWidth,
              alignItems: "center"
            }}
          >
            <Typography variant="h4" color="text.primary" m={3}>
              shop by category
            </Typography>

            <Categories />
          </div>

          <Box width="100%" mt={{ xs: 3, sm: 5 }}>
            <Box
              sx={{
                maxHeight: "151px",
                transform: "scale(-1)",
                aspectRatio: "1920/146",
                background: `url(${SchmearBottom})`,
                backgroundSize: "contain",
                width: "100%"
              }}
            />

            <Box
              sx={{
                backgroundColor: "#374744",
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 3,
                mt: "-2px"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: sectionWidth
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                    mb: 2,
                    flexDirection: {
                      xs: "column",
                      sm: "row"
                    }
                  }}
                >
                  <Typography
                    variant="h4"
                    color="secondary"
                    sx={{
                      textAlign: "center",
                      minWidth: 300
                    }}
                  >
                    {selectedTag ? (
                      selectedTag.name
                    ) : (
                      <Skeleton variant="text" width={120} />
                    )}
                    <LocalOffer sx={{ ml: 2 }} />
                  </Typography>
                  <Button
                    color="secondary"
                    endIcon={<Replay />}
                    variant="contained"
                    onClick={() => {
                      // select a new, different tag
                      if (selectedTag) {
                        const last = selectedTag;

                        let candidate =
                          frequentTags &&
                          (randomChoice(frequentTags) || undefined);
                        let attempt = 1;

                        while (candidate === last && attempt < 20) {
                          candidate =
                            frequentTags &&
                            (randomChoice(frequentTags) || undefined);
                          attempt++;
                        }

                        setSelectedTag(candidate);
                      }
                    }}
                  >
                    new category
                  </Button>
                </Box>

                <FlexibleProductList
                  // containerProps={{
                  //   justifyContent: "center",
                  //   alignSelf: "center"
                  // }}
                  products={tagSelectedProducts || []}
                  options={{
                    expected: 4,
                    loading:
                      !tagSelectedProducts || tagSelectedProducts.length < 4
                  }}
                  {...productListColumns}
                  lg={3}
                />
              </div>
            </Box>

            <Box
              sx={{
                maxHeight: "151px",
                aspectRatio: "1920/146",
                background: `url(${SchmearBottom})`,
                backgroundSize: "contain",
                width: "100%"
              }}
            />
          </Box>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: sectionWidth
            }}
          >
            <Typography
              variant="h4"
              color="text.primary"
              alignSelf="end"
              mr={2}
              mt={2}
              mb={1}
            >
              new pieces
            </Typography>

            <FlexibleProductList
              products={recent || []}
              options={{
                expected: 5,
                loading: !recent || recent.length <= 0
              }}
              {...productListColumns}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: sectionWidth,
              alignItems: "center"
            }}
          >
            <Typography
              variant="h4"
              color="text.primary"
              alignSelf="start"
              ml={2}
              mt={3}
              mb={1}
            >
              more picks
            </Typography>

            <FlexibleProductList
              products={dailyPicks?.slice(6) || []}
              options={{
                expected: 18,
                loading: !dailyPicks || dailyPicks.length < 24
              }}
              {...productListColumns}
            />
          </div>

          <Schmear
            action={{
              type: "link",
              to: "/artists",
              text: "View Artist Gallery"
            }}
            text=" For more, check out our artists' stores and stories"
          />
        </div>
      </Box>
    </Box>
  );
}
