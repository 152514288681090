import { Box, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArtistDocument } from "shared/models/artist-models";
import { filterIsDefined } from "shared/tools/type-helpers";
import { callCloudFunction } from "../../functions/callCloudFunction";
import { TGGArtistUser } from "../../redux/slices/authSlice";
import { dialog, useDialogState } from "../../zustand/imperative-dialog";
import LoadingButton from "../helpers/LoadingButton";
import SupportEmail from "../helpers/SupportEmail";
import SetupWizardStepper, { SetupStep } from "./SetupWizardStepper";
import StripeConnectSetup from "./StripeConnectSetup";

interface SetupWizardProps {
  artistUser: TGGArtistUser;
  artistDoc: ArtistDocument;
  artworkLength: number;
}

export default function SetupWizard({
  artistDoc: artist,
  artistUser,
  artworkLength
}: SetupWizardProps) {
  const stripeSuccessfullyLinked = useMemo(
    () => artistUser?.userDocument.stripeConnect?.payoutsEnabled,

    [artistUser]
  );

  const navigate = useNavigate();

  const [registeredCompletion, setRegisteredCompletion] = useState(false);

  const completedSteps = useMemo(() => {
    const steps = new Set<SetupStep>([]);

    if (artist) {
      steps.add("profile");
    }

    if (artworkLength > 0) {
      steps.add("art");
    }

    if (stripeSuccessfullyLinked) {
      steps.add("stripe");
    }

    return steps;
  }, [artist, artworkLength, artistUser]);

  const stripeSetupRequired = useMemo(() => {
    const commissionTarget = artistUser.userDocument.commissionTarget || "self";
    return commissionTarget == "self";
  }, [artistUser]);

  // TODO: get from user record
  const setupComplete = useMemo(() => {
    const finishedNeededSteps =
      completedSteps.has("profile") &&
      completedSteps.has("art") &&
      (completedSteps.has("stripe") || !stripeSetupRequired);
    return registeredCompletion || finishedNeededSteps;
  }, [completedSteps, registeredCompletion, artistUser]);

  const [completingSetup, setCompletingSetup] = useState(false);

  async function continueSetup() {
    if (!completedSteps.has("profile")) {
      // unexpected state
      navigate("/mystore/update/profile");
    } else if (!completedSteps.has("art")) {
      navigate("/mystore/upload/art");
    } else if (!completedSteps.has("stripe")) {
      await setStripeSetupOpen(true);
    } else {
      // unexpected state
      alert("error: all steps already completed");
    }
  }

  useEffect(() => {
    if (
      setupComplete &&
      !artistUser?.userDocument.setupCompletedOn &&
      !registeredCompletion
    ) {
      if (completingSetup) {
        console.warn(
          "currently completing setup, not trying to complete again"
        );
        return;
      }

      setCompletingSetup(true);
      callCloudFunction("completeSetup", {})
        .then(() => console.log("Setup complete"))
        .catch((error) =>
          dialog.error("Error while completing setup, contact support.", error)
        )
        .then(() => {
          // close the dialog we used to tell them we're working
          // TODO: better pattern for this
          useDialogState.getState().closeCurrent();

          dialog.alert({
            title: "Setup Complete",
            content:
              "Setup has been completed and your profile and artwork are now live!"
          });
          setRegisteredCompletion(true);
        })
        .finally(() => setCompletingSetup(false));
    }
  }, [setupComplete]);

  const [stripeSetupOpen, setStripeSetupOpen] = useState(false);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <StripeConnectSetup
        autoFireSuccess={true}
        artistUser={artistUser}
        open={stripeSetupOpen}
        onCancel={() => setStripeSetupOpen(false)}
        onSuccess={() => {
          dialog.alert({
            title: "Finishing Setup",
            content: "finishing setup for you..."
          });
          setStripeSetupOpen(false);
        }}
      />

      <Typography textAlign="center">
        Your profile will go live after you finish these steps! If you have any
        questions, reach out on Discord or <SupportEmail />
      </Typography>

      <SetupWizardStepper
        steps={filterIsDefined([
          "profile",
          "art",
          stripeSetupRequired ? "stripe" : undefined
        ])}
        completedSteps={completedSteps}
        actions={(step) => {
          switch (step) {
            case "art":
              navigate("/mystore/upload/art");
              break;
            case "stripe":
              setStripeSetupOpen(true);
              break;
          }
        }}
        loading={stripeSetupOpen ? ["stripe"] : []}
      />

      <LoadingButton
        loading={completingSetup || stripeSetupOpen}
        loadingMessage={
          stripeSetupOpen ? "setting up payments ..." : "completing setup ..."
        }
        onClick={() => continueSetup()}
        variant="contained"
        fullWidth
      >
        Continue Store Setup
      </LoadingButton>
    </Box>
  );
}
