import { z } from "zod";
import { ImageDetails, InternalImageDetails } from "./image-models";

export const CoreProductInfo = z.object({
  title: z
    .string()
    .regex(/^[a-zA-Z0-9 ]+$/, "Title can only be alphanumeric and spaces")
    .min(5, "Title must be at least 5 characters")
    .max(25, "Title must be at most 25 characters"),
  description: z
    .string()
    .min(50, "Description must be at least 50 characters")
    .max(500, "Description must be at most 500 characters"),
  altText: z
    .string()
    .min(20, "must be at least 20 characters")
    .max(150, "Must be at most 150 characters")
    .optional()
    // it's optional since old data may not have it (yet), but we want to require it for new data
    .refine((val) => val !== undefined, {
      message: "alt text is required for accessibility"
    }),
  price: z
    .string()
    .regex(/^\d+$/, "Must be a dollar value with no cents")
    .refine(
      (val) => {
        const num = parseInt(val, 10);
        return num >= 20 && num <= 500;
      },
      { message: "Must be between $20 and $500" }
    ),
  tags: z
    .array(
      z
        .string()
        .max(14, "tags must be < 15 characters")
        .min(3, "tags must be > 3 characters")
        .regex(
          /^([a-z0-9]+(?:\s|$)){1,3}$/,
          "Tags must only include a-z, and be at most 3 words each"
        )
    )
    .min(2, "you must have at least 2 tags")
    .max(15, "no more than 15 tags")
});

export const ProductDocument = CoreProductInfo.extend({
  productId: z.string(),
  uploadedAt: z.date(),
  updatedAt: z.date().optional(),
  live: z.boolean(),
  liveOn: z.date().optional(),
  image: ImageDetails,
  imageRotated: InternalImageDetails,
  artist: z.string(),
  artistName: z.string(),
  version: z.number(),
  slug: z.string(),
  slugs: z.array(z.string()),
  random: z.string().optional(),
  recencyRank: z.number().optional()
});

export type ProductDocument = z.infer<typeof ProductDocument>;
