import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  GridProps,
  IconButton,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Typography
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import AssetsResolver from "../../AssetsResolver";

import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { ProductDocument } from "shared/models/product-models";
import {
  calculatePossibleSizeOptions,
  getVariantSize,
  printOrientation
} from "shared/printing";
import { withZodSchema } from "../../helpers/ZodFormikAdapter";
import {
  useArtist,
  useArtistCollection,
  usePrintfulWallArt,
  useProduct
} from "../../helpers/queries";
import { limitText } from "../../helpers/text-helpers";
import snackbarSlice from "../../redux/slices/snackbarSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import DialogTitleWithClose from "../helpers/DialogTitleWithClose";
import ReportContent from "../helpers/ReportContent";
import FlexibleProductList from "../productViews/FlexibleProductList";
import "./SingleProductPage.css";
import SingleProductSizeButton from "./SingleProductSizeButton";

import { Settings } from "@mui/icons-material";
import { sortBy, uniq } from "lodash";
import Loading from "react-loading";
import {
  CloudFunctionData,
  CloudFunctionSchemas
} from "shared/cloud-functions";
import { FitStyle } from "shared/models/order-models";
import {
  PrintfulApiVariant,
  PrintfulProductDocument
} from "shared/models/printful-models";
import Config from "../../config";
import { callCloudFunction } from "../../functions/callCloudFunction";
import { countForProductVariant, useCart } from "../../zustand/cart-state";
import { useDevelopmentWarning } from "../../zustand/development-warning";
import { useDocuments } from "../../zustand/documents";
import { dialog } from "../../zustand/imperative-dialog";
import FormikField from "../formik/FormikField";
import FormikScrollToError from "../helpers/FormikScrollToError";
import ShareButton from "../helpers/ShareButton";
import MarginProduct from "../productViews/ProductImage";

function checkIfRolled(
  printfulVariant: PrintfulApiVariant,
  printfulProduct: PrintfulProductDocument
) {
  const checkResult =
    printfulProduct.product.title === "Enhanced Matte Paper Poster (in)" &&
    !(printfulVariant.size === "5″×7″" || printfulVariant.size === "8″×10″");

  return checkResult;
}

export default function SingleProductPage() {
  const user = useAppSelector((state) => state.auth.user);

  const readOnly = !user?.roles.includes("admin");

  const { productId: productIdOrSlug } = useParams();

  const shoppingCart = useCart();

  function addItem(
    item: ProductDocument,
    variant: PrintfulApiVariant,
    fit: FitStyle
  ) {
    shoppingCart.addItem({
      product: item,
      count: 1,
      printfulVariant: variant,
      fit: fit
    });
    dispatch(snackbarSlice.actions.addSnackbar("added_to_cart"));
  }

  const [product, productLoading, productError] = useProduct(
    productIdOrSlug || null
  );

  const [artist, artistLoading, artistError] = useArtist(product || null);
  const breadCrumbs = (p: ProductDocument) => [
    <Link
      color="inherit"
      underline="hover"
      component={RouterLink}
      to="/artwork"
      key="/artwork"
    >
      Gallery
    </Link>,
    <Typography key="product-page" color="text.primary">
      {p.title}
    </Typography>
  ];

  const [selectedProduct, setSelectedProduct] = useState<
    undefined | PrintfulProductDocument
  >(undefined);

  const [selectedFitStyle, setSelectedFitStyle] = useState<FitStyle>({
    fitStyle: "center_crop"
  });

  const [wallArt] = usePrintfulWallArt();

  const printfulProductDocuments = useDocuments(
    (state) => state.collections.printfulProducts
  );

  const printfulProducts = useMemo(() => {
    const loadedProducts: PrintfulProductDocument[] = [];

    for (const doc of Object.values(printfulProductDocuments)) {
      if (doc.state === "loaded") {
        loadedProducts.push(doc.document);
      }
    }

    return loadedProducts;
  }, [printfulProductDocuments]);

  const printfulFilteredProducts = useMemo(
    () =>
      sortBy(
        printfulProducts
          .filter(
            (p) =>
              !Config.printful.enforceProductWhitelist ||
              Config.printful.productsWhitelist.includes(p.product.id)
          )
          .filter(
            (p) =>
              calculatePossibleSizeOptions(
                product?.image.full.height || 0,
                product?.image.full.width || 0,
                p.product,
                p.variants
              ).length > 0
          ),
        (p) => p.product.title
      ),
    [product, printfulProducts]
  );

  useEffect(() => {
    if (wallArt) {
      wallArt.products.forEach((p) =>
        useDocuments
          .getState()
          .fetchDocument(p.id.toString(), "printfulProducts")
      );
    }
  }, [wallArt]);

  const productVariants = useMemo(() => {
    const neededInfo = product?.image.full && selectedProduct;

    if (!neededInfo) {
      return undefined;
    } else {
      const possible = calculatePossibleSizeOptions(
        product.image.full.height,
        product.image.full.width,
        selectedProduct.product,
        selectedProduct.variants
      );

      return sortBy(possible, (v) => {
        const { width, height } = getVariantSize(v);

        return parseInt(`${width}${height}`);
      });
    }
  }, [product, selectedProduct]);

  const maybeColors: string[] = useMemo(
    () =>
      uniq(
        (productVariants || [])
          .filter((v) => v.color)
          .map((v) => v.color as string)
      ),
    [productVariants]
  );

  const [color, setColor] = useState<string | undefined>();

  useEffect(() => {
    if (maybeColors.length > 1 && !color) {
      setColor(maybeColors[0]);
    }
  }, [maybeColors]);

  const hasColors = maybeColors.length > 1;

  const possibleVariants =
    color && hasColors
      ? productVariants?.filter((o) => o.color === color)
      : productVariants;

  useEffect(() => {
    if (printfulFilteredProducts && !selectedProduct) {
      const mattePoster = printfulFilteredProducts.find(
        (p) => p.product.id === 1
      );
      if (mattePoster) {
        setSelectedProduct(mattePoster);
      }
    }
  }, [printfulFilteredProducts, selectedProduct]);

  const [selectedVariant, setSelectedVariant] = useState<
    undefined | PrintfulApiVariant
  >();

  useEffect(() => {
    if (possibleVariants && possibleVariants.length > 0) {
      // choose one to start. TODO: do this more deterministically
      const selection = possibleVariants[0];

      if (!selectedVariant || !possibleVariants.includes(selectedVariant)) {
        // we have no variant, or an invalid (old) one
        setSelectedVariant(selection);
      }
    }
  }, [possibleVariants, selectedVariant, selectedProduct]);

  useEffect(() => {
    if (
      selectedProduct &&
      (color || !hasColors) &&
      selectedVariant &&
      !possibleVariants?.includes(selectedVariant)
    ) {
      // we have a selected variant, but it's not possible. We try to find the best possible one, or fallback to none
      const match = possibleVariants?.find(
        (so) => so.size === selectedVariant.size
      );

      if (match) {
        setSelectedVariant(match);
      } else {
        setSelectedVariant(undefined);
      }
    }
  }, [selectedProduct, color]);

  const dispatch = useAppDispatch();

  const [otherArtwork, otherArtworkLoading, otherArtworkError] =
    useArtistCollection(artist ? artist : null, product?.artist);

  const [moderationDialogOpen, setModerationDialogOpen] = useState(false);

  const [moderateRadioValue, setModerateRadioValue] = useState("Live");

  const [moderationActionLoading, setModerationActionLoading] = useState(false);

  const [developmentDialogOpen, setDevelopmentDialogOpen] = useState(false);

  const emailSubmitted = useDevelopmentWarning((state) => state.emailSubmitted);
  const setEmailSubmitted = useDevelopmentWarning(
    (state) => state.setEmailSubmitted
  );

  const [canBeRolled, setCanBeRolled] = useState<boolean>(false);

  useEffect(() => {
    if (selectedVariant && selectedProduct) {
      setCanBeRolled(checkIfRolled(selectedVariant, selectedProduct));
    } else {
      setCanBeRolled(false);
    }
  }, [selectedVariant, selectedProduct]);

  const productListColumns: GridProps = {
    xs: 6,
    sm: 4,
    md: 3,
    lg: 2
  };

  return (
    <Box display="flex" justifyContent="center" maxWidth="100%">
      <Box
        maxWidth="100%"
        width="1279px"
        flexGrow="1"
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        {product && (
          <>
            {/* Breadcrumbs section */}
            <Box m={3} mb={0} display="flex">
              <Box flexGrow={1} />
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumbs"
              >
                {breadCrumbs(product)}
              </Breadcrumbs>
            </Box>

            {!product.live && (
              <Alert severity="warning">
                This product is not live yet. It will be visible to others after
                store setup.
              </Alert>
            )}

            {/* overall product section */}
            <Box
              sx={{
                m: {
                  xs: 1.5,
                  sm: 3
                },
                maxWidth: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Box
                sx={{
                  gap: 3,
                  flexWrap: "wrap",
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "100%",
                  maxWidth: "1400px"
                }}
              >
                {/* Left column (product) */}
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  maxWidth={"100%"}
                >
                  <Box
                    sx={{
                      maxWidth: {
                        xs: "400px",
                        md: "100%"
                      },
                      width: {
                        xs: "80vw",
                        md:
                          printOrientation({
                            width: product.image.full.width,
                            height: product.image.full.height
                          }) === "horizontal"
                            ? 600
                            : 350
                      }
                    }}
                  >
                    <MarginProduct
                      product={product}
                      variant={selectedVariant}
                      width={"100%"}
                      outerAspectRatio={
                        printOrientation({
                          width: product.image.full.width,
                          height: product.image.full.height
                        }) === "horizontal"
                          ? { horizontal: 4, vertical: 3 }
                          : { horizontal: 3, vertical: 4 }
                      }
                      fit={selectedFitStyle}
                    />
                  </Box>
                </Box>

                {/* Right column (artist & print details) */}
                <Box
                  display="flex"
                  flexDirection="column"
                  maxWidth="min(500px, 100%)"
                  minWidth="min(100%, 350px)"
                  flexBasis="350px"
                  flex="1"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      mb: 3
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{ maxWidth: "100%", mr: "auto" }}
                    >
                      {product.title}
                    </Typography>
                    <ReportContent resourceType="product" product={product} />

                    <ShareButton
                      path={`/artwork/${product.slug}`}
                      prompt={
                        <>
                          Share "{product.title}"
                          <blockquote>
                            <Typography
                              color="text.secondary"
                              sx={{
                                borderLeft: 1,
                                borderColor: "primary.main",
                                pl: 2
                              }}
                            >
                              {product.description}
                            </Typography>
                          </blockquote>
                        </>
                      }
                    />

                    {readOnly ? (
                      <></>
                    ) : (
                      <IconButton
                        onClick={() => {
                          setModerationDialogOpen(true);
                          setModerateRadioValue(
                            product.live ? "Live" : "Not Live"
                          );
                        }}
                      >
                        <Settings />
                      </IconButton>
                    )}
                  </Box>

                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={3}
                      sx={{ display: "grid", alignItems: "center" }}
                    >
                      <Skeleton
                        sx={{
                          gridArea: "1/1",
                          width: "100%",
                          aspectRatio: "1/1",
                          transform: "none"
                        }}
                      />
                      {artist && artist.profilePicture && (
                        <Link
                          to={`/artists/${artist.storeSlug}`}
                          component={RouterLink}
                          sx={{ gridArea: "1/1", zIndex: 1 }}
                        >
                          <Box
                            sx={{ borderRadius: 1 }}
                            component="img"
                            style={{ maxWidth: "100%", display: "block" }}
                            src={AssetsResolver.imageUrl(
                              artist.profilePicture.path,
                              "profilepicturesmall"
                            )}
                          />
                        </Link>
                      )}
                    </Grid>
                    <Grid item xs={9}>
                      {artist && (
                        <Link
                          to={`/artists/${artist.storeSlug}`}
                          component={RouterLink}
                          sx={{
                            maxWidth: "100%",
                            textDecoration: "none",
                            color: "text.primary"
                          }}
                        >
                          <Box
                            display="flex"
                            alignItems="flex-start"
                            flexDirection="column"
                          >
                            <Box display="flex" justifyContent="space-between">
                              {artist && (
                                <Typography variant="h6" lineHeight="1.2">
                                  By{" "}
                                  {artist.name
                                    ? artist.name.first + " " + artist.name.last
                                    : artist.storeName}
                                </Typography>
                              )}
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                              {artist?.name && (
                                <Typography
                                  color="text.secondary"
                                  variant="body1"
                                  lineHeight="1.2"
                                >
                                  {artist.storeName}
                                </Typography>
                              )}
                            </Box>
                            {artist && (
                              <Typography sx={{ p: 1, wordWrap: "anywhere" }}>
                                {limitText(
                                  artist.quote.includes('"') ||
                                    artist.quote.includes("“")
                                    ? artist.quote
                                    : `“${artist.quote}”`
                                )}
                              </Typography>
                            )}
                          </Box>
                        </Link>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        sx={{
                          fontFamily: '"EB Garamond", serif;',
                          textTransform: "uppercase"
                        }}
                      >
                        Print Type
                      </Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: "flex" }}>
                      {selectedProduct &&
                        (printfulFilteredProducts.length >= 2 ? (
                          <>
                            <Select
                              sx={{
                                width: "80%",
                                "& .MuiSelect-select": { whiteSpace: "normal" }
                              }}
                              value={selectedProduct.product.id}
                              onChange={(e) => {
                                const selected = printfulProducts.find(
                                  (p) => p.product.id === e.target.value
                                );
                                setSelectedProduct(selected);
                              }}
                              size="small"
                            >
                              {printfulFilteredProducts.map((product) => (
                                <MenuItem
                                  value={product.product.id}
                                  key={product.product.id}
                                >
                                  {product.product.title.replace(" (in)", "")}
                                </MenuItem>
                              ))}
                            </Select>
                            {/* need better ux around this, removing for now
                        <img
                          src={selectedProduct.product.image}
                          style={{ width: "20%" }}
                        /> */}
                          </>
                        ) : (
                          <Typography>
                            {selectedProduct.product.title.replace(" (in)", "")}
                          </Typography>
                        ))}
                    </Grid>
                    {hasColors && (
                      <>
                        <Grid item xs={3}>
                          <Typography
                            sx={{
                              fontFamily: '"EB Garamond", serif;',
                              textTransform: "uppercase"
                            }}
                          >
                            Color
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          {maybeColors.map((c) => (
                            <Button
                              variant={color === c ? "contained" : "text"}
                              size="small"
                              key={c}
                              onClick={() => {
                                setColor(c);
                              }}
                            >
                              {c}
                            </Button>
                          ))}
                        </Grid>
                      </>
                    )}
                    <Grid
                      item
                      xs={3}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontFamily: '"EB Garamond", serif;',
                          textTransform: "uppercase"
                        }}
                      >
                        Print Size
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Box
                        sx={{
                          display: "flex",
                          flexFlow: "wrap",
                          gap: 0.2,
                          margin: 0
                        }}
                      >
                        {possibleVariants ? (
                          possibleVariants.map((e, index) => {
                            return (
                              <Box
                                key={index}
                                onClick={() => {
                                  setSelectedVariant(e);
                                }}
                              >
                                <SingleProductSizeButton
                                  sizeOption={e}
                                  selected={e.id === selectedVariant?.id}
                                />
                              </Box>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </Box>
                      <Box
                        sx={{
                          visibility: canBeRolled ? "visible" : "hidden"
                        }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          This size comes rolled for shipping
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        sx={{
                          fontFamily: '"EB Garamond", serif;',
                          textTransform: "uppercase"
                        }}
                      >
                        Fit Style
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Box
                        sx={{
                          display: "flex",
                          flexFlow: "wrap",
                          gap: 0.2,
                          margin: 0
                        }}
                      >
                        <Button
                          size="small"
                          variant={
                            selectedFitStyle.fitStyle === "center_crop"
                              ? "outlined"
                              : "text"
                          }
                          onClick={() => {
                            setSelectedFitStyle({ fitStyle: "center_crop" });
                          }}
                          aria-label="crop button"
                        >
                          Crop
                        </Button>
                        <Button
                          size="small"
                          variant={
                            selectedFitStyle.fitStyle === "margin"
                              ? "outlined"
                              : "text"
                          }
                          onClick={() => {
                            setSelectedFitStyle({ fitStyle: "margin" });
                          }}
                          aria-label="margin button"
                        >
                          Margin
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={9}>
                      {selectedFitStyle.fitStyle === "center_crop" ? (
                        <Typography variant="body2" color="text.secondary">
                          Image will be cropped to fit print area
                        </Typography>
                      ) : (
                        <Typography variant="body2" color="text.secondary">
                          Image will have unequal margins on print area
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        sx={{
                          fontFamily: '"EB Garamond", serif;',
                          textTransform: "uppercase"
                        }}
                      >
                        Price
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography>
                        {" "}
                        {`$${product.price}`} + printing ($
                        {selectedVariant?.price})
                      </Typography>
                    </Grid>
                  </Grid>
                  {selectedVariant &&
                    (countForProductVariant(
                      shoppingCart,
                      product,
                      selectedVariant,
                      selectedFitStyle
                    ) ? (
                      <ButtonGroup
                        variant="contained"
                        size="large"
                        sx={{ mt: 2, width: "100%" }}
                      >
                        <Button
                          variant="outlined"
                          onClick={() => {
                            shoppingCart.removeItem({
                              product: product,
                              printfulVariant: selectedVariant,
                              removeCount: 1,
                              fit: selectedFitStyle
                            });
                          }}
                        >
                          -1
                        </Button>
                        <Link
                          sx={{ width: "100%" }}
                          to="/checkout"
                          component={RouterLink}
                          onClick={(e) => {
                            if (Config.ux.checkoutDisabled) {
                              e.preventDefault();
                              setDevelopmentDialogOpen(true);
                            }
                          }}
                        >
                          <Button
                            sx={{
                              width: "100%",
                              pr: 0,
                              pl: 0,
                              borderRadius: 0
                            }}
                          >
                            Checkout (
                            {countForProductVariant(
                              shoppingCart,
                              product,
                              selectedVariant,
                              selectedFitStyle
                            )}
                            )
                          </Button>
                        </Link>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            shoppingCart.addItem({
                              product: product,
                              printfulVariant: selectedVariant,
                              fit: selectedFitStyle,
                              count: 1
                            });
                          }}
                        >
                          +1
                        </Button>
                      </ButtonGroup>
                    ) : (
                      <Button
                        size="large"
                        variant="contained"
                        onClick={() => {
                          Config.ux.checkoutDisabled
                            ? setDevelopmentDialogOpen(true)
                            : addItem(
                                product,
                                selectedVariant,
                                selectedFitStyle
                              );
                        }}
                        sx={{
                          backgroundColor: "rgb(112, 33, 44)",
                          width: "100%",
                          mt: 2
                        }}
                      >
                        Add to Cart
                      </Button>
                    ))}
                </Box>
              </Box>
            </Box>
            {/* About section */}
            <Box
              sx={{
                width: "min(1279px,100%)",
                display: "flex",
                flexDirection: "column",
                alignSelf: "center"
              }}
            >
              <Divider variant="middle">
                <Typography variant="h5">About this Piece</Typography>
              </Divider>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                  p: 3
                }}
              >
                <Box sx={{ maxWidth: "100%" }}>
                  <Typography>{product.description}</Typography>
                </Box>

                <Box sx={{}} display="flex" flexDirection="row">
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {product.tags?.map((tag) => (
                      <Chip
                        size="small"
                        sx={{
                          borderRadius: 1,
                          background:
                            tag !== "lgbt"
                              ? "gray.500"
                              : "linear-gradient(90deg,rgba(255, 0, 0, 1) 0%,rgba(255, 154, 0, 1) 10%,rgba(208, 222, 33, 1) 20%,rgba(79, 220, 74, 1) 30%,rgba(63, 218, 216, 1) 40%,rgba(47, 201, 226, 1) 50%,rgba(28, 127, 238, 1) 60%,rgba(95, 21, 242, 1) 70%,rgba(186, 12, 248, 1) 80%,rgba(251, 7, 217, 1) 90%,rgba(255, 0, 0, 1) 100%)"
                        }}
                        key={tag}
                        label={tag}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Divider variant="middle" />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 3
              }}
            >
              {artist &&
                otherArtwork &&
                otherArtwork.filter((p) => p.productId !== product.productId)
                  .length > 0 && (
                  <Typography variant="h4">
                    More artwork by{" "}
                    {artist.name
                      ? artist.name.first + " " + artist.name.last
                      : artist.storeName}
                  </Typography>
                )}
              {otherArtwork && (
                <FlexibleProductList
                  containerProps={{ justifyContent: "center" }}
                  {...productListColumns}
                  products={otherArtwork.filter(
                    (p) => p.productId !== product.productId
                  )}
                />
              )}
            </Box>
          </>
        )}
      </Box>

      <Dialog
        open={developmentDialogOpen}
        onClose={() => setDevelopmentDialogOpen(false)}
      >
        <DialogTitleWithClose onClose={() => setDevelopmentDialogOpen(false)}>
          Not accepting orders yet
        </DialogTitleWithClose>

        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1
          }}
        >
          <Typography paragraph>
            We are starting to onboard artists, but not accepting orders yet. We
            are planning to fully launch the store later this year!
          </Typography>

          <Typography paragraph>
            If you're interested in selling art{" "}
            <Link
              component={RouterLink}
              to="/apply"
              onClick={() => setDevelopmentDialogOpen(false)}
            >
              click here to apply.
            </Link>{" "}
            If you want us to keep you updated when we launch, and send you the
            occasional newsletter, enter your email below.
          </Typography>

          {emailSubmitted && (
            <Typography paragraph>
              It looks like you've already submitted an email, but you can enter
              it again if you made a mistake or want someone else to be notified
              as well.
            </Typography>
          )}

          <Formik<CloudFunctionData<"submitPrelaunchEmail">>
            validate={withZodSchema(
              CloudFunctionSchemas["submitPrelaunchEmail"].data
            )}
            initialValues={{
              email: "",
              interestedIn: "buying",
              comment: undefined
            }}
            onSubmit={async (values) => {
              callCloudFunction("submitPrelaunchEmail", values).catch((e) =>
                console.error("Failed to submit email", e)
              );
              setEmailSubmitted(true);
              setDevelopmentDialogOpen(false);
            }}
          >
            <Form id="submit-email-form">
              <FormikScrollToError />
              <FormikField
                size="small"
                sx={{ mt: 3 }}
                name="email"
                label="Email"
              />

              <FormikField
                sx={{ mt: 3 }}
                name="comment"
                label="Comment"
                placeholder="optional"
                allowUndefined
                multiline
                minRows={2}
              />
            </Form>
          </Formik>
        </DialogContent>

        <DialogActions>
          <Button type="submit" form="submit-email-form" variant="contained">
            Submit Email
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={moderationDialogOpen}
        onClose={() => setModerationDialogOpen(false)}
      >
        <DialogTitleWithClose onClose={() => setModerationDialogOpen(false)}>
          Moderate product
        </DialogTitleWithClose>

        <DialogContent>
          <FormControl disabled={moderationActionLoading}>
            <FormLabel id="radio-buttons-group-label">
              Edit live status for product{" "}
              {product?.slug ? product.slug : "Error"}
            </FormLabel>
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              defaultValue="Live"
              name="radio-buttons-group"
              value={moderateRadioValue}
            >
              <FormControlLabel
                value="Live"
                control={<Radio />}
                label="Live"
                onClick={async () => {
                  if (moderateRadioValue === "Not Live") {
                    if (!product) {
                      // button is disabled, but somehow this was clicked or race condition

                      dialog.error("Unexpected state, try again");
                    } else {
                      if (
                        window.confirm(
                          "Are you sure you want to set product " +
                            product.slug +
                            " as live?"
                        )
                      ) {
                        try {
                          setModerationActionLoading(true);
                          await callCloudFunction("productVisibility", {
                            productId: product.productId,
                            action: "set as live"
                          });

                          dialog.alert({
                            content: `Successfully set ${product.slug} as live`,
                            title: "Success"
                          });
                          setModerateRadioValue("Live");
                        } catch (e) {
                          dialog.error(`Error setting product as live`, e);
                        } finally {
                          setModerationActionLoading(false);
                        }
                      }
                    }
                  }
                }}
              />
              <FormControlLabel
                value="Not Live"
                control={<Radio />}
                label="Not Live"
                onClick={async () => {
                  if (moderateRadioValue === "Live") {
                    if (!product) {
                      // button is disabled, but somehow this was clicked or race condition

                      dialog.error("Unexpected state, try again");
                    } else {
                      if (
                        window.confirm(
                          "Are you sure you want to set product " +
                            product.slug +
                            " as not live?"
                        )
                      ) {
                        try {
                          setModerationActionLoading(true);
                          await callCloudFunction("productVisibility", {
                            productId: product.productId,
                            action: "set as not live"
                          });

                          dialog.alert({
                            content: `Successfully set ${product.slug} as not live`,
                            title: "Success"
                          });
                          setModerateRadioValue("Not Live");
                        } catch (e) {
                          dialog.error(`Error setting product as not live`, e);
                        } finally {
                          setModerationActionLoading(false);
                        }
                      }
                    }
                  }
                }}
              />
            </RadioGroup>
          </FormControl>
          {moderationActionLoading ? (
            <Loading type="bubbles" color="#812215" />
          ) : (
            <></>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
