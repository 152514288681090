import { z } from "zod";

/**
 * Deletion is recursive. Deleting a resource fully involves fully deleting its sub resources
 */

export const DeletionStatus = z.union([
  z.literal("not started"),
  z.literal("partial"),
  z.literal("complete")
]);

const UserDeletion = z.object({
  type: z.literal("user"),
  userType: z.literal("artist"),
  stripeConnect: z
    .object({
      id: z.string(),
      deleted: z.boolean()
    })
    .nullable(),
  products: z.array(
    z.object({
      productId: z.string(),
      status: DeletionStatus
    })
  ),
  firebaseAuthDeleted: z.boolean()
});

const ProductDeletion = z.object({
  type: z.literal("product"),
  artistId: z.string()
});

export const DeletionDocument = z
  .object({
    // resource id, e.g., product id or userId
    id: z.string(),
    reason: z.string().optional(),
    status: DeletionStatus,
    startedOn: z.date().optional(),
    completedOn: z.date().optional(),
    firestoreRecordsDeleted: z.boolean(),
    imagesDeleted: z
      .object({
        bunnyCDN: z.boolean(),
        cloudStorage: z.boolean()
      })
      .nullable()
  })
  .and(z.union([UserDeletion, ProductDeletion]));

export type DeletionDocument = z.infer<typeof DeletionDocument>;
