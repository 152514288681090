import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import Loading from "react-loading";
import { useSearchParams } from "react-router-dom";
import { callCloudFunction } from "../../functions/callCloudFunction";
import { TGGArtistUser } from "../../redux/slices/authSlice";
import { dialog } from "../../zustand/imperative-dialog";
import DialogTitleWithClose from "../helpers/DialogTitleWithClose";
import LoadingButton from "../helpers/LoadingButton";

export interface ConnectSetupDialogProps {
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  autoFireSuccess?: boolean;
  successMessage?: string;
  artistUser: TGGArtistUser;
}

/**
 * This exists as a drop-in component that tells the user they need to redirect
 * and then processes the redirect (with a URL param)
 *
 * This exists for profile creation, so users can choose to keep their
 * commission even if they waived it in normal setup.
 *
 * We could adopt this within overall store setup on the profile page,
 * but I'm leaving that alone for now to not introduce regressions
 * since the flow has low test coverage.
 */
export default function StripeConnectSetup(props: ConnectSetupDialogProps) {
  const payoutsEnabled = useMemo(
    () => Boolean(props.artistUser?.userDocument.stripeConnect?.payoutsEnabled),
    [props.artistUser]
  );

  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");
  const fromStripe = useMemo(() => from === "payment-setup", [from]);

  const [checkingLink, setCheckingLink] = useState(false);

  const [openDueToReturning, setOpenDueToReturning] = useState(false);

  useEffect(() => {
    if (fromStripe) {
      setCheckingLink(true);
      setOpenDueToReturning(true);

      callCloudFunction("checkStripePayoutsEnabled", {})
        .catch((e) => dialog.error("could not check if payouts are enabled", e))
        .finally(() => {
          setCheckingLink(false);
        });
    }
  }, [fromStripe]);

  const [generatingStripeLink, setGeneratingStripeLink] = useState(false);

  async function redirectToConnectSetup() {
    try {
      setGeneratingStripeLink(true);

      // https://stackoverflow.com/a/28662284/954986
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const current = window.location.href.split("?")[0]!;

      const { redirectTo } = await callCloudFunction(
        "createStripeConnectAccount",
        {
          returnToLocation: current
        }
      );
      window.location.href = redirectTo;
    } catch (e) {
      dialog.error("Unexpected error while redirecting to Stripe", e);
    } finally {
      setGeneratingStripeLink(false);
    }
  }

  const initialContent = useMemo(
    () => (
      <Typography>
        In order to receive payments, we need to set up a Stripe account for
        you. This will redirect you to Stripe, which will securely verify you
        and set up a method of payment.
      </Typography>
    ),
    []
  );

  /**
   * After success we may just want to close the dialog.
   */
  useEffect(() => {
    if (props.autoFireSuccess && payoutsEnabled) {
      props.onSuccess();
    }
  }, [props.autoFireSuccess, props.onSuccess]);

  const confirmingContent = useMemo(
    () => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3
        }}
      >
        <Loading type="cylon" color="#812215" />
        <Typography>Confirming that payments are enabled</Typography>
      </Box>
    ),
    [checkingLink]
  );

  const successContent = useMemo(
    () => (
      <Typography>
        {props.successMessage || "Payments have been successfully enabled!"}
      </Typography>
    ),
    [payoutsEnabled]
  );

  const mode = useMemo(() => {
    if (checkingLink) {
      return "confirming";
    } else if (payoutsEnabled) {
      return "success";
    } else {
      return "initial";
    }
  }, [checkingLink, payoutsEnabled]);

  const content = useMemo(() => {
    switch (mode) {
      case "initial":
        return initialContent;
      case "confirming":
        return confirmingContent;
      case "success":
        return successContent;
    }
  }, [mode, initialContent, confirmingContent, successContent]);

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={props.open || openDueToReturning}
      onClose={() => {
        setOpenDueToReturning(false);
        payoutsEnabled ? props.onSuccess() : props.onCancel();
      }}
    >
      <DialogTitleWithClose
        onClose={() => {
          setOpenDueToReturning(false);
          payoutsEnabled ? props.onSuccess() : props.onCancel();
        }}
      >
        Payments Setup
      </DialogTitleWithClose>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {mode === "initial" ? (
          <LoadingButton
            loading={generatingStripeLink}
            onClick={() => redirectToConnectSetup()}
          >
            Continue
          </LoadingButton>
        ) : mode === "success" ? (
          <Button
            onClick={() => {
              setOpenDueToReturning(false);
              props.onSuccess();
            }}
          >
            Close
          </Button>
        ) : (
          <></>
        )}
      </DialogActions>
    </Dialog>
  );
}
