import { Box, Typography, List, ListItem } from "@mui/material";

export default function Guidelines() {
  const guidelineList = [
    "No nudity or lingerie",
    "No self-harm",
    "No adult language",
    "No non legal drugs or alcohol",
    "No blood, guns, or violence, or depictions of self harm",
    "No pornography",
    "No nude or sexualized children",
    "No violence, hatred, or vilification",
    "No racism or racist terms/symbols",
    "No harmful misinformation or threats",
    "No personal attacks, harassment, or baiting",
    "No use of bots, fake accounts, or AI-generated artwork"
  ];
  return (
    <Box
      sx={{
        width: "900px",
        maxWidth: "95%",
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        alignSelf: "center",
        gap: 3
      }}
    >
      <Typography variant="h4">Content and Community Guidelines</Typography>
      <Typography variant="body1">
        The Giving Gallery is committed to this platform being a safe and
        positive space for artists, buyers, and our partners. If these content
        and community guidelines are violated, TGG reserves the right to provide
        warnings, account suspensions, account closures, or remove artwork at
        our sole discretion. We also take into consideration users' flags or
        reporting of content violations. We will make every effort to ensure
        artists feel free to express themselves, while also upholding and
        respecting these content guidelines. The Giving Gallery has an
        application screening process to ensure that artists do not infringe on
        the Content Guidelines.
      </Typography>
      <Typography variant="h5">Content Guidelines:</Typography>
      <List
        sx={{
          listStyleType: "disc",
          pl: 3,
          "& li": {
            padding: 0.3,
            display: "list-item"
          }
        }}
      >
        {guidelineList.map((guideline) => {
          return (
            <ListItem key={guideline}>
              <Typography variant="body1">{guideline}</Typography>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
