import {
  ErrorOutline,
  InfoOutlined,
  TaskAlt,
  WarningAmber
} from "@mui/icons-material";
import { Alert, AlertProps, AlertTitle, Box, styled } from "@mui/material";

interface RichAlertProps extends Omit<AlertProps, "action"> {
  actions?: React.ReactNode | React.ReactNode[];
}

const defaultIconMapping = {
  success: <TaskAlt fontSize="inherit" color="success" />,
  warning: <WarningAmber fontSize="inherit" color="warning" />,
  error: <ErrorOutline fontSize="inherit" color="error" />,
  info: <InfoOutlined fontSize="inherit" color="info" />
};

const AlertIcon = styled("div", {
  name: "RichAlert",
  slot: "Icon",
  overridesResolver: (props, styles) => styles.icon
})({
  marginRight: 12,
  //padding: '7px 0',
  display: "flex",
  fontSize: 22,
  opacity: 0.9
});

export default function RichAlert({
  children,
  actions,
  title,
  ...props
}: RichAlertProps) {
  const severity = props.severity || "success";

  return (
    // half padding on message since icon increases size. If we do no icon, we may need to change this
    <Alert
      icon={false} // disable default icon prop, as we roll our own
      action={actions}
      {...props} // make sure we omit any of our custom props from this
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        "& .MuiAlert-action": {
          display: "flex",
          justifyContent: "space-evenly",
          marginLeft: 0, // overriding "auto"
          flex: 1,
          flexWrap: "wrap"
        },
        "& .MuiAlert-message": { padding: "4px 0" },
        ...props.sx
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {props.icon !== false && (
          <AlertIcon sx={{ alignSelf: title ? "flex-start" : "center" }}>
            {defaultIconMapping[severity]}
          </AlertIcon>
        )}
        <Box>
          <AlertTitle>{title}</AlertTitle>
          {children}
        </Box>
      </Box>
    </Alert>
  );
}
